import { css } from "@emotion/react";
import styled from "@emotion/styled";

export type Size = "S" | "M" | "L" | "XL";

export const sizes: Record<Size, string> = {
	S: "10px",
	M: "20px",
	L: "30px",
	XL: "40px",
};

export const stack = (
	gap: number | Size,
	justify: "flex-start" | "flex-end" | "center" | "space-around" | "space-between" | "space-evenly" = "flex-start",
	align: "flex-start" | "flex-end" | "center" | "stretch" = "flex-start",
	reverse?: "reverse"
) => css`
	display: flex;
	flex-direction: ${reverse ? "column-reverse" : "column"};
	justify-content: ${justify};
	align-items: ${align};

	> *:not(:last-child) {
		${`${reverse ? "margin-top:" : "margin-bottom:"}${typeof gap === "number" ? `${gap}px` : sizes[gap]}`};
	}
`;

export const row = (
	gap: number | Size,
	justify: "flex-start" | "flex-end" | "center" | "space-around" | "space-between" | "space-evenly" = "flex-start",
	align: "flex-start" | "flex-end" | "center" | "stretch" | "baseline" = "flex-start",
	reverse?: "reverse"
) => css`
	display: flex;
	flex-direction: ${reverse ? "row-reverse" : "row"};
	justify-content: ${justify};
	align-items: ${align};

	> *:not(:last-child) {
		${`${reverse ? "margin-left:" : "margin-right:"}${typeof gap === "number" ? `${gap}px` : sizes[gap]}`};
	}
`;

export const grid = (
	gap: number | Size,
	cellWidth: number | string,
	justify: "flex-start" | "flex-end" | "center" | "space-around" | "space-between" | "space-evenly" = "flex-start",
	align: "flex-start" | "flex-end" | "center" | "stretch" | "baseline" = "flex-start"
) => css`
	display: grid;
	grid-template-columns: repeat(auto-fill, ${cellWidth});
	gap: ${typeof gap === "number" ? `${gap}px` : sizes[gap]};
	justify-content: ${justify};
	align-items: ${align};
`;

export const Spacer = styled.div<{ space?: number }>`
	flex: ${props => props.space ?? 1};
	margin-bottom: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	margin-top: 0 !important;
`;
