import styled from "@emotion/styled";
import { Patient } from "domain/patient";
import { SurgeryStep } from "domain/surgery-step";
import React from "react";
import { colors } from "style";
import { useI18n } from "utils/i18n";

export const Greetings: React.FC<{ patient?: Patient; state?: SurgeryStep }> = ({ patient }) => {
	const { format } = useI18n();

	return (
		<Container>
			<FirstWording>{format("patient.dashboard.greetings.name", { name: patient?.firstName ?? "" })}</FirstWording>
		</Container>
	);
};

const Container = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;

const FirstWording = styled.div`
	font-size: 25px;
	font-weight: bold;
`;
