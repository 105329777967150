import SignIcon from "@assets/icons/pen-filled.svg";
import styled from "@emotion/styled";
import { Button } from "components/button";
import {
	Document,
	SurgeryDocumentAdmission,
	SurgeryDocumentSource,
	SurgeryDocumentValidationStatus,
} from "domain/document";
import React, { useCallback, useRef, useState } from "react";
import { useDocumentType } from "services/document-type-service";
import { createModal } from "services/modal-service";
import { colors, row, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { DocumentDescriptionEditor } from "./document-description-editor";
import { DocumentMenuContextAction, DocumentMenuContextMenu } from "./document-menu-context-menu";
import { useUser } from "../../services/auth-service";
import { patientService, PatientService } from "../../services/patient-service";
import { UserType } from "../../domain/user-type";
import { SecretaryService, usePractitioner } from "../../services/secretary-service";
import { PractitionerService, usePractitionerProfile } from "../../services/practitioner-service";
import { Surgery } from "../../domain/surgery";
import { MySignModal } from "../modals/my-sign-modal";
import { AxiosError } from "axios";
import { ApiError } from "../../services/api";

interface EditableDocumentProps {
	document: Document;
	onDelete: () => void;
	onChange: (document: Document) => void;
	className?: string;
	audience?: string;
	practitionerId?: string;
	surgeryId: string;
	onEdit?: (bool: boolean) => void;
}

export const DocumentBigCard: React.FC<EditableDocumentProps> = ({
	document,
	className,
	practitionerId,
	onDelete,
	onChange,
	surgeryId,
}) => {
	const user = useUser();
	const { format } = useI18n();
	const contextMenuIcon = useRef<HTMLImageElement | null>(null);
	const [editingDocument, setEditingDocument] = useState<Document>();
	const editingDocumentType = useDocumentType(editingDocument?.documentType?.id);
	const [procedureLoading, setProcedureLoading] = useState<boolean>(false);
	const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
	let desc = document.documentType.label.split(":")[1] ?? document.documentType.label;
	if (desc == "Dépassement honoraires à editer") desc = "Dépassement honoraire";
	const {practitioner} = usePractitioner(practitionerId)
	const profile = usePractitionerProfile()

		function log(): void {
		switch (user?.type) {
			case UserType.Patient: {
				const patientService = new PatientService();
				patientService.log(surgeryId, document.id).then();
				break;
			}
			case UserType.Practitioner: {
				const practitionerService = new PractitionerService();
				practitionerService.log(surgeryId, document.id).then();
				break;
			}
			case UserType.Secretary: {
				const secretaryService = new SecretaryService();
				secretaryService.log(surgeryId, document.id).then();
				break;
			}
			default:
				break;
		}
	}

	async function read(document: Document): Promise<void> {
		if (user?.type == UserType.Patient) {
			const patientService = new PatientService();
			await patientService.updateDocument(surgeryId, document.id, { toRead: false });
			await patientService.fetchSurgeries();
			// only [Patient] can modifiy 'toRead' status
		}
	}
	let result: any = null;
	const sign = useCallback(
		async (surgery: Surgery) => {
			setProcedureLoading(true);
			try {
				setProcedureLoading(false);
				result = await createModal<string | undefined>(({ onClose }) => (
					<MySignModal
						surgeryId={surgery.id}
						surgeryDate={surgery.surgeryDate}
						documents={surgery.surgeryDocuments.filter(document => document.needSignature && !document.isSigned &&
							document.documentType.filename == "sign")}
						onClose={onClose}
						surgery={surgery}
					/>
				));
				setDocumentsLoading(result === "success");
				if (result) {
					await patientService.fetchSurgeries();
				}
			} catch (e: any) {
				const apiError: AxiosError<ApiError> = e;
				if (apiError.response?.data.code === "ERR_NO_PHONENUMBER_OTHER_GUARDIAN") {
					alert(format("signError.phoneNumber"));
				}
				console.log(e.message);
			} finally {
				setProcedureLoading(false);
				surgery.surgeryDocuments.map(async document => {
					if (!document.isSigned && document.needSignature) {
						await patientService.updateDocument(surgeryId, document.id, { toRead: false });
					}
				});
			}
		},
		[format, surgeryId]
	);

	return (
		<Container className={className}>
			{!editingDocument && (
				<ContextRow>
					{document.status != SurgeryDocumentAdmission.Completed && document.status != null ? (
						<Tag backgroundColor="rgba(245, 132, 12, 1)">{format("patient.surgery.document.unfinished")}</Tag>
					) : null}
					{document.status == null || document.status == SurgeryDocumentAdmission.Completed ? (
						<ContextMenuIcon
							ref={contextMenuIcon}
							onClick={async () => {
								const action = await createModal<DocumentMenuContextAction | null>(
									({ style, onClose }) => (
										<DocumentMenuContextMenu
											style={style}
											onClose={onClose}
											showUpdate={true}
											showDelete={(user?.type == "hpu" || user?.type != "patient") && document.documentType.filename != "sign"}
											audience={user?.type}
											surgeryId={surgeryId}
											documentId={document?.id}
											showHss={document.lienHss != null}
											showHss2={document.lienHss2 != null}
										/>
									),
									{ valueOnBackdropClick: null, anchor: contextMenuIcon.current ?? undefined }
								);
								if (action === "download") {
									window.open(document.url, "_blank");
								}
								if (action === "downloadHss") {
									window.open(document.lienHss, "_blank");
								}
								if (action === "downloadHss2") {
									window.open(document.lienHss2, "_blank");
								}
								if (action === "delete") {
									onDelete();
								}
								if (action === "update") {
									setEditingDocument(document);
								}
							}}
						/>
					) : null}
				</ContextRow>
			)}
			<Spacer />
			<FileNameContainer>
				<PdfIcon src="/icons/pdf.svg" />
				<FileName
					textBold={desc == " Document final signé" ? "bolder" : "initial"}
				>
					{desc}
				</FileName>
			</FileNameContainer>
			{editingDocument ? (
				<Editing>
					<DocumentDescriptionEditor
						documentTypeId={editingDocument.documentType.id}
						value={editingDocument.description}
						onChange={description => setEditingDocument({ ...editingDocument, description })}
					/>
					<Buttons>
						<StyledButton
							disabled={editingDocumentType?.isOtherType && !editingDocument.description}
							onClick={() => {
								onChange(editingDocument);
								setEditingDocument(undefined);
							}}
						>
							{format("practitioner.surgery.upload.validate")}
						</StyledButton>
						<StyledButton onClick={() => setEditingDocument(undefined)}>
							{format("practitioner.surgery.upload.cancel")}
						</StyledButton>
					</Buttons>
				</Editing>
			) : document.status == SurgeryDocumentAdmission.Empty || document.status == SurgeryDocumentAdmission.Pending ? (
				document.documentType.id == "fa10bea740224aa7b945dc50e1f2181d" && (
					<FileDescription rejected={document.validationStatus == SurgeryDocumentValidationStatus.Rejected}>
						{format("patient.designate.trusted.person.description")}
					</FileDescription>
				)
			) : document.documentType.isOtherType ? (
				<FileDescription rejected={document.validationStatus == SurgeryDocumentValidationStatus.Rejected}>
					{document.description ?? ""}
				</FileDescription>
			) : null}
			{!editingDocument && (
				<FileSign rejected={document.validationStatus == SurgeryDocumentValidationStatus.Rejected}>
					<div>
						{document.isSigned ? (
							<DescriptionSection>
								<DownloadSection
									target="_blank"
									rel="noopener noreferrer"
									href={document.url}
									onClick={() => {
										log();
										read(document).then();
									}}
								>
									<InfoSection>
										<DescriptionText>
											<DownloadIcon src="/icons/file-download.svg" />Télécharger
										</DescriptionText>
										<SignText>
											{format("practitioner.surgery.document.isSigned")} <SignedIcon />
										</SignText>
									</InfoSection>
								</DownloadSection>
							</DescriptionSection>
						) :
						(practitioner?.evaluation || profile?.evaluation )
						&& !document.isSigned
						&& document.validationStatus != SurgeryDocumentValidationStatus.Rejected
						&& document.needSignature
						&& document.documentType.source == SurgeryDocumentSource.Practitioner? (
							format("practitioner.surgery.document.needsSignature.patient")
						)
						: !document.isSigned && document.needSignature && document.validationStatus != SurgeryDocumentValidationStatus.Rejected ? (
							format("practitioner.surgery.document.needsSignature")
						)
							: document.validationStatus == SurgeryDocumentValidationStatus.Rejected ? (
							<div>
								{"Rejeté : "}
								{document.rejectReason}
							</div>
						) : (
							""
						)}
					</div>
				</FileSign>
			)}
			<Spacer />
		</Container>
	);
};

const Buttons = styled.div`
	${row("S", "space-between", "center")};
`;

const StyledButton = styled(Button)`
	padding: 3px 11px;
	height: unset;
	border-radius: 9px;

	:disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

const Container = styled.div`
	width: 265px;
	height: 500px;
	background-color: ${colors.white};
	border-radius: 12px;
	box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
	position: relative;
	${stack("S", "center", "center")}
	padding: 17px;
	flex: 1;
`;

const DescriptionSection = styled.div`
	font-size: 10px;
	height: 100%;
	margin-bottom: 10px;
	${row("S", "flex-start", "flex-end")};
`;

const DownloadSection = styled.a`
	float: left;
	vertical-align: top;
	display: inline-block;
	text-align: right;
`;

const DescriptionText = styled.div`
	${stack(0, "center","center")}
	height: 100%;
	width: 100%;
`;

const SignText = styled.div`
	height: 100%;
	width: 100%;
`;

const InfoSection = styled.div`
	height: 100%;
	width: 200px;
	text-align: center;
	font-size: 10px;
	${row("S", "flex-start", "flex-end")};
`;

const DownloadIcon = styled.img`
	width: 22px;
	height: 22px;
`;

const Tag = styled.div<{ backgroundColor: string }>`
	width: 100px;
	height: 22px;
	background-color: ${$props => $props.backgroundColor};
	color: ${colors.white};
	border-radius: 12px;
	position: center;
	text-align: center;
	font-size: 10px;
	font-weight: bold;
	top: 6px;
	right: 30px;
	box-sizing: content-box;
	cursor: pointer;
	z-index: 10;
`;
const Editing = styled.div`
	${stack("S", "center", "center")};
`;
const ContextMenuIcon = styled.div`
	width: 90%;
	height: 90%;
	object-fit: contain;
	border: none;

	position: absolute;
	padding: 0;
	top: 0;
	right: 0;
	box-sizing: content-box;
	cursor: pointer;
	z-index: 10;
`;

const PdfIcon = styled.img`
	width: 24px;
	height: 30px;
`;

const FileName = styled.span<{ textBold?: string }>`
	font-weight: ${$props => $props.textBold == "bolder" ? "bolder" : "initial"};
	color: ${colors.black};
	font-size: 10px;
`;

const FileDescription = styled.span<{ rejected: boolean }>`
	${row("S", "flex-start", "center")};
	font-size: 12px;
	word-wrap: break-word;
`;

const FileSign = styled.span<{ rejected: boolean }>`
	${row("S", "center", "center")};
	font-size: 12px;
	font-style: italic;
	margin-top: 20px;
`;

const SignedIcon = styled(SignIcon)`
	width: 20px;
	height: 20px;
	path,
	rect {
		fill: ${colors.green};
	}
`;

const FileNameContainer = styled.div`
	${row("S", "flex-start", "center")};
	margin-top: 20px;
`;

const ContextRow = styled.div`
	${row("S", "flex-start", "center")};
`;
