import { DocumentProcessingIcon } from "@assets/icons/document-processing.svg";
import styled from "@emotion/styled";
import { Breadcrumb } from "components/navigation/breadcrumb";
import React from "react";
import { useHistory } from "react-router";
import { colors, row, Spacer } from "style";
import { useI18n } from "utils/i18n";
import {Module} from "../../domain/surgery";

export interface HospitalHeaderProps {
	firstName: string;
	lastName: string;
	surgeryDate: string;
	pendingDocuments?: number;
	modules?: Module[] | undefined;
}

export const HospitalHeader: React.FC<HospitalHeaderProps> = ({
	firstName,
	lastName,
	surgeryDate,
	pendingDocuments,
	modules,
}) => {
	const { format, formatDate } = useI18n();
	const history = useHistory();

	return (
		<Container>
			<Breadcrumb firstName={firstName} lastName={lastName} onClick={() => history.goBack()} />
			{!!pendingDocuments && modules && modules?.some(item => "pre-admission" == item.type) && (
				<PendingDocsWrapper>
					<ColoredDocumentProcessingIcon />
					<PendingDocs>{format("hospital.breadcrumb.document_count", { count: pendingDocuments })}</PendingDocs>
				</PendingDocsWrapper>
			)}
			<Spacer />
			<DateText>
				{format("hospital.breadcrumb.date")}{" "}
				<DateTextBold>{formatDate(surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })}</DateTextBold>
			</DateText>
		</Container>
	);
};

const Container = styled.div`
	${row("M", "flex-start", "center")}
`;

const DateText = styled.span`
	margin-left: 54px;
	font-size: 18px;
`;

const DateTextBold = styled.span`
	font-weight: bold;
	font-size: 18px;
`;

const PendingDocs = styled.span`
	font-size: 15px;
	color: ${colors.blue2};
	font-weight: bold;
`;

const PendingDocsWrapper = styled.div`
	${row("S")}
`;

const ColoredDocumentProcessingIcon = styled(DocumentProcessingIcon)`
	path {
		fill: ${colors.blue2};
	}
`;
