export enum SurgeryStep {
	PatientInfo = "patient-info",
	PatientDocuments = "patient-documents",
	ForensicDocuments = "forensic-documents",
	ForensicHpuDocuments = "forensic-hpu-documents",
	ForensicEvaluationDocuments = "forensic-evaluation-documents",
	Anesthesia = "anesthesia",
	Surgery = "surgery",
	Evaluation = "evaluation",
	PostOp = "post-operative",
}

export const allSurgerySteps = [SurgeryStep.PatientInfo, SurgeryStep.ForensicDocuments];
export const allEvaluationSteps = [SurgeryStep.PatientInfo, SurgeryStep.ForensicEvaluationDocuments];

export const allPatientSugerySteps = [SurgeryStep.PatientInfo, SurgeryStep.ForensicDocuments];

export const allHPUSteps = [SurgeryStep.PatientInfo, SurgeryStep.ForensicHpuDocuments];
