import * as React from 'react';
import { css } from '@emotion/react';
import { LayoutContext } from '@table-library/react-table-library/common/context';
const BASE_STYLE = `
  ${() => {
}}
  padding: 0;
  margin: 0;

  ${() => {
}}
  display: flex;
  align-items: center;

  ${() => {
}}
  align-self: stretch;


  & > div {
    ${() => {
}}
    flex: 1;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.hide {
    display: none;
  }

  &.pin-left,
  &.pin-right {
    position: sticky;
    z-index: 2;
  }

  ${() => {
}}
  background-color: inherit;
`;
const CELL_CONTAINER_STYLE = css `
  ${BASE_STYLE}
`;
const CellContainer = React.forwardRef((props, ref) => {
    const context = React.useContext(LayoutContext);
    if (!context) {
        throw new Error('No Layout Context.');
    }
    const { layout } = context;
    const As = layout?.isDiv ? 'div' : 'td';
    return <As css={CELL_CONTAINER_STYLE} ref={ref} {...props}/>;
});
const HEADER_CELL_CONTAINER_STYLE = css `
  ${BASE_STYLE}

  z-index: 1;
  text-align: left;
  position: sticky;
  top: 0;

  &.pin-left,
  &.pin-right {
    z-index: 3;
  }
`;
const HeaderCellContainer = React.forwardRef((props, ref) => {
    const context = React.useContext(LayoutContext);
    if (!context) {
        throw new Error('No Layout Context.');
    }
    const { layout } = context;
    const As = layout?.isDiv ? 'div' : 'th';
    return <As css={HEADER_CELL_CONTAINER_STYLE} ref={ref} {...props}/>;
});
export { CellContainer, HeaderCellContainer };
