import styled from "@emotion/styled";
import React from "react";
import { colors, row } from "style";

export interface BreadcrumbProps {
	firstName: string;
	lastName: string;
	onClick?: () => void;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ firstName, lastName, onClick }) => {
	return (
		<Container onClick={onClick}>
			<BackIcon src="/icons/back.svg" />
			<Name>
				{firstName} {lastName}
			</Name>
		</Container>
	);
};

const Container = styled.div`
	cursor: ${props => (props.onClick ? "pointer" : "auto")};
	${row("L", "center", "center")};
	position: relative;
`;

const BackIcon = styled.img`
	transform: rotate(90deg);
	height: 24px;
	margin-top: 5px;
`;

const Name = styled.h1`
	color: ${colors.black};
`;
