import CheckIcon from "@assets/icons/check.svg";
import styled from "@emotion/styled";
import { AnesthesiaType } from "domain/anesthesia";
import { Surgery, SurgeryCompletionState } from "domain/surgery";
import React from "react";
import { colors, row } from "style";
import { BlueFlag, RedFlag } from "style/flags";
import { useI18n } from "utils/i18n";

export const StateAnesthesiaAppointmentField: React.FC<{ surgery: Surgery }> = ({ surgery }) => {
	const { formatDate, format } = useI18n();

	return surgery.stateAnesthesiaAppointment &&
		surgery.stateAnesthesiaAppointment !== SurgeryCompletionState.NotApplicable &&
		surgery.anesthesiaType === AnesthesiaType.General ? (
		surgery.stateAnesthesiaAppointment === SurgeryCompletionState.ActionRequired ? (
			<Row>
				<BlueFlag>{format("table.anesthesia-appointment.toDo")}</BlueFlag>
			</Row>
		) : surgery.stateAnesthesiaAppointment === SurgeryCompletionState.Completed ? (
			<Row>
				<div>
					{formatDate(surgery.anesthesiaAppointmentDate, {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					})}
				</div>
				<ValidIcon />
			</Row>
		) : surgery.stateAnesthesiaAppointment === SurgeryCompletionState.InProgress ||
		  surgery.anesthesiaAppointmentDate ? (
			<>
				{formatDate(surgery.anesthesiaAppointmentDate, {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				})}
			</>
		) : surgery.stateAnesthesiaAppointment === SurgeryCompletionState.Critical ? (
			surgery.anesthesiaAppointmentHonored === false ? (
				<Row>
					<RedFlag withIcon>{format("table.anesthesia-appointment.missed")}</RedFlag>
				</Row>
			) : (
				<Row>
					<RedFlag withIcon>{format("table.anesthesia-appointment.hurryUp")}</RedFlag>
				</Row>
			)
		) : (
			<>{format("table.anesthesia-appointment.other")}</>
		)
	) : (
		<>{format("table.anesthesia-appointment.other")}</>
	);
};

const Row = styled.div`
	${row(0, "flex-start", "center")};
`;

const ValidIcon = styled(CheckIcon)`
	path {
		fill: ${colors.green};
	}
`;
