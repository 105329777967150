import styled from "@emotion/styled";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import { colors, row, sizes } from "style";

interface ButtonProps {
	small?: boolean;
	secondary?: boolean;
	naked?: boolean;
	disabled?: boolean;
	loading?: boolean;
	third?: boolean;
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps & HTMLMotionProps<"button">>(
	({ children, small, secondary, third , naked, disabled, loading, ...props }, ref) => {
		const height = small ? 26 : 40;
		return (
			<ButtonContainer {...props} ref={ref} small={small} secondary={secondary} third={third} naked={naked} disabled={disabled}>
				<ButtonText
					secondary={secondary}
					naked={naked}
					disabled={disabled}
					variants={{ idle: { y: 0 }, loading: { y: -height } }}
					transition={{ type: "spring", stiffness: 1000, damping: 60 }}
					initial="idle"
					animate={loading ? "loading" : "idle"}
				>
					{children}
				</ButtonText>
				<AnimatePresence>
					{loading && (
						<SpinnerContainer
							key="spinner"
							variants={{ idle: { y: height }, loading: { y: 0 } }}
							transition={{ type: "spring", stiffness: 1000, damping: 60 }}
							exit={{ y: height }}
							initial="idle"
							animate={loading ? "loading" : "idle"}
						>
							<Spinner src="/icons/spinner.svg" small={small} />
						</SpinnerContainer>
					)}
				</AnimatePresence>
			</ButtonContainer>
		);
	}
);
Button.displayName = "Button";

const ButtonContainer = styled(motion.button)<{ small?: boolean; secondary?: boolean; naked?: boolean; third?: boolean }>`
	cursor: ${props => (props.disabled ? "default" : "pointer")};
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: ${props => (props.small ? "26px" : "40px")};
	border-radius: ${props => (props.small ? "9px" : "13px")};
	background-color: ${props =>
		props.naked ? "transparent" : props.disabled ? "#d9d9d9" : props.secondary ? "#ffe0e5" : props.third ? "#00bfba" : colors.pink};
	appearance: none;
	border: none;
	padding-left: ${props => (props.naked ? "0" : sizes.M)};
	padding-right: ${props => (props.naked ? "0" : sizes.M)};
	transition: background-color 0.1s linear;
	outline: none;
	overflow: hidden;
	position: relative;

	:hover,
	:focus-visible {
		background-color: ${props =>
			props.naked ? "transparent" : props.disabled ? "#d9d9d9" : props.secondary ? "#f6c9d0" : props.third ? "#00bfba" : "#d04f63"};
	}
`;

const ButtonText = styled(motion.div)<{ secondary?: boolean; naked?: boolean; disabled?: boolean }>`
	font-weight: bold;
	font-size: 15px;
	color: ${props => (props.disabled ? colors.grey2 : props.secondary || props.naked ? colors.pink : colors.white)};
	text-decoration: ${props => (props.naked ? "underline" : "none")};
`;

const SpinnerContainer = styled(motion.div)`
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	${row(0, "center", "center")};
	pointer-events: none;
`;

const Spinner = styled.img<{ small?: boolean }>`
	width: ${props => (props.small ? 20 : 30)}px;
	height: ${props => (props.small ? 20 : 30)}px;
	object-fit: contain;
`;
