import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { HTMLAttributes } from "react";
import { colors, row, Size, stack } from "style";

export const FormField: React.FC<{ label: string; spacing?: number | Size } & HTMLAttributes<HTMLDivElement>> = ({
	label,
	children,
	...props
}) => {
	return (
		<Container {...props}>
			<Label layout>{label}</Label>
			<Input layout>{children}</Input>
		</Container>
	);
};

const Container = styled.div<{ spacing?: number | Size }>`
	${props => stack(props.spacing ?? 5, "flex-start", "stretch")};
`;

const Label = styled(motion.label)`
	font-size: 12px;
	font-weight: bold;
	color: ${colors.grey};
	text-transform: uppercase;
`;

const Input = styled(motion.div)`
	${row(0, "flex-start", "center")};
	> *  {
		flex-grow: 1;
	}
`;
