import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { colors, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { createModal } from "../../services/modal-service";
import { IdeaModal } from "../modals/idea-modal";
import { Bulb, ButtonSupport, ToolTipIdeas } from "./practitioner-nav-bar";
import { supportTechnicalService, useSupportTechicalProfile } from "../../services/support-technical-service";
import { fullName } from "../../domain/utils";
import { UserType } from "../../domain/user-type";
import { AccountSelector } from "./account-selector";
import { css } from "@emotion/react";
import { useHistory, useLocation } from "react-router";
export const SupportTechnicalNavBar: React.FC<{ className?: string }> = ({ className }) => {
	const { format } = useI18n();
	const history = useHistory();
	const location = useLocation();
	const profile = useSupportTechicalProfile();
	const [showToolTip, setShowToolTip] = useState<boolean>(false);
	useEffect(() => {
		supportTechnicalService.fetchProfile().then();
	}, []);
	return (
		<Container className={className}>
			<LogoContainer>
				<LogoSophi src="/images/logo.svg" />
				<span>{format("practitioner.header.logo")}</span>
			</LogoContainer>
			<Tab
				selected={location.pathname.startsWith("/support-technical/user")}
				onClick={() => history.push("/support-technical/")}
			>
				{format("hpu.dashboard-patients.title")}
			</Tab>
			<Tab
				selected={location.pathname.startsWith("/support-technical/doc-Custom")}
				onClick={() => history.push("/support-technical/doc-Custom")}
			>
				{format("supportTechnical.pdf.custom.title")}
			</Tab>
			<Tab
				selected={location.pathname.startsWith("/support-technical/add-practitioner-document")}
				onClick={() => history.push("/support-technical/add-practitioner-document")}
			>
				{format("supportTechnical.indexation.custom.title")}
			</Tab>
			<Spacer />
			<AccountSelector name={fullName(profile)} withAccount accountType={UserType.Support} />
			<div
				onMouseOver={() => setShowToolTip(true)}
				onMouseOut={() => {
					setShowToolTip(false);
				}}
				onClick={() => {
					createModal(({ onClose }) => <IdeaModal onClose={onClose} profile={profile} />);
				}}
			>
				<ButtonSupport>
					<Bulb />
				</ButtonSupport>
				<ToolTipIdeas>{format("ideas.box.button.tooltip")}</ToolTipIdeas>
			</div>
		</Container>
	);
};

const Container = styled.div`
	${row("L", "flex-start", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "center", "center")}
	}
`;

const LogoContainer = styled.div`
	${row(5, "flex-start", "center")}
	color: ${colors.black};
`;

const LogoSophi = styled.img`
	width: 29px;
`;

const Tab = styled.div<{ selected: boolean }>`
    position: relative;
    cursor: pointer;
    color: ${colors.black};
    opacity: 0.3;

    ${({ selected }) =>
            selected &&
            css`
                opacity: 1;
                :after {
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "";
                    height: 6px;
                    width: 6px;
                    border-radius: 3px;
                    background-color: ${colors.pink};

                    @media ${mobile} {
                        left: -15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            `}
`;
