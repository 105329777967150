import styled from "@emotion/styled";
import { CcamDpmMode, DocCustom, Surgery } from "domain/surgery";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { practitionerService } from "services/practitioner-service";
import { colors, desktop, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { motion } from "framer-motion";
import { TextInput } from "../inputs/text-input";
import { Button } from "../button";
import { useModules } from "../../services/hospital-service";
import { useParams } from "react-router";
import { useCCAMDatabasePractitioner } from "../../services/practitioner-service";
import { SelectInput } from "../inputs/select-input";
import { CheckboxInput } from "../inputs/checkbox-input";

export const FormFieldSection: React.FC<{
	surgery: Surgery;
	docCustomForms: DocCustom[];
	onSubmit?: (submit: null, e: FormEvent) => void;
}> = ({ surgery, onSubmit }) => {
	const { format } = useI18n();

	//region DPM
	const modules = useModules(surgery.hospitalId);
	const [hasDpm, setHasDpm] = useState(false);
	useEffect(() => {
		if (modules && Boolean(modules.find(module => module.type == "dpm"))) {
			setHasDpm(true);
		}
	}, [modules]);

	const { formatDpmMode } = useI18n();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const { ccamList } = useCCAMDatabasePractitioner();
	const [classificationCodes, setClassificationCodes] = useState([""]);
	const [dpmMode, setdpmMode] = useState(CcamDpmMode.NONE.toString());
	const [isOptam, setIsOptam] = useState(true);
	const [dpmAmount, setDpmAmount] = useState("None");
	const [ccamPriceList, setCcamPriceList] = useState([["None"]]);
	const ccamPrices = [...ccamPriceList];
	const [dpmResult, setDpmResult] = useState("None");
	const codeList = surgery.classificationCode?.split(",");

	useEffect(() => {
		if (codeList) {
			setClassificationCodes(codeList);
			classificationCodes.map((_code: string, index: number) => {
				fillCcamPriceList(index);
			});
		}
	}, [ccamList, isOptam]);

	function fillCcamPriceList(index: number) {
		const optamPrice = ccamList.find(ccam => ccam.codeCCAM == classificationCodes[index])?.priceOptam ?? "None";
		const price = ccamList.find(ccam => ccam.codeCCAM == classificationCodes[index])?.price ?? "None";
		const prices = [optamPrice, price];
		ccamPrices[index] = [...prices];
		setCcamPriceList(ccamPrices);
	}

	const handleIsOptamChange = () => {
		setIsOptam(!isOptam);
		if (Number(dpmAmount) > 0) {
			handleCalculatedAmount();
		}
	};

	const handleDpmModeChange = (e: string) => {
		setdpmMode(e);
	};

	const handleDpmAmountChange = (e: string) => {
		setDpmAmount(e.replace(",", "."));
		handleCalculatedAmount();
	};

	const arrByOptamValuesDesc = (arr1: string[], arr2: string[]) => {
		const nombreA = parseInt(arr1[0]);
		const nombreB = parseInt(arr2[0]);
		return nombreB - nombreA;
	};
	const arrByNoOptamValuesDesc = (arr1: string[], arr2: string[]) => {
		const nombreA = parseInt(arr1[1]);
		const nombreB = parseInt(arr2[1]);
		return nombreB - nombreA;
	};

	function handleCalculatedAmount() {
		console.log("$$$$$$$$$$$$$$$");
		let tbSomme = 0;
		console.log("initial tbSomme:", tbSomme);
		let total = 0;
		console.log("initial total:", total);
		ccamPriceList.sort(isOptam ? arrByOptamValuesDesc : arrByNoOptamValuesDesc).map((_pricePair, index) => {
			console.log("itération n°" + index);
			console.log("  traitement map de :", _pricePair);
			let ratio = 0;
			if (index == 0) {
				ratio = 1;
			} else if (index == 1) {
				ratio = 0.5;
			}
			// console.log("ccamPrices:", ccamPrices);
			// console.log("ccamPriceList[" + index + "][0]:", ccamPriceList[index][0]);
			// console.log("ccamPriceList[" + index + "][1]:", ccamPriceList[index][1]);
			const ccamPrice = isOptam ? Number(ccamPriceList[index][0].valueOf()) : Number(ccamPriceList[index][1].valueOf());
			// console.log("ccamPrice:" + " Number(ccamPriceList[" + Number(index) + "][" + Number(!isOptam) + "]) = " + ccamPrice);
			console.log("  ratio:", ratio);
			console.log("  tbSomme = " + tbSomme + " + (" + ccamPrice + " * " + ratio + ")");
			tbSomme += ccamPrice * ratio;
		});
		console.log("tbSomme:", tbSomme);
		console.log("dpmMode: ", dpmMode);
		console.log("dpmAmount: ", dpmAmount);
		switch (dpmMode) {
			case "Fixed_amount": {
				total = Number(dpmAmount) + tbSomme;
				console.log("total: " + Number(dpmAmount) + " + " + tbSomme + " = " + total);
				break;
			}
			case "Percentage": {
				total = tbSomme * (Number(dpmAmount) / 100);
				console.log("total: " + tbSomme + " * (" + Number(dpmAmount) + " / 100) = " + total);
				break;
			}
			case "Total_amount": {
				total = Number(dpmAmount) - tbSomme;
				console.log("total: " + Number(dpmAmount) + " - " + tbSomme + " = " + total);
				break;
			}
		}
		// console.log("total.toFixed(2):", total.toFixed(2));
		setDpmResult(total.toFixed(2));
		// console.log("dpmResult (function result):", total.toFixed(2))
		console.log("dpmResult:", dpmResult);
		console.log("$$$$$$$$$$$$$$$");
	}

	//endregion

	const save = useCallback(async (surgeryId: string) => {
		await practitionerService.createFormValuesBySurgery(surgeryId).then();
		// persistance dans la table 'surgery'
		await practitionerService.updateCustomDpmValues(surgeryId, { dpmMode, dpmAmount, isOptam, dpmResult });
		await practitionerService.updateDocCustomValues(surgeryId, { dpmMode, dpmAmount, isOptam, dpmResult }).then();
		await practitionerService.fetchSurgery(surgeryId).then();
		await practitionerService.fetchDocCustom(surgeryId).then();
	}, []);

	return (
		<Section>
			<form
				onSubmit={e => {
					e.preventDefault();
					console.log("final dpmResult:", dpmResult);
					save(surgery.id);
					onSubmit?.(null, e);
				}}
			>
				<FieldSection key="dpm">
					<DocTitle>{format("createDocumentFormModal.depassementHonoraire.label")} :</DocTitle>
					{hasDpm && surgery ? (
						<>
							<CCAMFields>
								<FieldStack style={{ gridArea: "dpmMode" }}>
									<Label>Mode DPM</Label>
									<SelectInput<string>
										innerId="side"
										value={dpmMode}
										onChange={e => {
											handleDpmModeChange(e);
										}}
										options={[
											CcamDpmMode.NONE,
											CcamDpmMode.FIXED_AMOUNT,
											CcamDpmMode.PERCENTAGE,
											CcamDpmMode.TOTAL_AMOUNT,
										]}
										itemRenderer={formatDpmMode}
										required
									/>
								</FieldStack>

								{dpmMode != CcamDpmMode.NONE ? (
									<FieldStack style={{ gridArea: "optam" }}>
										<CheckboxInput value={isOptam} onChange={handleIsOptamChange}></CheckboxInput>
										<Label>OPTAM</Label>
									</FieldStack>
								) : null}

								<FieldStack style={{ gridArea: "amount" }}>
									{dpmMode == CcamDpmMode.FIXED_AMOUNT ? (
										<Label>Dépassement</Label>
									) : dpmMode == CcamDpmMode.PERCENTAGE ? (
										<Label>Pourcentage</Label>
									) : dpmMode == CcamDpmMode.TOTAL_AMOUNT ? (
										<Label>Coût Total Acte</Label>
									) : null}
									{dpmMode == CcamDpmMode.FIXED_AMOUNT ||
									dpmMode == CcamDpmMode.PERCENTAGE ||
									dpmMode == CcamDpmMode.TOTAL_AMOUNT ? (
										<TextInput
											onChange={e => {
												handleDpmAmountChange(e.target.value.toString());
											}}
										></TextInput>
									) : null}
								</FieldStack>
							</CCAMFields>
							<FieldStack style={{ color: "#ABC6C5" }}>Rappel des actes:</FieldStack>
							{classificationCodes.map((code, index) => {
								return (
									<FieldStack key={index} /*style={{ gridArea: "ccamName" }}*/>
										{classificationCodes[index]} {" - "}{" "}
										{ccamList.find(ccam => ccam.codeCCAM == classificationCodes[index])?.description}
										{isOptam
											? `:  ${ccamList.find(ccam => ccam.codeCCAM == classificationCodes[index])?.priceOptam} €`
											: `: ${ccamList.find(ccam => ccam.codeCCAM == classificationCodes[index])?.price} €`}
									</FieldStack>
								);
							})}
						</>
					) : null}
				</FieldSection>

				<FieldSection>
					<Buttons>
						<Button key="submit" type="submit">
							{format("patient.dashboard.info.submit")}
						</Button>
					</Buttons>
				</FieldSection>
			</form>
		</Section>
	);
};

const Section = styled.div`
	${stack("L", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

const FieldSection = styled.div`
	${stack("S", "flex-start", "stretch")}
`;

export const DocumentTitle = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const DocTitle = styled.h3`
	color: ${colors.black};
`;

const CCAMFields = styled.div`
	display: grid;
	grid-row-gap: ${sizes.L};
	grid-column-gap: ${sizes.L};
	align-items: flex-start;
	justify-item: center;
	grid-template-columns: 25fr 25fr 25fr 25fr;
	grid-template-areas: "dpmMode optam amount amount";
`;

const FieldStack = styled.div`
	${row("M")}
`;

const Label = styled(motion.label)`
	font-size: 12px;
	font-weight: bold;
	color: ${colors.grey};
	text-transform: uppercase;
`;
