import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth-reducer";

const rootReducer = combineReducers({
	auth: authReducer,
	// Ajoutez d'autres reducers au besoin
});
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware: any) => getDefaultMiddleware(),
	devTools: true,
});

export default store;
