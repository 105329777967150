import styled from "@emotion/styled";
import React, { InputHTMLAttributes } from "react";
import { colors, row, sizes, desktop, mobile } from "style";
import { useToggle } from "utils/hooks";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
	icon?: string | JSX.Element;
	rightIcon?: string | JSX.Element;
	popout?: boolean;
	onRightIconClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}
export const TextInput: React.FC<TextInputProps> = ({ icon, rightIcon, onRightIconClick, popout, ...props }) => {
	return (
		<Container popout={popout}>
			<Input type="text" icon={!!icon} rightIcon={!!rightIcon} popout={popout} {...props} />
			{!icon ? null : typeof icon === "string" ? <Icon src={icon} /> : <IconContainer>{icon}</IconContainer>}
			{!rightIcon ? null : typeof rightIcon === "string" ? (
				<RightIcon src={rightIcon} onClick={onRightIconClick} />
			) : (
				<IconContainer>{rightIcon}</IconContainer>
			)}
		</Container>
	);
};

export const TextInputLonger: React.FC<TextInputProps> = ({ icon, rightIcon, onRightIconClick, popout, ...props }) => {
	return (
		<Container popout={popout}>
			<InputLonger type="text" icon={!!icon} rightIcon={!!rightIcon} popout={popout} {...props} />
			{!icon ? null : typeof icon === "string" ? <Icon src={icon} /> : <IconContainer>{icon}</IconContainer>}
			{!rightIcon ? null : typeof rightIcon === "string" ? (
				<RightIcon src={rightIcon} onClick={onRightIconClick} />
			) : (
				<IconContainer>{rightIcon}</IconContainer>
			)}
		</Container>
	);
};

const Container = styled.div<{ popout?: boolean }>`
	${row(0)};
	position: relative;
	border-radius: 7px;
	${props => (props.popout ? "box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.05);" : "")}
`;

const Input = styled.input<{ icon?: boolean; rightIcon?: boolean; popout?: boolean; disabled?: boolean }>`
	appearance: none;
	height: 40px;
	border-radius: 7px;
	padding: 0;

	border: none;
	box-shadow: ${props => (props.popout ? `0px 0px 0px 1px ${colors.white} inset` : "0px 0px 0px 1px #c8efec inset")};

	background-color: ${props => (props.disabled ? "rgba(0,0,0,0.06)" : colors.white)};
	padding-left: ${props => (props.icon ? sizes.XL : sizes.S)};
	padding-right: ${props => (props.rightIcon ? sizes.XL : sizes.S)};
	flex-grow: 1;

	::placeholder {
		font-size: 13px;
		font-style: italic;
		color: ${colors.grey};
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}

	transition: box-shadow 0.15s ease-out;
`;

const InputLonger = styled.input<{ icon?: boolean; rightIcon?: boolean; popout?: boolean; disabled?: boolean }>`
	@media ${mobile} {
		appearance: none;
		height: 40px;
		border-radius: 7px;
		padding: 0;

		border: none;
		box-shadow: ${props => (props.popout ? `0px 0px 0px 1px ${colors.white} inset` : "0px 0px 0px 1px #c8efec inset")};

		background-color: ${props => (props.disabled ? "rgba(0,0,0,0.03)" : colors.white)};
		padding-left: ${props => (props.icon ? sizes.XL : sizes.S)};
		padding-right: ${props => (props.icon ? sizes.XL : sizes.S)};
		flex-grow: 1;

		::placeholder {
			font-size: 13px;
			font-style: italic;
			color: ${colors.grey};
		}

		&:focus {
			box-shadow: 0 0 0 2px ${colors.green} inset;
			outline: 0;
		}

		transition: box-shadow 0.15s ease-out;
	}

	@media ${desktop} {
		appearance: none;
		height: 40px;
		border-radius: 7px;
		padding: 0;
		width: 500px;

		border: none;
		box-shadow: ${props => (props.popout ? `0px 0px 0px 1px ${colors.white} inset` : "0px 0px 0px 1px #c8efec inset")};

		background-color: ${props => (props.disabled ? "rgba(0,0,0,0.03)" : colors.white)};
		padding-left: ${props => (props.icon ? sizes.XL : sizes.S)};
		padding-right: ${props => (props.icon ? sizes.XL : sizes.S)};
		flex-grow: 1;

		::placeholder {
			font-size: 13px;
			font-style: italic;
			color: ${colors.grey};
		}

		&:focus {
			box-shadow: 0 0 0 2px ${colors.green} inset;
			outline: 0;
		}

		transition: box-shadow 0.15s ease-out;
	}
`;

const Icon = styled.img`
	margin-top: 1px;
	width: 18px;
	height: 18px;
	position: absolute;
	top: ${sizes.S};
	left: 12px;
	pointer-events: none;
`;

const IconContainer = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	top: ${sizes.S};
	left: ${sizes.S};
	pointer-events: none;
`;

const RightIcon = styled.img`
	width: 20px;
	height: 20px;
	position: absolute;
	top: ${sizes.S};
	right: ${sizes.S};
	cursor: pointer;
`;

export const EmailInput: React.FC<Omit<TextInputProps, "icon">> = props => (
	<TextInput type="email" icon="/icons/email.svg" {...props} />
);

export const NumberInput: React.FC<Omit<TextInputProps, "icon">> = props => <TextInput type="text" {...props} />;

export const SearchInput: React.FC<Omit<TextInputProps, "icon">> = props => (
	<TextInput type="text" rightIcon="/icons/search.svg" {...props} />
);

export const PasswordInput: React.FC<Omit<TextInputProps, "type" | "rightIcon" | "onRightIconClick">> = props => {
	const [showPassword, toggleShowPassword] = useToggle(false);
	return (
		<TextInput
			icon="/icons/lock.svg"
			type={showPassword ? "text" : "password"}
			rightIcon="/icons/eye.svg"
			onRightIconClick={toggleShowPassword}
			{...props}
		/>
	);
};

export const PatternPasswordInput: React.FC<
	Omit<TextInputProps, "type" | "rightIcon" | "onRightIconClick">
> = props => {
	const [showPassword, toggleShowPassword] = useToggle(false);
	return (
		<TextInput
			pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
			icon="/icons/lock.svg"
			type={showPassword ? "text" : "password"}
			rightIcon="/icons/eye.svg"
			onRightIconClick={toggleShowPassword}
			{...props}
		/>
	);
};

export const PatternPasswordInputLonger: React.FC<
	Omit<TextInputProps, "type" | "rightIcon" | "onRightIconClick">
> = props => {
	const [showPassword, toggleShowPassword] = useToggle(false);
	return (
		<TextInputLonger
			pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
			icon="/icons/lock.svg"
			type={showPassword ? "text" : "password"}
			rightIcon="/icons/eye.svg"
			onRightIconClick={toggleShowPassword}
			{...props}
		/>
	);
};

export const TextArea = styled.textarea`
	appearance: none;
	resize: none;
	height: 100px;
	border-radius: 7px;
	padding: 10px;
	color: ${colors.black};

	border: none;
	box-shadow: 0px 0px 0px 1px #c8efec inset;

	background-color: ${colors.white};
	flex-grow: 1;

	::placeholder {
		font-size: 13px;
		font-style: italic;
		color: ${colors.grey};
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
`;
