import styled from "@emotion/styled";
import { Button } from "components/button";
import { SelectInput, SelectInputType } from "components/inputs/select-input";
import { AnesthesiaType } from "domain/anesthesia";
import { Surgery } from "domain/surgery";
import { AnimateSharedLayout } from "framer-motion";
import React, { useCallback, useState } from "react";
import { colors, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import {patientService} from "../../services/patient-service";
import {useUser} from "../../services/auth-service";
import {secretaryService} from "../../services/secretary-service";
import {practitionerService} from "../../services/practitioner-service";

interface AnesthesiaTypeSectionProps {
	surgery: Surgery;
	practitionerId?: string | null | undefined;
	className?: string;
	onSubmit?: (submit: AnesthesiaType)=> void,
}

export const AnesthesiaTypeSection: React.FC<AnesthesiaTypeSectionProps> = ({ surgery, practitionerId, className, onSubmit}) => {
	const { format, formatAnesthesiaType } = useI18n();
	const [anesthesiaType, setAnesthesiaType] = useState(surgery.anesthesiaType ?? AnesthesiaType.Local);
	const user = useUser();

	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const setEditingAndResetFields = useCallback(
		(editing: boolean) => {
			setEditing(editing);
			setAnesthesiaType(surgery.anesthesiaType ?? AnesthesiaType.Local);
		},
		[surgery.anesthesiaType]
	);

	const save = useCallback(async () => {
		try {
			surgery.anesthesiaType = anesthesiaType;
			switch (user?.type){
				case "patient":
					await patientService.updateSurgery(surgery.id, practitionerId!, surgery);
					break;
				case "secretary":
					await secretaryService.updateSurgery(surgery.id, practitionerId!, surgery);
					break;
				case "practitioner":
					await practitionerService.updateSurgery(surgery.id, surgery);
					break;
			}
		} catch (e) {
			console.log(e);
		} finally {
			setEditingAndResetFields(false);
			setLoading(false);
		}

		onSubmit?.(anesthesiaType);
	}, [anesthesiaType, setEditingAndResetFields]);

	return (
		<AnimateSharedLayout>
			<form
				onSubmit={e => {
					e.preventDefault();
					!loading && save();
				}}
			>
				<Section className={className}>
					<Header>
						<Subtitle>{format("practitioner.anesthesia.type.sectionTitle")}</Subtitle>
						{!editing && surgery.anesthesiaType && (surgery?.hospital?.id == "16eaf8d7e4564004a99652cf82be5e92" || user?.type != "patient") && (
							<Button onClick={() => setEditingAndResetFields(true)}>
								{format("practitioner.anesthesia.type.editButton")}
							</Button>
						)}
					</Header>

					{!editing ? (
						<Info>
							{surgery.anesthesiaType ? (
								<InfoField>
									<InputWrapper>
										<Label>{format("practitioner.anesthesia.type.inputLabel")}</Label>
										<Text>{formatAnesthesiaType(surgery.anesthesiaType)}</Text>
									</InputWrapper>
								</InfoField>
							) : (
								<InfoField>{format("practitioner.anesthesia.type.empty")}</InfoField>
							)}
							<Buttons>
								{!surgery.anesthesiaType ? (
									<Button onClick={() => setEditingAndResetFields(true)} type="button">
										{format("practitioner.anesthesia.type.completeButton")}
									</Button>
								) : null}
							</Buttons>
						</Info>
					) : (
						<Info>
							<InputWrapper spacing="S">
								<Label>{format("practitioner.anesthesia.type.inputLabel")}</Label>
								<AnesthesiaTypeSelectInput
									value={anesthesiaType}
									innerId="surgery-selectfield"
									options={[AnesthesiaType.Local, AnesthesiaType.General]}
									itemRenderer={formatAnesthesiaType}
									onChange={setAnesthesiaType}
								/>
							</InputWrapper>
							<Buttons>
								<Button
									key="submit"
									type="submit"
									loading={loading}
								>
									{format("practitioner.anesthesia.type.validButton")}
								</Button>
								<Button
									secondary
									onClick={() => setEditingAndResetFields(false)}
									type="button"
								>
									{format("practitioner.anesthesia.type.cancelButton")}
								</Button>
							</Buttons>
						</Info>
					)}
				</Section>
			</form>
		</AnimateSharedLayout>
	);
};

const Subtitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Section = styled.section`
	${stack("L", "flex-start", "stretch")}
	padding: ${sizes.L};
	border-radius: 12px;
	box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
	background-color: ${colors.white};
`;

const Header = styled.div`
	${row("M", "space-between", "baseline")}
`;

const AnesthesiaTypeSelectInput = styled<SelectInputType<AnesthesiaType>>(SelectInput)`
	select {
		width: 200px;
	}
`;

const Buttons = styled.div`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const Info = styled.div`
	${stack("M", "flex-start", "stretch")}
`;
const InfoField = styled.div`
	${row(0)};
	color: ${colors.black};
	font-size: 15px;

	> * {
		flex-grow: 1;
	}
`;

const Label = styled.div`
	font-size: 13px;
	color: ${colors.black};
	font-weight: bold;
`;

const Text = styled.div`
	color: ${colors.black};
	font-size: 15px;
`;

const InputWrapper = styled.div<{ spacing?: "S" }>`
	${props => stack(props.spacing ?? 0)}
`;
