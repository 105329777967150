import styled from "@emotion/styled";
import { DeleteSurgeryModal } from "components/modals/delete-surgery-modal";
import { Patient } from "domain/patient";
import { Practitioner, SurgeryStatus } from "domain/surgery";
import { SurgeryStep } from "domain/surgery-step";
import { fullName } from "domain/utils";
import { motion } from "framer-motion";
import React, { CSSProperties, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { createModal } from "services/modal-service";
import { practitionerService, usePatientSurgeries, useSurgery } from "services/practitioner-service";
import { colors, row, sizes } from "style";
import { useI18n } from "utils/i18n";
import { PractitionerSurgeryTimeline } from "./practitioner-surgery-timeline";
import { SurgeryDatesSelector } from "./surgery-dates-selector";
import { useModules } from "../../services/hospital-service";
import { SecretaryEvaluationTimeline } from "./secretary-evaluation-timeline";
import { SecretarySurgeryTimeline } from "./secretary-surgery-timeline";
import { PractitionerEvaluationTimeline } from "./practitioner-evaluation-timeline";

interface PractitionerHeaderProps {
	patient: Patient;
	practitioner?: Practitioner | null;
	step?: SurgeryStep;
	surgeryId?: string;
}

export const PractitionerHeader: React.FC<PractitionerHeaderProps> = ({ patient, step, surgeryId, practitioner }) => {
	const history = useHistory();
	const { formatDate } = useI18n();
	const { surgeries } = usePatientSurgeries(patient.id);
	const contextMenuIcon = useRef<HTMLImageElement | null>(null);
	const { surgery } = useSurgery(surgeryId);

	// region modules
	const modules = useModules(surgery?.hospitalId);
	const modulesFormat = modules?.map(item => {
		return item.type
	})
	// endregion

	return (
		<TimelineContainer>
			<Breadcrumb>
				<BreadcrumbLink to="/practitioner/dashboard-patients">Mes patients</BreadcrumbLink>
				<BreadcrumLevelIcon src="/icons/back.svg" />
				<BreadcrumbLinkName to={`/practitioner/patient/${patient.id}`}>
					{fullName(patient)}
				</BreadcrumbLinkName>
				{surgery && <BreadcrumLevelIcon src="/icons/back.svg" />}
				{surgery && (
					<BreadcrumbLink to={`/practitioner/patient/${patient.id}/surgery/${surgeryId}`}>
						{formatDate(surgery.surgeryDate)}
					</BreadcrumbLink>
				)}
			</Breadcrumb>
			{practitioner?.evaluation ? step && <PractitionerEvaluationTimeline step={step} surgeryId={surgeryId} patient={patient} modules={modulesFormat}/> : step && <PractitionerSurgeryTimeline step={step} surgeryId={surgeryId} patient={patient} modules={modulesFormat}/>}
			{surgeryId && surgeries && (
				<SurgeryDatesSelector
					surgery={surgery}
					surgeries={surgeries}
					surgeryId={surgeryId}
					onSurgeryChange={newSurgery =>
						practitioner?.evaluation ? history.push(`/practitioner/patient/${patient.id}/surgery/${newSurgery.id}/forensic-evaluation-documents`) :
						history.push(`/practitioner/patient/${patient.id}/surgery/${newSurgery.id}/forensic-documents`)
					}
					wording={{
						futureLabel: "practitioner.selectSurgery.future.label",
						futurePlaceholder: "practitioner.selectSurgery.future.placeholder",
						historyLabel: "practitioner.selectSurgery.history.label",
						historyPlaceholder: "practitioner.selectSurgery.history.placeholder",
					}}
				/>
			)}

			{surgery?.status === SurgeryStatus.Active && (
				<ContextMenuIcon
					src="/icons/more.svg"
					ref={contextMenuIcon}
					onClick={async () => {
						if (!surgeryId) {
							return;
						}
						const action = await createModal<"delete" | "download" | null>(
							({ style, onClose }) => <SurgeryContextMenu style={style} onClose={onClose} />,
							{ valueOnBackdropClick: null, anchor: contextMenuIcon.current ?? undefined }
						);
						if (action === "delete") {
							const confirm = await createModal(DeleteSurgeryModal);
							if (confirm) {
								await practitionerService.deleteSurgery(surgeryId);
								history.push("/practitioner");
							}
						}
					}}
				/>
			)}
		</TimelineContainer>
	);
};

const TimelineContainer = styled.div`
	${row("L", "space-between", "center")}
`;

const ContextMenuIcon = styled.img`
	width: 16px;
	height: 16px;
	object-fit: contain;
	padding: 10px;
	padding-right: 0px;
	box-sizing: content-box;
	cursor: pointer;
`;

export const SurgeryContextMenu: React.FC<{
	onClose: (action: "delete" | null) => void;
	style?: CSSProperties;
}> = ({ onClose, style }) => {
	const { format } = useI18n();
	return (
		<ContextMenuModal style={style} initial={{ y: -15, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
			<ContextMenuOption onClick={() => onClose("delete")}>{format("surgery.deleteButton")}</ContextMenuOption>
		</ContextMenuModal>
	);
};

const ContextMenuModal = styled(motion.div)`
	background: white;
	border-radius: 9px;
	overflow: hidden;
`;
const ContextMenuOption = styled.div`
	padding: ${sizes.M};
	cursor: pointer;

	:hover {
		background: rgba(0, 0, 0, 0.05);
	}
`;

const Breadcrumb = styled.div`
	${row("S", "flex-start", "center")};
	flex-shrink: 1;
	flex-grow: 1;
	min-width: 0;
`;

const BreadcrumLevelIcon = styled.img`
	transform: rotate(-90deg);
	height: 16px;
	margin-top: 5px;
`;

const BreadcrumbLink = styled(Link)`
	display: block;
	font-size: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const BreadcrumbLinkName = styled(Link)`
	color: ${colors.pink};
	font-weight: bold;
	text-decoration: underline;
	display: block;
	font-size: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	:hover {
		text-decoration: none;
	}
`;
