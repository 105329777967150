import { useEffect, useState } from "react";

export function useCCAMDatabase() {
	const [ccamDatabase, setCcamDatabase] = useState<Record<string, string> | null>(null);

	useEffect(() => {
		(async () => {
			setCcamDatabase(((await import("data/ccam.json")) as unknown) as Record<string, string>);
		})();
	}, []);

	return ccamDatabase;
}
