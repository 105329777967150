import styled from "@emotion/styled";
import { Patient } from "domain/patient";
import { fullName } from "domain/utils";
import React, { useState } from "react";
import { colors, mobile, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { AccountSelector } from "./account-selector";
import { UserType } from "../../domain/user-type";
import { createModal } from "../../services/modal-service";
import { IdeaModal } from "../modals/idea-modal";
import { Bulb, ButtonSupport, ToolTipIdeas } from "./practitioner-nav-bar";

interface Props {
	className?: string;
	patient?: Patient;
}

export const PatientNavBar: React.FC<Props> = ({ className, patient }) => {
	const { format } = useI18n();
	const [showToolTip, setShowToolTip] = useState<boolean>(false);
	return (
		<Container className={className}>
			<Title>
				<img src="/images/logo.svg" alt="" />
				<div>{format("patient.dashboard.title")}</div>
			</Title>
			<AccountSelector name={fullName(patient)} accountType={UserType.Patient} />
			<FloatingButton
				onMouseOver={() => setShowToolTip(true)}
				onMouseOut={() => {
					setShowToolTip(false);
				}}
				onClick={() => {
					createModal(({ onClose }) => <IdeaModal onClose={onClose} profile={patient} />);
				}}
			>
				<ButtonCustomSupport>
					<Bulb />
				</ButtonCustomSupport>
				<ToolTipIdeas>{format("ideas.box.button.tooltip")}</ToolTipIdeas>
			</FloatingButton>
		</Container>
	);
};

const Container = styled.div`
	${row("L", "space-between", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		padding: ${sizes.M};
		${stack("M", "flex-start", "flex-start")};
		margin-left: -20px;
		margin-right: -20px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;

const Title = styled.div`
	${row("S", "flex-start", "center")}
	font-size: 27px;
	font-weight: bold;
	color: ${colors.black};

	img {
		width: 29px;
		height: 25px;
	}
`;

const FloatingButton = styled.div`
	position: fixed;
`;

const ButtonCustomSupport = styled(ButtonSupport)`
	@media ${mobile} {
		display: none;
	}
`;
