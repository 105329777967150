import React, {useCallback, useState} from "react";
import {Button} from "../button";
import styled from "@emotion/styled";
import {colors, sizes} from "../../style";
import {secretaryService} from "../../services/secretary-service";
import {practitionerService} from "../../services/practitioner-service";
import {useUser} from "../../services/auth-service";

export const WeblinkUploader: React.FC<{ setWeblinkWindow: any, practitionerId: string, surgeryId: string }> = ({ setWeblinkWindow, practitionerId, surgeryId }) => {
    const [webLinkValue, setWeblinkValue] = useState("");
    const [webLinkDescription, setWeblinkDescription] = useState("Vidéo explicative de votre intervention");
    const user = useUser();
    function isValidUrl(urlString: string): boolean{
        try {
            const xhr = new XMLHttpRequest();
            const newUrl = new URL(urlString);
            xhr.open('get', urlString, true);
            const isUrlAlive: boolean = (xhr.status == 200 || xhr.status == 204 || xhr.status == 0);
            const pattern = new RegExp(
                '^([a-zA-Z]+:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', // fragment locator
                'i'
            );
            return (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') && pattern.test(urlString) && isUrlAlive;
        }
        catch(e){
            return false;
        }
    }
    function isDescriptionFilled(description: string): boolean {
        return description != ""
    }
    const resetWebLinkInputs = () => {
        setWeblinkValue("");
        setWeblinkDescription("Vidéo explicative de votre intervention");
    }

    const addWeblink = useCallback(
        async (urlString: string, description: string) => {
            switch (user?.type) {
                case "secretary": {
                    await secretaryService.addWeblink(practitionerId, surgeryId, urlString, description);
                    await secretaryService.fetchSurgery(surgeryId, practitionerId);
                    break;
                }
                case "practitioner": {
                    await practitionerService.addWeblink(surgeryId, urlString, description);
                    await practitionerService.fetchSurgery(surgeryId);
                    break;
                }
                default:
                    break;
            }
    }, []);

    return (
        <WeblinkUploaderWrapper>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "110px", padding: "8px 5px 0 5px", fontSize: "15px" }}>lien vidéo:</div>
                    <Input
                        type="text"
                        style={{ width: "320px" }}
                        placeholder="https://www.youtube.com/watch?v=..."
                        onChange={(e) => setWeblinkValue(e.target.value)}
                    ></Input>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "110px", padding: "8px 5px 0 5px", fontSize: "15px" }}>description:</div>
                    <Input
                        type="text"
                        style={{ width: "320px" }}
                        placeholder="Entrer une description de la vidéo"
                        defaultValue={webLinkDescription}
                        onChange={(e) => setWeblinkDescription(e.target.value)}
                    ></Input>
                </div>
            </div>
            <Button
                disabled={!isValidUrl(webLinkValue) || !isDescriptionFilled(webLinkDescription)}
                type="button"
                onClick={ async () => {
                    await addWeblink(webLinkValue, webLinkDescription);
                    resetWebLinkInputs();
                    setWeblinkWindow(false);
                }}
                style={{ width: "120px", marginBottom: "10px", alignSelf: "flex-end" }}
            >
                Valider
            </Button>
            <CloseIconWrapper
                onClick={ () => {
                    resetWebLinkInputs();
                    setWeblinkWindow(false);
                }}>
                <CloseIcon src="/icons/close.svg"></CloseIcon>
            </CloseIconWrapper>
        </WeblinkUploaderWrapper>
    )
}

const WeblinkUploaderWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-self: flex-end;
	width: 600px;
	background-color: ${colors.white};
	border-radius: 15px;
	border: 1px solid ${colors.grey};
	box-shadow: 5px 8px 8px rgba(0, 0, 0, 0.1);
	height: 126px;
	padding: 25px 10px 5px 10px;
`;

const Input = styled.input<{ icon?: boolean; rightIcon?: boolean; popout?: boolean; disabled?: boolean }>`
	appearance: none;
	height: 40px;
	border-radius: 7px;
	padding: 0;

	border: none;
	box-shadow: ${props => (props.popout ? `0px 0px 0px 1px ${colors.white} inset` : "0px 0px 0px 1px #c8efec inset")};

	background-color: ${props => (props.disabled ? "rgba(0,0,0,0.06)" : colors.white)};
	padding-left: ${props => (props.icon ? sizes.XL : sizes.S)};
	padding-right: ${props => (props.rightIcon ? sizes.XL : sizes.S)};
	flex-grow: 1;

	::placeholder {
		font-size: 13px;
		font-style: italic;
		color: ${colors.grey};
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}

	transition: box-shadow 0.15s ease-out;
`;

const CloseIconWrapper = styled.div`
	position: absolute;
	top: -1px;
	right: 0;
`;

const CloseIcon = styled.img`
	height: 24px;
	
	&:hover {
		transform: scale(1.1);
	}
`;
