import React, {useRef, useState} from "react";
import styled from "@emotion/styled";
import {ModalCardContainer, ModalCloseIcon} from "../../services/modal-service";
import {colors, row, sizes, stack} from "../../style";
import {CheckboxInput} from "../inputs/checkbox-input";
import ReactPlayer from "react-player";
import {useI18n} from "../../utils/i18n";
import {userProfileService} from "../../services/user-profile-service";
import {UserProfile} from "../../domain/user-profile";

export const CreateVideoPlayerModal: React.FC<{
    onClose: (value: string | null) => void;
    videoPath: string | undefined;
    timelineCursor?: number;
    displayCheckbox?: boolean;
    userPrfl?: UserProfile | null;
    title?: string | null;
}> = ( {onClose, videoPath, timelineCursor, displayCheckbox, userPrfl, title} ) => {
    const { format } = useI18n();
    const [checked, setChecked] = useState(false);
    const handleChange = async () => {
        await setChecked(!checked)
    };
    const handleClose = async () => {
        if (checked && userPrfl){
            const strUserProfileId = JSON.parse(JSON.stringify(userPrfl.id)).value.toString();
            await userProfileService.setHasWatchedTuto(strUserProfileId)
        }
    }
    const [isReady, setIsReady] = React.useState(false);
    const playerRef = useRef<ReactPlayer>(null);
    const onReady = React.useCallback(() => {
        if (!isReady && playerRef.current && timelineCursor) {
            playerRef.current.seekTo(timelineCursor ?? 0, "seconds");
            setIsReady(true);
        }
    }, [isReady, playerRef.current]);

    return (
        <Card>
            <ModalCloseIcon onClick={() => {
                onClose(null);
                handleClose();
            }}
            />
            <Title>{title ? title : format("video-player-modal.title")}</Title>
            <Content>
                <ReactPlayer
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    onContextMenu={(e: Event) => e.preventDefault()}
                    ref={playerRef}
                    url={videoPath}
                    playing={true}
                    width="70%"
                    height="70%"
                    controls={true}
                    onReady={onReady}
                    // volume={0.1}
                    muted
                />
            </Content>
            {displayCheckbox && (
                <Row>
                    <RowIcon>
                        <CheckboxInput
                            value={checked}
                            onChange={handleChange}
                        >
                        </CheckboxInput>
                        {format("video-player-modal.checkbox.label")}
                    </RowIcon>
                </Row>
            )}
        </Card>
    );
};

const Card = styled(ModalCardContainer)`
	padding: 63px 80px;
	${stack("M")};

	@media (max-width: 920px) {
		padding: ${sizes.L};
	}
`;

const Title = styled.h1`
	font-size: 27px;
	max-width: 650px;
	color: ${colors.black};
`;

const Content = styled.div`
	${row(0, "center", "center")};
`;

const Row= styled.div`
	${row("L", "flex-start", "center")}
`;

const RowIcon= styled.div`
	${row("S", "flex-end", "flex-end")}
	position: relative;
`;
