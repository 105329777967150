import styled from "@emotion/styled";
import { Button } from "components/button";
import { FormField } from "components/form-field";
import { SelectInput } from "components/inputs/select-input";
import { SectionTitle } from "components/section";
import {
	HCI,
	hpState,
	Surgery,
	surgeryHealthCareProviderValues,
	SurgeryUpdateDTO,
	VisitType,
} from "domain/surgery";
import { motion } from "framer-motion";
import React, { CSSProperties, FormEvent, useCallback, useEffect, useState } from "react";
import { practitionerService, useHealthCareProviders, usePractitionerProfile } from "services/practitioner-service";
import { colors, desktop, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { normalizeDate } from "utils/time";
import { createModal } from "../../services/modal-service";
import { ConfirmSurgeryEditModal } from "../modals/confrm-surgery-edit-modal";
import { CheckboxInput } from "../inputs/checkbox-input";
import { DatePickerInput } from "../inputs/datepicker-input";

interface SurgerySectionProps {
	surgery: Surgery;
	practitionerId?: string | null | undefined;
	hci?: HCI;
	onEdit?: (data: SurgeryUpdateDTO) => Promise<void>;
	overrideTitle?: string;
	style?: CSSProperties;
}

export const SurgeryVisitSection: React.FC<SurgerySectionProps> = ({
	surgery,
	practitionerId,
	onEdit,
	style,
	hci: hciProp,
}) => {
	const { format, formatDate, formatHealthCareProviderReason } = useI18n();
	const [hciPatientId, setHciPatientId] = useState("");
	const [hciVisitId, setHciVisitId] = useState("");
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hci, setHci] = useState("");
	const [visitStartDate, setVisitStartDate] = useState<string | null>(null);
	const [visitEndDate, setVisitEndDate] = useState<string | null>(null);
	const practitioner = usePractitionerProfile();
	const healthcareProviders = useHealthCareProviders();
	const [needHealthcareProvider, setNeedHealthcareProvider] = useState(!!surgery.healthcareProviderId);
	const [healthcareProvider, setHealthcareProvider] = useState<string | null>(
		healthcareProviders ? healthcareProviders[0].id : null
	);
	const [healthcareProviderReason, sethealthcareProviderReason] = useState(surgery.healthcareProviderReason ?? "");
	const [healthcareProviderModule, setHealthcareProviderModule] = useState(false);
	const modules = practitionerService.fetchPractitionerModulesByHospitalId(surgery.hospitalId ?? hci);
	useEffect(() => {
		practitionerService.fetchPractitionerModulesByHospitalId(surgery.hospitalId ?? hci).then();
		modules.then(module => {
			module?.find(module => module.type === "healthcare-provider")
				? setHealthcareProviderModule(true)
				: setHealthcareProviderModule(false);
		});
	}, [hci]);

	const setEditingAndResetFields = useCallback(
		(editing: boolean) => {
			setEditing(editing);
			setHci(surgery.hospitalId ?? "");
			setHciPatientId(surgery.hciPatientId ?? "");
			setHciVisitId(surgery.hciVisitId ?? "");
			setVisitStartDate(surgery.visitStartDate ?? null);
			setVisitEndDate(surgery.visitEndDate ?? null);
			setHealthcareProvider(surgery.healthcareProviderId ?? null);
			setSurgeryDate(surgery.surgeryDate ?? null);
		},
		[
			surgery.hciPatientId,
			surgery.hciVisitId,
			surgery.hospitalId,
			surgery.visitEndDate,
			surgery.visitStartDate,
			surgery.healthcareProviderId,
			surgery.surgeryDate,
		]
	);
	const [surgeryDate, setSurgeryDate] = useState("");
	const save = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			if (loading) {
				return;
			}
			try {
				setLoading(true);
				await createModal<string | null>(({ onClose }) => (
					<ConfirmSurgeryEditModal
						onClose={onClose}
						practitionerId={practitionerId}
						surgeryId={surgery.id}
						surgery={surgery}
						hciPatientId={hciPatientId ?? undefined}
						hciVisitId={hciVisitId ?? undefined}
						hospitalId={hci ?? undefined}
						visitType={visitStartDate === visitEndDate ? VisitType.Outpatient : VisitType.Inpatient}
						visitStartDate={visitStartDate ?? undefined}
						visitEndDate={visitEndDate ?? undefined}
						healthcareProviderId={healthcareProvider ?? undefined}
						needHealthcareProvider={needHealthcareProvider ?? undefined}
						healthcareProviderReason={healthcareProviderReason ?? undefined}
						surgeryDate={surgeryDate ?? undefined}
					/>
				));
			} catch (e) {
				console.log(e);
			} finally {
				setEditingAndResetFields(false);
				setLoading(false);
			}
		},
		[
			hci,
			hciPatientId,
			hciVisitId,
			loading,
			onEdit,
			setEditingAndResetFields,
			visitEndDate,
			visitStartDate,
			healthcareProvider,
			healthcareProviderReason,
			surgeryDate,
		]
	);

	const areDataMissing =
		!surgery.hciPatientId ||
		!surgery.hciVisitId ||
		!surgery.visitType;

	const [selectedDate, setSelectedDate] = useState<Date| undefined>(new Date(normalizeDate(surgery.surgeryDate)));
	const selectedDateFunct = useCallback(
		async (e) => {
			setSelectedDate(new Date(normalizeDate(e.toLocaleString())))
		},
		[visitStartDate, surgeryDate]
	);

	return (
		<VisitSection style={style}>
			<SectionTitle>{format("surgeryVisitSection.title")}</SectionTitle>
			<Form onSubmit={save}>
				{!editing ? (
					<>
						<FormField label={format("surgeryVisitSection.hci.label")} style={{ gridArea: "hci" }}>
							<FormValue>
								{hciProp
									? hciProp.name
									: practitioner?.hospitals.find(hci => surgery.hospitalId === hci.id)?.name ?? ""}
							</FormValue>
						</FormField>

						<Fields>
							<FormField
								label={format("createSurgeryModal.date.label")}
								style={{ gridArea: "surgeryDate" }}
							>
								<FormValue>{formatDate(surgery.surgeryDate)}</FormValue>
							</FormField>
							<FormField
								label={format("surgeryVisitSection.visitStartDate.label")}
								style={{ gridArea: "surgeryDateStart" }}
							>
								<FormValue>{formatDate(surgery.visitStartDate)}</FormValue>
							</FormField>

							<FormField
								label={format("surgeryVisitSection.visitEndDate.label")}
								style={{ gridArea: "surgeryDateEnd" }}
							>
								<FormValue>{formatDate(surgery.visitEndDate)}</FormValue>
							</FormField>

							<FormField
								label={format("surgeryVisitSection.healthcare.provider.label")}
								style={{ gridArea: "healthcareProvider" }}
							>
								<FormValue>
									{(healthcareProviders &&
										healthcareProviders.find(hp => hp.healthcareProvider.id == surgery.healthcareProviderId)?.name) ??
										"Aucun"}
								</FormValue>
							</FormField>
							<FormField
								label={format("secretary.create.surgery.healthcareProvider.reason")}
								style={{ gridArea: "healthcareProviderReason" }}
							>
								{healthcareProviderReason.length > 0 ? healthcareProviderReason : "Aucun"}
							</FormField>

						</Fields>
						<Buttons>
							{onEdit ? (
								<Button onClick={() => setEditingAndResetFields(true)} type="button">
									{format(
										areDataMissing ? "surgeryVisitSection.edit.completeButton" : "surgeryVisitSection.edit.editButton"
									)}
								</Button>
							) : null}
						</Buttons>
					</>
				) : (
					<>
						<FormField label={format("surgeryVisitSection.hci.label")} style={{ gridArea: "hci" }}>
							{hciProp ? (
								<FormValue>{hciProp.name}</FormValue>
							) : (
								<SelectInput<string>
									innerId="institution"
									value={hci}
									onChange={setHci}
									options={practitioner?.hospitals.map(hci => hci.id) ?? []}
									itemRenderer={id => practitioner?.hospitals.find(hci => hci.id === id)?.name ?? ""}
									style={{ gridArea: "hic" }}
									autoFocus
								/>
							)}
						</FormField>

						<Fields>
							<FormField style={{ gridArea: "surgeryDate" }} label={format("createSurgeryModal.date.label")}>
								<DatePickerInput
									placeholder={format("createSurgeryModal.date.placeholder")}
									onChange={e => {
										setSurgeryDate(normalizeDate(e.toLocaleString()));
										setVisitStartDate(normalizeDate(e.toLocaleString()));
										setVisitEndDate(normalizeDate(e.toLocaleString()));
										selectedDateFunct(e).then();
									}}
									surgeryDate={selectedDate}
									newDate={surgeryDate}
									required
								/>
							</FormField>
							<FormField style={{ gridArea: "surgeryDateStart" }} label={format("createSurgeryModal.dateStart.label")}>
								<DatePickerInput
									placeholder={format("createSurgeryModal.date.placeholder")}
									onChange={e => {
										setVisitStartDate(normalizeDate(e.toLocaleString()));
										setVisitEndDate(normalizeDate(e.toLocaleString()));
									}}
									surgeryDate={selectedDate}
									newDate={visitStartDate}
									required
								/>
							</FormField>
							<FormField style={{ gridArea: "surgeryDateEnd" }} label={format("createSurgeryModal.dateEnd.label")}>
								<DatePickerInput
									placeholder={format("createSurgeryModal.date.placeholder")}
									onChange={e => {
										setVisitEndDate(normalizeDate(e.toLocaleString()));
									}}
									surgeryDate={selectedDate}
									minDate={visitStartDate??undefined}
									newDate={visitEndDate}
									required
								/>
							</FormField>

							{healthcareProviderModule && (
								<FormField
									label={format("surgeryVisitSection.healthcare.provider.label")}
									style={{ gridArea: "healthcareProvider" }}
								>
									<RowHealthcareProvider>
										<Text>{format("createSurgeryModal.healthcareProvider.text")}</Text>
										<CheckboxInput
											value={needHealthcareProvider}
											onChange={() => {
												setHealthcareProvider(null);
												setNeedHealthcareProvider(!needHealthcareProvider);
											}}
										></CheckboxInput>
									</RowHealthcareProvider>
								</FormField>
							)}
							<FormField label="" style={{ gridArea: "healthcareProviderSelect" }}>
								{(surgery.hpState != hpState.ACCEPTED) &&
								needHealthcareProvider &&
								healthcareProviderModule ? (
									<RowHealthcareProvider>
										<HealthcareDiv>
											<div>{format("secretary.create.surgery.healthcareProvider.psad")}</div>
											<SelectInput<string>
												innerId="healthcareProvider"
												value={healthcareProvider}
												onChange={setHealthcareProvider}
												placeholder={format("createSurgeryModal.hci.placeholder")}
												options={
													healthcareProviders
														?.filter(hp => hp.enabled)
														.sort((hp, hpNext) => hp.name.localeCompare(hpNext.name))
														.map(hp => hp.id) ?? []
												}
												itemRenderer={id => healthcareProviders?.find(hp => hp.id === id)?.name ?? ""}
												autoFocus
												required
											/>
										</HealthcareDiv>
										{needHealthcareProvider && healthcareProvider && (
											<HealthcareDiv>
												<>
													<div>{format("secretary.create.surgery.healthcareProvider.reason")}</div>
													<SelectInput<string>
														innerId="healthcareProviderReason"
														value={healthcareProviderReason}
														onChange={sethealthcareProviderReason}
														placeholder={format("createSurgeryModal.hp.heal")}
														options={surgeryHealthCareProviderValues}
														itemRenderer={formatHealthCareProviderReason}
														autoFocus
														required
													/>
												</>
											</HealthcareDiv>
										)}
									</RowHealthcareProvider>
								) : null}
							</FormField>
						</Fields>
						<Buttons layout>
							<Button
								key="submit"
								type="submit"
								initial={{ opacity: 0 }}
								loading={loading}
								animate={{ opacity: 1, transition: { delay: 0.3 } }}
							>
								{format("surgeryVisitSection.edit.validButton")}
							</Button>
							<Button
								secondary
								onClick={() => setEditingAndResetFields(false)}
								type="button"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { delay: 0.3 } }}
							>
								{format("surgeryVisitSection.edit.cancelButton")}
							</Button>
						</Buttons>
					</>
				)}
			</Form>
		</VisitSection>
	);
};

const Form = styled.form`
	${stack("L", "flex-start", "stretch")};
	flex-grow: 1;
`;

const FormValue = styled.div``;

const Fields = styled.div`
	display: grid;
	grid-row-gap: ${sizes.M};
	grid-column-gap: ${sizes.XL};
	grid-template-columns: 33fr 33fr 33fr;
	grid-template-areas:
		"surgeryDate surgeryDateStart surgeryDateEnd"
		"patientId visitId blank"
		"healthcareProvider healthcareProviderReason blank"
		"healthcareProviderSelect healthcareProviderSelect blank";
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};

	> * {
		flex-grow: 1;
	}
`;

const VisitSection = styled(motion.div)`
	${stack("L", "flex-start", "stretch")}
	width: 100%;
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
		height: initial;
	}
`;

const Text = styled.div`
	padding-right: 15px;
`;

const RowHealthcareProvider = styled.div`
	${row(0)}
`;

const HealthcareDiv = styled.div`
	width: 400px;
`;
