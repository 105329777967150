import CheckIcon from "@assets/icons/check.svg";
import ErrorIcon from "@assets/icons/error.svg";
import WaitIcon from "@assets/icons/wait.svg";
import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { colors, row } from "style";

export const RedFlag: React.FC<{ withIcon?: boolean } & HTMLAttributes<HTMLDivElement>> = ({
	withIcon,
	children,
	...otherProps
}) => {
	return (
		<RowFlag style={{ backgroundColor: "#ffe9ec", color: colors.red }} {...otherProps}>
			{children}
			{withIcon && <StyledErrorIcon />}
		</RowFlag>
	);
};

export const GreenFlag: React.FC<
	{ withIcon?: boolean; withBackgroundColor?: boolean } & HTMLAttributes<HTMLDivElement>
> = ({ withIcon, withBackgroundColor, children, ...otherProps }) => {
	return withBackgroundColor ? (
		<RowFlag style={{ backgroundColor: "#d6f5f4", color: colors.green }} {...otherProps}>
			{children}
			{withIcon && <ValidIcon />}
		</RowFlag>
	) : (
		<ClassicRow {...otherProps}>
			{children}
			{withIcon && <ValidIcon />}
		</ClassicRow>
	);
};

export const BlueFlag: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...otherProps }) => {
	return (
		<RowFlag style={{ backgroundColor: "#dbedff", color: colors.blue2 }} {...otherProps}>
			{children}
			<StyledWaitIcon />
		</RowFlag>
	);
};

export const GreyFlag: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...otherProps }) => {
	return (
		<Flag style={{ backgroundColor: "#eaeaea", color: "#535353" }} {...otherProps}>
			{children}
		</Flag>
	);
};

export const OrangeFlag: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...otherProps }) => {
	return (
		<Flag style={{ backgroundColor: "#fff5eb", color: colors.orange }} {...otherProps}>
			{children}
		</Flag>
	);
};

const Flag = styled.div`
	padding: 1px 8px 2px 8px;
	border-radius: 10px;
	font-size: 12px;
	font-weight: bold;
`;

const RowFlag = styled(Flag)`
	${row(0, "center", "center")};

	svg {
		height: 15px;
		width: 15px;
		margin-left: 3px;
		margin-right: -2px;
	}
`;

const ClassicRow = styled.div`
	${row(0, "center", "center")};

	svg {
		height: 15px;
		width: 15px;
		margin-left: 3px;
		margin-right: -2px;
	}
`;

const ValidIcon = styled(CheckIcon)`
	path {
		fill: ${colors.green};
	}
`;

const StyledWaitIcon = styled(WaitIcon)`
	path {
		fill: ${colors.blue2};
	}
`;

const StyledErrorIcon = styled(ErrorIcon)`
	path {
		fill: ${colors.red};
	}
`;
