import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import React, {useEffect, useState} from "react";
import { colors, mobile, row, sizes } from "style";
import {SurgeryStep} from "../domain/surgery-step";

export const TooltipSteps: React.FC<{ show?: boolean; steps: string }> = ({ children, show, steps}) => {
	const [x, setX] = useState("10px")
	useEffect(() => {
		switch (steps){
			case SurgeryStep.PatientInfo:
				setX("91px");
				break;
			case SurgeryStep.PatientDocuments:
				setX("134px");
				break;
			case SurgeryStep.ForensicDocuments:
				setX("176px");
				break;
			case SurgeryStep.Surgery:
				setX("218px");
				break;
			case "info":
				setX("500px");
				break;
		}
	}, [steps])

	return (
		<AnimatePresence>
			{show && (
				<TooltipAnchor
					animate={{ y: 10, opacity: 1 }}
					transition={{ type: "spring" }}
					exit={{y: 0, opacity: 0}}
					x={x}
				>
					<TooltipContainer className="tooltip-container">
						<div>{children}</div>
					</TooltipContainer>
				</TooltipAnchor>
			)}
		</AnimatePresence>
	);
};

const TooltipAnchor = styled(motion.div)<{ x?: string }>`
	${row("S", "center", "center")};
	position: absolute;
	top: -70px;
	width: 10px;
	height: 10px;
	background: blue;
	${row(0, "center", "center")};

	@media ${mobile} {
		display: none;
	}
`;

export const TooltipContainer = styled.div`
	position: absolute;
	right: 0px;
	width: 350px;
	box-shadow: 0 0px 7px 0 rgba(70, 62, 62, 0.15);
	background-color: ${colors.white};
	padding: ${sizes.S};
	border-radius: 3px;
`;
