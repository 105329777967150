import { WritableObservable } from "micro-observables";
import { api } from "./api";
import { Ideas } from "../components/modals/idea-modal";

export class IdeasService {
	public ideas = new WritableObservable<Ideas | null>(null);

	async createIdea(idea: Ideas): Promise<Ideas> {
		const newIdea = await api.post<Ideas>("kpi/new", idea);
		return newIdea.data;
	}
}

export const ideasService = new IdeasService();
