import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { colors, mobile, row, sizes, stack } from "style";
import { hpuService } from "../../services/hpu-service";
import { useI18n } from "../../utils/i18n";

export const HpuDocsNavBar: React.FC<{
	toDo?: number;
	accepted?: number;
	archived?: number;
	refused?: number;
	className?: string;
}> = ({ toDo, accepted, archived, refused, className }) => {
	const location = useLocation();
	const { format } = useI18n();
	useEffect(() => {
		hpuService.fetchProfile().then();
	}, []);

	return (
		<Container className={className}>
			<Tab
				to="/hpu/dashboard-patients-accepted"
				selected={
					!!location.pathname.match(
						"/hpu/dashboard-patients-accepted"
					) /*||
                    !!location.pathname.match("/hpu/patient/[a-zA-Z0-9]+$")*/
				}
				counter={accepted!} // retirer '!', et le '?' des props quand_toDo et archived seront passées aussi
			>
				{format("hpu.navigation.inProgress")}
			</Tab>
			<Tab
				to="/hpu/dashboard-patients-refused"
				selected={
					!!location.pathname.match(
						"/hpu/dashboard-patients-refused"
					) /*||
                    !!location.pathname.match("/hpu/patient/[a-zA-Z0-9]+$")*/
				}
				counter={refused!}
			>
				Refusés
			</Tab>
			<Tab
				to="/hpu/dashboard-patients-archived"
				selected={
					!!location.pathname.match(
						"/hpu/dashboard-patients-archived"
					) /*||
                    !!location.pathname.match("/hpu/patient/[a-zA-Z0-9]+$")*/
				}
				counter={archived!}
			>
				Archivés
			</Tab>
		</Container>
	);
};

const Container = styled.div`
	${row("S", "space-evenly", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "flex-start", "flex-start")};
		padding: ${sizes.S};
	}
`;

const Tab = styled(Link)<{ selected: boolean; counter: number }>`
	display: block;
	position: relative;
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;
	margin: auto 150px;

	${({ selected }) =>
		selected &&
		css`
			opacity: 1;
			:after {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 6px;
				width: 6px;
				border-radius: 3px;
				background-color: ${colors.pink};

				@media ${mobile} {
					left: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		`}

	${({ counter, selected }) =>
		counter >= 0 && counter <= 99
			? css`
					:before {
						color: ${colors.white};
						position: absolute;
						bottom: -8px;
						left: -20px;
						transform: translateX(-50%);
						content: attr(counter);
						text-align: center;
						height: 32px;
						width: 32px;
						border-radius: 50%;
						background-color: ${selected ? colors.pink : colors.grey2};
					}
			  `
			: counter > 99 &&
			  css`
					:before {
						color: ${colors.white};
						position: absolute;
						bottom: -8px;
						left: -20px;
						transform: translateX(-50%);
						content: "99+";
						text-align: center;
						height: 32px;
						width: 32px;
						border-radius: 50%;
						background-color: ${selected ? colors.pink : colors.grey2};
					}
			  `}
`;
