import styled from "@emotion/styled";
import { SurgeryStep } from "domain/surgery-step";
import React from "react";
import { colors, row, stack } from "style";
import { BackIcon } from "@assets/icons/back.svg";
import { css, keyframes } from "@emotion/react";
import { Module } from "domain/surgery";

const icons = {
	[SurgeryStep.PatientInfo]: "/icons/profile.svg",
	[SurgeryStep.PatientDocuments]: "/icons/document-success-white.svg",
	[SurgeryStep.ForensicDocuments]: "/icons/file.svg",
	[SurgeryStep.ForensicHpuDocuments]: "/icons/file.svg",
	[SurgeryStep.ForensicEvaluationDocuments]: "/icons/file.svg",
	[SurgeryStep.Anesthesia]: "/icons/syringe.svg",
	[SurgeryStep.Surgery]: "/icons/bed.svg",
	[SurgeryStep.Evaluation]: "/icons/clipboard-check-white.svg",
	[SurgeryStep.PostOp]: "/icons/calendar-white.svg",
};

const names = {
	[SurgeryStep.PatientInfo]: "Informations",
	[SurgeryStep.PatientDocuments]: "Vérification",
	[SurgeryStep.ForensicDocuments]: "Documents",
	[SurgeryStep.ForensicHpuDocuments]: "Ordonnances",
	[SurgeryStep.ForensicEvaluationDocuments]: "Documents",
	[SurgeryStep.Anesthesia]: "Anesthésie",
	[SurgeryStep.Surgery]: "Intervention",
	[SurgeryStep.Evaluation]: "Evaluation",
	[SurgeryStep.PostOp]: "PostOp",
};

interface SurgeryStepIconProps {
	step: SurgeryStep;
	active?: boolean;
	focused?: boolean;
	pending?: boolean;
	badge?: "complete" | "error";
	modulesFormat?: Module[];
}
export const SurgeryStepIcon: React.FC<SurgeryStepIconProps> = ({ active, focused, pending, step, badge }) => {
	return (
		<Stack>
			<Container focused={focused} color={active ? (pending ? colors.orange : colors.green) : "#CEE7E5"}>
				{focused && (
					<ArrowImage src="/icons/back.svg" color={active ? (pending ? colors.orange : colors.green) : "#CEE7E5"} />
				)}
				<IconImage src={icons[step]} />
				{badge === "complete" && <BadgeImage src="/icons/badge-check.svg" />}
				{badge === "error" && <BadgeImage src="/icons/badge-error.svg" />}
			</Container>
			<NameContainer>
				<Name color={active ? (pending ? colors.orange : colors.green) : "#7a7a7a"}>{names[step]}</Name>
			</NameContainer>
		</Stack>
	);
};

const Container = styled.div<{ focused?: boolean }>`
	${stack(0, "center", "center")};
	width: 34px;
	height: 34px;
	border-radius: 17px;
	position: relative;
	background-color: ${props => props.color};
	box-shadow: ${props => (props.focused ? `0px 0px 0px 3px ${props.color}88, 0px 0px 0px 7px ${props.color}33` : "")};
`;

const Name = styled.div<{ color?: string }>`
	font-size: 12px;
	font-weight: bold;
	position: absolute;
	color: ${props => (props.color ? `${props.color}` : null)};
`;

const NameContainer = styled.div`
	padding-top: 20px;
	width: fit-content;
	${row(0, "center", "center")};
`;

const Stack = styled.div`
	${stack(0, "center", "center")};
	width: max-content;
	height: fit-content;
	padding-top: 10px;
`;

const IconImage = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;

const ArrowImageSettings = css`
	pointer-events: none;
	position: absolute;
	top: -25px;
	width: 22px;
	height: 22px;
`;

const moveUpDown = keyframes`
  0%, 100% {
    top: -40px;
  }
  50% {
    top: -30px;
  }
`;

const ArrowImage = styled(BackIcon)`
	${ArrowImageSettings};
	path {
		fill: ${props => props.color};
	}
	width: 35px;
	height: 35px;
	margin-bottom: 10px;
	animation: ${moveUpDown} 0.8s linear infinite;
`;

const BadgeImage = styled.img`
	pointer-events: none;
	position: absolute;
	top: -7px;
	right: -7px;
	width: 22px;
	height: 22px;
`;
