import { loginSuccess, logoutSuccess } from "./auth-actions";
interface User {
	type: string
	tokens: {
		accessToken: string,
		refreshToken: string
	}
}
interface AuthState {
	user: User | null;
}

const initialState: AuthState = {
	user: null,
};
type AuthAction = ReturnType<typeof loginSuccess> | ReturnType<typeof logoutSuccess>;

const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
	switch (action.type) {
		case "LOGIN_SUCCESS":
			return {
				...state,
				user: {
					type: action.payload.userType,
					tokens: action.payload.tokens,
				},
			};
		case "LOGOUT_SUCCESS":
			return {
				...state,
				user: null,
			};
		default:
			return state;
	}
};

export default authReducer;
