import CheckIcon from "@assets/icons/check.svg";
import styled from "@emotion/styled";
import { Surgery } from "domain/surgery";
import React, { HTMLAttributes } from "react";
import { colors, row } from "style";
import { useI18n } from "utils/i18n";
export const StatePostOperativeField: React.FC<{ surgery: Surgery }> = ({ surgery }) => {
	const { format, formatDate } = useI18n();
	const allDates = surgery.postOperatives?.map(postOp =>
		formatDate(postOp.date, { day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric" })
	);
	return surgery.postOperatives && surgery.postOperatives.length > 0 ? (
		<GreenFlagWithHover
			withIcon
			allDates={allDates?.sort(function (a, b) {
				a = a.split("/").reverse().join("");
				b = b.split("/").reverse().join("");
				return a > b ? 1 : a < b ? -1 : 0;
			})}
		/>
	) : (
		<Row>{format("table.signed-documents.other")}</Row>
	);
};

const Row = styled.div`
	${row(0, "flex-start", "flex-start")};
	margin-left: 5px;
}
`;

export const GreenFlagWithHover: React.FC<
	{ withIcon?: boolean; withBackgroundColor?: boolean; allDates?: string[] } & HTMLAttributes<HTMLDivElement>
> = ({ withIcon, withBackgroundColor, allDates, children, ...otherProps }) => {
	return withBackgroundColor ? (
		<RowFlag style={{ backgroundColor: "#d6f5f4", color: colors.green }} {...otherProps}>
			{children}
			{withIcon && <ValidIcon />}
		</RowFlag>
	) : (
		<ClassicRow {...otherProps}>
			{children}
			{withIcon && <ValidIcon />}
			<ToolTipDates>
				{allDates && allDates?.length > 0 ? allDates?.join("\n").toString() : allDates?.toString()}
			</ToolTipDates>
		</ClassicRow>
	);
};

const ToolTipDates = styled.div`
	display: none;
	visibility: hidden;
	min-width: 120px;
	max-width: 200px;
	background-color: ${colors.white};
	color: ${colors.black};
	text-align: center;
	border-radius: 6px;
	padding: 3px 0;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	white-space: break-spaces;
	/* Position the tooltip */
	z-index: 1000;
	margin-left: 30px;
	margin-top: -8px;
`;

const Flag = styled.div`
	padding: 1px 8px 2px 8px;
	border-radius: 10px;
	font-size: 12px;
	font-weight: bold;
`;

const RowFlag = styled(Flag)`
	svg {
		height: 15px;
		width: 15px;
		margin-left: 3px;
		margin-right: -2px;
	}
`;

const ValidIcon = styled(CheckIcon)`
	path {
		fill: ${colors.green};
	}
`;

const ClassicRow = styled.div`
	${row(0, "flex-start", "flex-start")};
	width: auto;
	&:hover ${ToolTipDates} {
		visibility: visible;
		display: block;
		position: fixed;
		padding: 3px;
	}
	svg {
		height: 15px;
		width: 15px;
		margin-left: 3px;
		margin-right: -2px;
	}
`;
