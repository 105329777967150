import styled from "@emotion/styled";
import React, { Fragment } from "react";
import { colors } from "style";

export interface SectionSelectInputProps<T> {
	innerId: string;
	value: T | null;
	options: readonly { name: string; section: readonly T[] }[];
	disabledOptions?: T[];
	onChange: (v: T) => void;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	disabledArrow?: boolean;
	className?: string;
	itemRenderer?: (item: T) => string;
}

export type SectionSelectInputType<K> = React.FC<SectionSelectInputProps<K>>;

export function SectionSelectInput<T extends { id: string }>({
	innerId,
	label,
	onChange,
	placeholder,
	disabledArrow,
	disabledOptions,
	options,
	value,
	className,
	itemRenderer = v => `${v}`,
	...rest
}: SectionSelectInputProps<T>): JSX.Element {
	const allOptions = options.map(sect => sect.section).flat();
	const selectedIndex = value?.id;

	return (
		<SelectContainer className={className}>
			{label && <StyledLabel htmlFor={innerId}>{label}</StyledLabel>}
			<SelectWrapper>
				<StyledSelect
					name={innerId}
					id={innerId}
					onChange={e => {
						const selectedItem = allOptions.find(item => item.id === e.currentTarget.value);
						if (selectedItem) {
							onChange(selectedItem);
						}
					}}
					value={selectedIndex !== undefined ? selectedIndex : placeholder}
					{...rest}
				>
					{placeholder && !value ? <option disabled>{placeholder}</option> : null}
					{options
						.filter(v => v.section.length > 0)
						.map(v => {
							const { name, section } = v;
							return (
								<Fragment key={name}>
									<option disabled>{name}</option>
									{section.map(sectionItem => (
										<option
											key={sectionItem.id}
											value={sectionItem.id}
											disabled={disabledOptions?.includes(sectionItem)}
										>
											{itemRenderer(sectionItem)}
										</option>
									))}
								</Fragment>
							);
						})}
				</StyledSelect>
				{!disabledArrow ? <ArrowDownIcon src="/icons/back.svg" /> : null}
			</SelectWrapper>
		</SelectContainer>
	);
}

const SelectContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
`;

const StyledLabel = styled.label`
	font-weight: 600;
	color: ${colors.black};
	margin-bottom: 10px;
	text-transform: uppercase;
	font-size: 15px;
`;

const SelectWrapper = styled.div`
	position: relative;
`;

const StyledSelect = styled.select`
	overflow: hidden;

	font-family: inherit;
	appearance: none;
	-webkit-appearance: none;
	font-size: 15px;
	color: ${colors.black};
	flex-shrink: 0;
	width: 300px;
	padding: 8px 30px 10px 16px;
	border: none;
	border-radius: 8px;
	box-shadow: 0px 0px 0px 1px #c8efec inset;
	background-color: ${colors.white};
	appearance: none;
	outline: 0;
	@media (max-width: 380px) {
		width: 240px;
	}

	&:disabled {
		color: ${colors.grey2};
		background: #f4f4f4;
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
	transition: box-shadow 0.15s ease-out;
`;

const ArrowDownIcon = styled.img`
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	width: 15px;
`;
