import { XHRUploadOptions } from "@uppy/xhr-upload";
import { Patient } from "domain/patient";
import {DocCustom, FormValue, HealthcareProvider, Surgery, SurgeryUpdateDTO} from "domain/surgery";
import {useComputedObservable, useObservable, WritableObservable} from "micro-observables";
import {useCallback, useEffect, useMemo} from "react";
import {Paginated} from "utils/pagination";
import {api, apiConfig} from "./api";
import {authService, useUser} from "./auth-service";
import {Map} from "immutable";
import{CredentialsUpdate} from "../domain/utils";
import {TrustedPerson} from "../domain/trusted-person";
import {DocCustomDTO, ResetDocCustomDTO} from "./practitioner-service";
import {UserProfile} from "../domain/user-profile";
import {Scoring} from "../domain/scoring";
import { FormParad } from "../domain/formParad";
import { SurgeryHPU } from "../domain/hpu";

export type HealthcareProviderCreationDTO = Pick<HealthcareProvider, "name">;

export type TrustedPersonCreationDTO = Pick<TrustedPerson, "email" | "phoneNumber" | "firstName" | "lastName" | "address" | "gender"> & {
	codeSms?: string | null;
	surgeryId?: string | null;
};

export type SurgeryDocumentDTO= {
	toRead?: boolean;
	status?: string;
	validationStatus?: string;
}

export type SurgeryDocumentTrustedPersonDTO= {
	choice: string;
	status: string;
}



export type PatientAssignUserProfileDTO = Pick<Patient, "userProfile"> & {
	userProfile: UserProfile,
}

export class PatientService {
	public patient = new WritableObservable<Patient | null>(null);
	public surgeries = new WritableObservable<Surgery[] | null>(null);
	healthcareProviderByPatientId = new WritableObservable<Map<string, HealthcareProvider[]>>(Map());
	docCustoms = new WritableObservable<DocCustom[]>([]);
	formValue = new WritableObservable<FormValue | null>(null);
	formValues = new WritableObservable<FormValue[]>([]);
	surgeryByIds = new WritableObservable<Map<string, Surgery>>(Map());
	formParad = new WritableObservable<FormParad | null>(null);

	public patientCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);

	async fetchPatient() {
		try {
			const result = await api.get<Patient>("patient/profile");
			this.patient.set(result.data);
			return result.data;
		} catch (e) {
			console.log("Query fetchPatient aborted");
		}
		return null;
	}
	// this is here for now, but need to remove it after patching the backend
	async updatePassword(patientCredentials: CredentialsUpdate | null, patch: Partial<Omit<CredentialsUpdate, "assign">> ) {
			const newCredentials = await api.post<CredentialsUpdate>("patient/password-update", {
				newPassword: patientCredentials?.newPassword,
				newPasswordConfirmation: patientCredentials?.newPasswordConfirmation,
				currentPassword: patientCredentials?.currentPassword,
				...patch,
			});
			this.patientCredentials.update(patientMap => patientMap ? (newCredentials.data) : null);
		 }

	async setPatientData(
		patientId: string | "self",
		surgeryId: string,
		data: Partial<Pick<Patient, "gender" | "phoneNumber1" | "phoneNumber2" | "address" | "pharmacy" | "nurse">>
	) {
		if (patientId === "self") {
			const result = await api.post<Patient>(`patient/profile/${surgeryId}`, data);
			this.patient.set(result.data);
			return result.data;
		} else {
			const result = await api.post<Patient>(`patient/patient/${patientId}`, data);
			this.surgeries.update(surgeries =>
				surgeries
					? surgeries.map(surgery => {
							if (surgery.patient?.id === patientId) {
								surgery.patient = result.data;
							}
							return surgery;
					  })
					: surgeries
			);
			return result.data;
		}
	}

	async assignUserProfile(
		// patientId: string,
		userProfile: UserProfile,
	) {
		const result = await api.post("userprofile/assign", userProfile);
		this.patient.set(result.data);
		return result.data;
	}

	async fetchSurgeries() {
		try {
			const [patientSurgeries, wardSurgeries] = await Promise.all([
				api.get<Paginated<Surgery>>("patient/surgery?filter=Mine"),
				api.get<Paginated<Surgery>>("patient/surgery?filter=Ward"),
			]);
			const allSurgeries = [...patientSurgeries.data.content, ...wardSurgeries.data.content];
			this.surgeries.update(() =>allSurgeries);
			return allSurgeries;
		} catch (e) {
			console.log("Query fetchSurgeries aborted");
		}
		return null;
	}

	async fetchSurgery(surgeryId: string){
		const surgery = await api.get<Surgery>(`/patient/surgery/${surgeryId}`);
		// console.log("fetchSurgery: ", surgery);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
		return surgery;
	}

	async setSurgeryData(
		patientId: string,
		surgeryId: string,
		healthcareProviderName: string| null
	)
	{
		const result = await api.post<Surgery>(`/patient/surgery/${surgeryId}/healthcare-provider`, {
			"healthcareProviderName": healthcareProviderName
		});
		this.surgeries.update(surgeries =>
			surgeries
				? surgeries.map(surgery => {
					if (surgery.patient?.id === patientId) {
						surgery.healthcareProvider = result.data.healthcareProvider;
					}
					return surgery;
				})
				: surgeries
		);
		return result.data;
	}
	async deleteDocument(surgeryId: string, documentId: string) {
		await api.delete(`patient/surgery/${surgeryId}/document/${documentId}`);
	}

	async updateDocument(surgeryId: string, documentId: string, data: SurgeryDocumentDTO) {
		await api.post(`patient/surgery/${surgeryId}/document/${documentId}`, data);
	}
	async updateDocumentTrustedPerson(trustedPersonId: string, surgeryId: string, documentId: string, data: SurgeryDocumentTrustedPersonDTO) {
		await api.post(`trusted-person/${trustedPersonId}/surgery/${surgeryId}/document/${documentId}`, data);
	}

	async signDocumentApiV3(surgeryId: string, smsType: boolean): Promise<string> {
		let typeSent = "sms"
		if (!smsType)
			typeSent = "email"
		return (await api.get<string>(`patient/surgery/${surgeryId}/${typeSent}/sign-documents`)).data;
	}

	async etatContratV3(surgeryId: string): Promise<string> {
		return (await api.get<string>(`patient/surgery/${surgeryId}/state/contract`)).data;
	}

	async addSecondPageToSign(surgeryId: string) {
		await api.post(`patient/surgery/${surgeryId}/add/addSecondPageToSign`);
	}

	async signRestDocument(surgeryId: string) {
		await api.post(`patient/surgery/${surgeryId}/sign/rest`);
	}

	async signRestDocumentTutors(surgeryId: string) {
		await api.post(`patient/surgery/${surgeryId}/sign/rest/tutors`);
	}

	async fetchDocCustom(surgeryId: string): Promise<DocCustom[]> {
		const docCustom = await api.get<DocCustom[]>(`patient/surgery/${surgeryId}/docCustoms/forms`);
		this.docCustoms.update(()=>docCustom.data)
		return docCustom.data
	}

	getDocumentUploadEndpoint(surgeryId: string): XHRUploadOptions {
		const user = authService.user.get();
		return {
			endpoint: `${apiConfig.baseURL}/patient/surgery/${surgeryId}/document`,
			formData: true,
			method: "post",
			fieldName: "files",
			bundle: true,
			headers: {
				...apiConfig.headers,
				Authorization: `Bearer ${user?.tokens.accessToken}`,

			},
			timeout: 0,

		} as const;
	}

	async startSignature(surgeryId: string) {
		const result = await api.post<Surgery>(`patient/surgery/${surgeryId}/start-signature`);

		this.surgeries.update(surgeries => {
			if (!surgeries) {
				console.warn("Couldn't update document, surgeries are null");
				return surgeries;
			}
			const surgeryIndex = surgeries.findIndex(surgery => surgeryId === surgery.id);
			surgeries[surgeryIndex] = result.data;
			return [...surgeries];
		});

		return result.data;
	}

	async setAnesthesiaAppointmentDate(surgeryId: string, anesthesiaAppointmentDate: string) {
		const result = await api.post<Surgery>(`patient/surgery/${surgeryId}`, {
			anesthesiaAppointmentDate,
		});

		this.surgeries.update(surgeries => {
			if (!surgeries) {
				console.warn("Couldn't update surgeries, surgeries are null");
				return surgeries;
			}
			const surgeryIndex = surgeries.findIndex(surgery => surgeryId === surgery.id);
			surgeries[surgeryIndex] = result.data;
			return [...surgeries];
		});

		return result.data;
	}

	// On logge les actions du patient sur un document
	async log(surgeryId: string, documentId: string) {
		await api.get(`/patient/log/surgery/${surgeryId}/document/${documentId}`);
	}

	// On logge le bouton "visionner et signer" du patient sur les documents
	async logSign(surgeryId: string) {
		await api.get(`/patient/log/surgery/${surgeryId}/documents`);
	}

	async fetchHealthcareProvider(): Promise<HealthcareProvider[]> {
		const healthcareProvider = await api.get<HealthcareProvider[]>(`/patient/healthcare-provider`);
		this.healthcareProviderByPatientId.update(healthcareProviderMap => healthcareProviderMap.set('patientId', healthcareProvider.data));
		return healthcareProvider.data;
	}

	async createTrustedPerson(patientId: string | undefined, surgeryId: string, trustedPerson: TrustedPersonCreationDTO){
		if(patientId){
			const result = await api.post(`/patient/${patientId}/surgery/${surgeryId}/trusted-person`, trustedPerson)
			return result.data;
		}
	}
	async createFormValues(surgeryId: string, formFieldId: string): Promise<FormValue> {
		const formValue = await api.post<FormValue>(`/patient/surgery/${surgeryId}/form-field/${formFieldId}/form-value`);
		this.formValue.update(()=>formValue.data);
		return formValue.data
	}
	async updateDocCustom(surgeryId: string, update: DocCustomDTO[]): Promise<DocCustom[]> {
		const docCustom = await api.post<DocCustom[]>(`/patient/surgery/${surgeryId}/createCustomDocument`, update);
		return docCustom.data
	}
	async resetDocCustom(surgeryId: string, docCustomId: string, data: ResetDocCustomDTO): Promise<DocCustom> {
		const docCustom = await api.post<DocCustom>(`/patient/surgery/${surgeryId}/doc-custom/${docCustomId}/reset`, data);
		return docCustom.data
	}
	async updateSurgery(surgeryId: string, practitionerId: string, update: SurgeryUpdateDTO): Promise<void> {
		const surgery = await api.post<Surgery>(`/patient/practitioner/${practitionerId}/surgery/${surgeryId}`, update);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}
	async createFormValuesBySurgery(surgeryId: string) {
		const docCustom = await api.post(`/patient/surgery/${surgeryId}/createFormValues`);
		return docCustom.data
	}

	async createScoring(answers: Scoring) {
		await api.post<Scoring>("/scoring/new", answers);
	}
	async deleteScoring(scoring: Scoring | undefined) {
		if(scoring){
			await api.delete<Scoring>(`/scoring/surgery/${scoring.surgeryId}`);
		}
		else {
			console.log("Erreur scoring undefined")
		}
	}

	async getPharmacies(adresse : string){
		const pharmacies = await api.get(`/patient/pharmacies?adresse=${adresse}&type=pharmacy&keyWord=pharmacie`)
		return pharmacies.data
	}
	async getNurses(adresse : string){
		const nurses = await api.get(`/patient/nurses?adresse=${adresse}&type=doctor&keyWord=infirmier`)
		return nurses.data
	}

	async createParad(answers: FormParad, patient: Patient, surgery: Surgery | SurgeryHPU) {
		await api.post<FormParad>(`/patient/${patient.id}/surgery/${surgery.id}/parad/new`, answers);
	}

	async deleteParad(formParad: FormParad) {
		if (formParad.id != undefined) {
			await api.delete(`/patient/parad/${formParad.id}/delete`);
		}
	}

	async fetchParad(patientId: string, surgeryId: string) {
		try {
			const result = await api.get<FormParad>(`patient/${patientId}/surgery/${surgeryId}/parad`);
			this.formParad.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPatient aborted");
		}
		return null;
	}
}

export const patientService = new PatientService();

export function useSurgeries(): { surgeries: Surgery[] | null; pastSurgeries: Surgery[]; futureSurgeries: Surgery[] } {
	const surgeries = useObservable(patientService.surgeries);
	const [pastSurgeries, futureSurgeries] = useMemo(() => {
		const actualDate = new Date();
		return (
			surgeries?.reduce(
				(acc: [Surgery[], Surgery[]], surgery) =>
					new Date(surgery.surgeryDate) > actualDate ? [acc[0], [...acc[1], surgery]] : [[...acc[0], surgery], acc[1]],
				[[], []]
			) ?? [[], []]
		);
	}, [surgeries]);

	return {
		surgeries,
		pastSurgeries,
		futureSurgeries,
	};
}

export function useHealthCareProviders() {
	const user = useUser();
	const healthcareProvider = useComputedObservable(() => patientService.healthcareProviderByPatientId.get().get('patientId'));
	const fetchHealthcareProvider = useCallback(async () => {
		try {
			if (user) {
				await patientService.fetchHealthcareProvider();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);
	useEffect(() => {
		fetchHealthcareProvider().then();
	}, [fetchHealthcareProvider]);
	return healthcareProvider;
}

export function useDocs(surgeryId?: string): {docCustomForms: DocCustom[]} {
	const user = useUser();
	const docCustomForms = useComputedObservable(() => patientService.docCustoms.get(), []);
	const fetchDocs = useCallback(async () => {
		try {
			if (user && surgeryId) {
				await patientService.fetchDocCustom(surgeryId);
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [user]);
	useEffect(() => {
		fetchDocs().then();
	}, [fetchDocs]);
	return {docCustomForms};
}

export function usePatient(): { currentPatient: Patient | null } {
	const user = useUser();
	const currentPatient = useComputedObservable(() => patientService.patient.get(), [])
	const fetchPatient = useCallback(async () => {
		try {
			if (user) {
				await patientService.fetchPatient();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);
	useEffect(() => {
		fetchPatient().then();
	}, [fetchPatient]);
	return {currentPatient};
}

export function useSurgery(surgeryId: string): {surgery: Surgery | undefined} {
	const user = useUser();
	const surgery = useComputedObservable(() => patientService.surgeryByIds.get().get(surgeryId), [])
	const fetchSurgery = useCallback(async () => {
		try {
			if(user){
				await patientService.fetchSurgery(surgeryId)
			}
		}catch (e) {
			console.error()
		}
	}, [user])
	useEffect(() => {
		fetchSurgery().then()
	},[fetchSurgery])
	return {surgery}
}

export function usePatientFormParad(patientId: string, surgeryId: string, audience: string): { patientFormParad: FormParad | null } {
	const user = useUser();
	const patientFormParad = useComputedObservable(() => patientService.formParad.get(), [])
	const fetchFormParad = useCallback(async () => {
		try {
			if (user && audience == "patient") {
				await patientService.fetchParad(patientId, surgeryId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, patientId, surgeryId]);
	useEffect(() => {
		fetchFormParad().then();
	}, [fetchFormParad]);
	return {patientFormParad};
}
