import styled from "@emotion/styled";
import { SurgeryStepIcon } from "components/surgery-step-icon";
import { Patient } from "domain/patient";
import { allSurgerySteps, SurgeryStep } from "domain/surgery-step";
import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import {colors, row} from "style";

interface PractitionerSurgeryTimelineProps {
	step: SurgeryStep;
	patient: Patient;
	surgeryId?: string;
	modules?: string[];
}

export const PractitionerSurgeryTimeline: React.FC<PractitionerSurgeryTimelineProps> = ({
	step: currentStep,
	patient,
	surgeryId,
	modules,
}) => {

	// region Modules - on ajoute le module post operative
	let arraySteps = allSurgerySteps;
	if(modules){
		for (let i = 0; i <= modules.length; i++){
			switch (true) {
				case modules?.some(item => 'anesthesia' == item) &&
				!allSurgerySteps.some(item => SurgeryStep.Anesthesia == item)&&
				!arraySteps.some(item => SurgeryStep.Anesthesia == item) :
					// arraySteps = [...arraySteps, SurgeryStep.Anesthesia];
					arraySteps = [allSurgerySteps[0], SurgeryStep.Anesthesia, allSurgerySteps[1]];
					break;
				case !arraySteps.some(item => SurgeryStep.Surgery == item) :
					arraySteps = [...arraySteps, SurgeryStep.Surgery];
					break;
				case modules?.some(item => 'post-operative' == item) &&
				!allSurgerySteps.some(item => SurgeryStep.PostOp == item) &&
				!arraySteps.some(item => SurgeryStep.PostOp  == item) :
					arraySteps = [...arraySteps, SurgeryStep.PostOp];
					break;
				default:
					break;
			}
		}
	}
	// endregion

	return (
		<Steps>
			{arraySteps.map((step, index) => (
				<Fragment key={index}>
					<Link key={step} to={`/practitioner/patient/${patient.id}/surgery/${surgeryId}/${step}`}>
						<SurgeryStepIcon step={step} active={true} focused={currentStep === step} />
					</Link>
					{index < arraySteps.length-1 ?
						<Progress pending={true}/>
						: null
					}
				</Fragment>

			))}
		</Steps>
	);
};

const Progress = styled.div<{ pending: boolean | undefined }>`
	width: 31px;
	height: 4px;
	border-radius: 2.5px;
	background-color: ${colors.grey};
	flex-shrink: 1;
	flex-grow: 1;

	div {
		background-color: ${props => (props.pending ? colors.orange : colors.green)};
	}
`;
const Steps = styled.div`
	${row("S", "center", "center")};
	position: relative;
`;
