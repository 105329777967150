import styled from "@emotion/styled";
import { desktop, mobile, sizes, stack } from "style";

export const Page = styled.div`
	${stack("XL", "flex-start", "stretch")}
	flex-grow: 1;
	min-height: 100vh;
	margin: 0 auto;
	width: 1100px;
	max-width: calc(100vw - 40px);

	@media ${desktop} {
		padding-top: ${sizes.XL};
		padding-bottom: ${sizes.XL};
	}

	@media ${mobile} {
		// Pad for previous/next button shown on several patient pages
		padding-bottom: 100px;
	}
`;
