import { Practitioner, Secretary } from "../../domain/surgery";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "../../services/auth-service";
import { useI18n } from "../../utils/i18n";
import { ModalCardContainer, ModalCloseIcon } from "../../services/modal-service";
import { TextArea, TextInput } from "../inputs/text-input";
import styled from "@emotion/styled";
import { colors, desktop, mobile, row, sizes, Spacer, stack } from "../../style";
import { motion } from "framer-motion";
import { Button } from "../button";
import { ideasService } from "../../services/ideas-service";
import { Hpu } from "../../domain/hpu";
import AttentionIcon from "@assets/icons/warning.svg";
import Robot from "@assets/icons/robot-support.svg";
import { Patient } from "../../domain/patient";
import { css } from "@emotion/react";

interface IdeasModalProps {
	onClose: (status: boolean | null) => void;
	profile?: Practitioner | Secretary | Patient | null;
	hospitalUser?: { id: string; name: string; hospitalName: string; hospitalId: string } | null;
	hpuProfile?: Hpu | null;
}

export interface Ideas {
	id: string;
	firstName?: string;
	lastName?: string;
	audience?: string;
	userId?: string;
	comment?: string;
	createdAt?: string;
}

export const IdeaModal: React.FC<IdeasModalProps> = ({ onClose, profile, hospitalUser, hpuProfile }) => {
	const [loading, setLoading] = useState(false);
	const [showCount, setShowCount] = useState(true);
	const [currentCount, setCurrentCount] = useState(0);
	const totalCount = useRef(255);
	const user = useUser();
	const { format } = useI18n();
	const id = useRef(profile?.id ?? hospitalUser?.id);
	const firstName = useRef(profile?.firstName ?? hpuProfile?.hpuFirstName ?? "");
	const lastName = useRef(profile?.lastName ?? hpuProfile?.hpuLastName ?? "");
	const userName = useRef(hospitalUser?.name ?? "");
	const hospitalName = useRef(hospitalUser?.hospitalName ?? "");
	const role = useRef(user ? user.type : "");
	const [comment, setComment] = useState("");
	const [showFinalText, setShowFinalText] = useState(false);
	const ideas: Ideas = { id: "" };

	useEffect(() => {
		setCurrentCount(comment.length);
	}, [comment]);

	const save = useCallback(async () => {
		try {
			setLoading(true);
			firstName.current != ""
				? (ideas.firstName = firstName.current)
				: userName.current != ""
				? (ideas.firstName = userName.current)
				: null;
			lastName.current != ""
				? (ideas.lastName = lastName.current)
				: hospitalName.current != ""
				? (ideas.lastName = hospitalName.current)
				: null;
			ideas.audience = role.current;
			ideas.userId = id.current;
			ideas.comment = comment;
			if (comment.length > 0) {
				ideasService.createIdea(ideas).then();
			}
		} catch (e: any) {
			throw e.message;
		} finally {
			setLoading(false);
			setShowFinalText(true);
		}
	}, [loading]);

	useEffect(() => {
		// Fonction qui sera exécutée après le délai spécifié
		if (showFinalText) {
			const updateStateWithTimer = () => {
				// Mettez à jour votre état ici
				onClose(null);
			};

			// Définissez le délai en millisecondes (par exemple, 2000 ms pour 2 secondes)
			const delay = 5000;

			// Lancez le timer avec setTimeout
			const timerId = setTimeout(updateStateWithTimer, delay);

			// Nettoyez le timer lorsque le composant est démonté ou lorsque l'effet est nettoyé
			return () => clearTimeout(timerId);
		}
	}, [showFinalText]); // Le tableau vide signifie que cet effet n'a besoin d'être exécuté qu'une seule fois après le montage initial

	return (
		<Card>
			<form
				onSubmit={e => {
					try {
						e.preventDefault();
						save().then();
					} catch (e) {
						console.log("Error sending support question : aborted");
					}
				}}
			>
				<ModalCloseIcon onClick={() => onClose(null)} />
				{!showFinalText ? (
					<>
						<Titles>
							<Title>{format("ideas.box.button.title")} </Title>
							<RowIcon>
								<Attention />
								<Subtitles>
									<Subtitle>{format("ideas.box.button.subtitle.first")}</Subtitle>
								</Subtitles>
							</RowIcon>
						</Titles>
						<Content>
							{profile && (
								<Fields>
									<Label>{format("ideas.box.placeholder.firstName")}</Label>
									<Text value={firstName.current} placeholder={format("ideas.box.placeholder.firstName")} disabled />
								</Fields>
							)}
							{profile && (
								<Fields>
									<Label>{format("ideas.box.placeholder.lastName")}</Label>
									<Text value={lastName.current} placeholder={format("ideas.box.placeholder.lastName")} disabled />
								</Fields>
							)}
							{hospitalUser && (
								<Fields>
									<Label>{format("ideas.box.placeholder.username")}</Label>
									<Text value={userName.current} placeholder={format("ideas.box.placeholder.username")} disabled />
								</Fields>
							)}
							{hospitalUser && (
								<Fields>
									<Label>{format("ideas.box.placeholder.hospital.name")}</Label>
									<Text
										value={hospitalName.current}
										placeholder={format("ideas.box.placeholder.hospital.name")}
										disabled
									/>
								</Fields>
							)}
							<Fields>
								<Label>{format("ideas.box.placeholder.comment")}</Label>
								<CommentDiv>
									<Comment
										value={comment}
										onChange={e => {
											setComment(e.target.value);
										}}
										onFocus={() => setShowCount(true)}
										onBlur={() => setShowCount(false)}
										maxLength={255}
									/>
									{showCount && <Count totalCount={totalCount.current} currentCount={currentCount} />}
								</CommentDiv>
							</Fields>
							<Buttons>
								<Button key="submit" type="submit" loading={loading} onClick={() => setLoading(true)}>
									{format("surgerySection.saveButton")}
								</Button>
								<Button secondary onClick={() => onClose(false)} type="button">
									{format("surgerySection.cancelButton")}
								</Button>
							</Buttons>
						</Content>
					</>
				) : (
					<Titles>
						<Title>{format("ideas.box.button.title")} </Title>
						<RowIcon>
							<RobotIcon />
							<Subtitles>
								<Subtitle>{format("ideas.box.button.subtitle.final")}</Subtitle>
							</Subtitles>
						</RowIcon>
					</Titles>
				)}
			</form>
		</Card>
	);
};

export interface CounterProps {
	totalCount: number;
	currentCount: number;
}

export const Count: React.FC<CounterProps> = ({ totalCount, currentCount }) => {
	return (
		<CountDiv max={currentCount == totalCount}>
			{currentCount}/{totalCount}
		</CountDiv>
	);
};

const Card = styled(ModalCardContainer)`
	${stack("M")};
	@media ${desktop} {
		padding: ${sizes.L};
		width: 800px;
	}
	@media ${mobile} {
		padding: ${sizes.S};
		width: 400px;
	}
`;

const Content = styled.div`
	padding-top: 10px;
	${stack("M", "flex-start", "flex-start")};
`;

const Titles = styled.div`
	width: 100%;
	${stack("S", "flex-start", "flex-start")}
`;

const Subtitles = styled.div`
	${stack(0, "flex-start", "flex-start")}
`;
const Title = styled.h2`
	color: ${colors.black};
`;

const Subtitle = styled(motion.div)`
	font-size: 15px;
	color: ${colors.black};
`;

const RowIcon = styled.div`
	${row("S", "flex-start", "stretch")}
`;

const Attention = styled(AttentionIcon)`
	width: 27px;
`;

const Label = styled(motion.label)`
	font-size: 12px;
	font-weight: bold;
	color: ${colors.grey};
	text-transform: uppercase;
`;

const Comment = styled(TextArea)`
	width: 100%;
	font-size: 15px;
`;

const Fields = styled.div`
	${stack("S", "flex-start", "flex-start")}
	width: 100%;
`;

const Text = styled(TextInput)`
	width: 100%;
`;

const Buttons = styled.div`
	${row("M", "flex-end", "stretch")}
`;
const Link = styled.a`
	margin-left: 3px;
	color: ${colors.blue2};
	text-decoration: underline;
`;

export const CountDiv = styled.div<{ max: boolean }>`
	position: absolute;
	color: ${colors.grey};
	right: 0;
	bottom: 0;
	z-index: 5;
	padding-bottom: 5px;
	padding-right: 5px;
	${props =>
		props.max &&
		css`
			color: ${colors.red};
		`}
`;

const CommentDiv = styled.div`
	width: 100%;
	position: relative;
`;
const RobotIcon = styled(Robot)`
	width: 42px;
	height: 42px;
	fill: ${colors.black};
`;
