import styled from "@emotion/styled";
import React, { useState } from "react";
import { colors, sizes, stack } from "style";
import { TextInput } from "./text-input";

interface BooleanInputProps {
	list: string[];
	onChange?: (value: string) => void;
	onClick?: (value: string) => void;
	notFoundText?: string;
	textInputPlaceHolder: string;
	startSearch?: number;
}
export const capitalizeFirstLetter = (word: string): string => {
	if (!word) return word;
	return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const DownShift: React.FC<BooleanInputProps> = ({
	list,
	onChange,
	onClick,
	notFoundText,
	textInputPlaceHolder,
	startSearch = 3,
}) => {
	const [show, setShow] = useState(false);
	const [inputValue, setInputValue] = useState("#@");
	return (
		<RowHospitalSelection>
			<Text
				onChange={e => {
					e.target.value.length > 0 ? setShow(true) : setShow(false);
					setInputValue(e.target.value);
					e.target.value.length > 2 ? onChange?.(e.target.value) : null;
				}}
				value={inputValue != "#@" ? capitalizeFirstLetter(inputValue) : ""}
				placeholder={textInputPlaceHolder}
				required={true}
				onClick={()=> setShow(false)}
			/>
			{show && inputValue.length >= startSearch ? (
				list.length > 0 ? (
					list.map((item, index) => {
						return (
							<ContainerHospitalInput
								key={index}
								onClick={() => {
									setInputValue(item);
									setShow(false);
									onClick?.(item);
								}}
							>
								{item}
							</ContainerHospitalInput>
						);
					})
				) : notFoundText && (
					<ContainerHospitalInput>{notFoundText}</ContainerHospitalInput>
				)
			) : (
				show && <ContainerHospitalInput>...</ContainerHospitalInput>
			)}
		</RowHospitalSelection>
	);
};

const ContainerHospitalInput = styled.div`
	position: relative;
	right: 0px;
	min-width: 300px;
	max-width: 800px;
	box-shadow: 0 0px 7px 0 rgba(70, 62, 62, 0.15);
	padding: ${sizes.S};
	border-radius: 3px;
	z-index: 10;
	cursor: pointer;
	:hover {
		color: ${colors.white};
		background-color: ${colors.pink};
	}
`;

const RowHospitalSelection = styled.div`
	${stack(0, "flex-start", "stretch")};
`;

const Text = styled(TextInput)`
	min-width: 300px;
	max-width: 800px;
`;
