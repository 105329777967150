import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Patient } from "domain/patient";
import { Surgery, SurgeryStatus } from "domain/surgery";
import { fullName } from "domain/utils";
import React from "react";
import { colors, desktop, mobile, row, stack } from "style";
import { useI18n } from "utils/i18n";
import { WordingKey } from "utils/i18n-typings";
import { isDateSameDay } from "utils/time";
import { SectionSelectInput, SectionSelectInputType } from "../inputs/section-select-input";
import { SelectInput, SelectInputType } from "../inputs/select-input";

export interface SurgerySelectorWordingKeys {
	historyLabel: WordingKey;
	historyPlaceholder: WordingKey;
	futureLabel: WordingKey;
	futurePlaceholder: WordingKey;
}

interface SurgeryDatesSelectorProps {
	surgery?: Surgery;
	surgeries: Surgery[];
	onSurgeryChange: (surgery: Surgery) => void;
	surgeryId?: string;
	wording: SurgerySelectorWordingKeys;
}

function splitSurgeries(surgeries: Surgery[]) {
	const pastSurgeries = [];
	const futureSurgeries = [];
	const wardSurgeries: Record<string, { patient: Patient; surgeries: Surgery[] }> = {};
	for (const surgery of surgeries) {
		if (surgery.patient) {
			if (wardSurgeries[surgery.patient.id]) {
				wardSurgeries[surgery.patient.id].surgeries.push(surgery);
			} else {
				wardSurgeries[surgery.patient.id] = {
					patient: surgery.patient,
					surgeries: [surgery],
				};
			}
		} else if (isDateSameDay(new Date(surgery.surgeryDate), new Date()) || new Date(surgery.surgeryDate) > new Date()) {
			futureSurgeries.push(surgery);
		} else {
			pastSurgeries.push(surgery);
		}
	}
	return {
		pastSurgeries,
		futureSurgeries,
		wardSurgeries,
	} as const;
}

export const SurgeryDatesSelector: React.FC<SurgeryDatesSelectorProps> = ({
	surgery,
	surgeries,
	surgeryId,
	onSurgeryChange,
	wording,
}) => {
	const { formatDate, format } = useI18n();
	const { pastSurgeries, futureSurgeries, wardSurgeries } = splitSurgeries(surgeries);
	const selectedSurgery = surgeries.find(surgery => surgeryId && surgery.id === surgeryId) ?? null;

	if (surgeries.length <= 0) {
		return null;
	}

	return (
		<DatesWrapper>
			{surgery && surgery.status === SurgeryStatus.Archived ? (
				<ArchivedSurgeryText>
					{format("surgerySelector.archived", {
						date: formatDate(surgery.surgeryDate, { day: "numeric", month: "numeric", year: "numeric" }),
					})}
				</ArchivedSurgeryText>
			) : (pastSurgeries.length > 0 && futureSurgeries.length > 0) || Object.keys(wardSurgeries).length > 0 ? (
				<>
					<SurgeryText>{format(wording.futureLabel)}</SurgeryText>
					<SectionSurgerySelectInput
						value={selectedSurgery}
						innerId="surgery-selectfield"
						options={[
							{ name: format(wording.historyLabel), section: pastSurgeries },
							{ name: format(wording.futurePlaceholder), section: futureSurgeries },
							...Object.keys(wardSurgeries).map(wardId => ({
								name: fullName(wardSurgeries[wardId].patient),
								section: wardSurgeries[wardId].surgeries,
							})),
						]}
						disabledOptions={surgeries.filter(s => s.status === SurgeryStatus.Archived)}
						itemRenderer={surgery =>
							formatDate(surgery.surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })
						}
						onChange={onSurgeryChange}
					/>
				</>
			) : pastSurgeries.length > 0 && futureSurgeries.length === 0 ? (
				<>
					<SurgeryText>{format(wording.historyLabel)}</SurgeryText>
					<LargerSelectInput
						placeholder={format(wording.historyPlaceholder)}
						value={selectedSurgery}
						innerId="surgery-selectfield"
						options={surgeries}
						disabledOptions={surgeries.filter(s => s.status === SurgeryStatus.Archived)}
						itemRenderer={surgery =>
							formatDate(surgery.surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })
						}
						onChange={onSurgeryChange}
					/>
				</>
			) : futureSurgeries.length === 1 ? (
				<>
					<SurgeryText>{format(wording.futureLabel)}</SurgeryText>
					<DateText>
						{formatDate(surgeries[0].surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })}
					</DateText>
				</>
			) : (
				<>
					<SurgeryText>{format(wording.futureLabel)}</SurgeryText>
					<SurgerySelectInput
						value={selectedSurgery}
						innerId="surgery-selectfield"
						options={surgeries}
						disabledOptions={surgeries.filter(s => s.status === SurgeryStatus.Archived)}
						itemRenderer={surgery =>
							formatDate(surgery.surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })
						}
						onChange={onSurgeryChange}
					/>
				</>
			)}
		</DatesWrapper>
	);
};

const DatesWrapper = styled.div`
	@media ${desktop} {
		${stack(0)}
	}

	@media ${mobile} {
		${row(3, "flex-start", "center")}
	}
`;

const ArchivedSurgeryText = styled.div`
	max-width: 150px;
	color: ${colors.grey};
	font-size: 13px;
`;

const SurgeryText = styled.span`
	color: ${colors.black};
	font-weight: bold;
	font-size: 13px;
`;

const DateText = styled(SurgeryText)`
	color: ${colors.green};
`;

const selectFieldStyle = css`
	margin-top: 5px;
	select {
		width: 120px;
		font-size: 13px;
		padding-left: 8px;
		padding: 4px 25px 5px 8px;
		color: ${colors.green};
	}

	img {
		width: 12px;
		height: 12px;
	}
`;

const SurgerySelectInput = styled<SelectInputType<Surgery>>(SelectInput)`
	${selectFieldStyle}
`;

const LargerSelectInput = styled(SurgerySelectInput)`
	select {
		width: 150px;
	}
`;

const SectionSurgerySelectInput = styled<SectionSelectInputType<Surgery>>(SectionSelectInput)`
	${selectFieldStyle}
`;
