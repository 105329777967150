import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { colors, mobile, row, sizes } from "style";

export const Tooltip: React.FC<{ show?: boolean; className?: string }> = ({ children, show, className }) => {
	return (
		<AnimatePresence>
			{show && (
				<TooltipAnchor
					initial={{ x: -30, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					exit={{ x: -30, opacity: 0 }}
					transition={{ type: "spring", stiffness: 100, damping: 20 }}
					className={className}
				>
					<TooltipContainer className="tooltip-container">
						<TooltipTipContainer>
							<svg width={10} height={20} viewBox="0 0 15 30">
								<path fill={colors.white} d="M 0 0 L 0 30 L 15 15 Z" />
							</svg>
						</TooltipTipContainer>
						<div>{children}</div>
					</TooltipContainer>
				</TooltipAnchor>
			)}
		</AnimatePresence>
	);
};
const TooltipAnchor = styled(motion.div)`
	position: absolute;
	left: -15px;
	width: 10px;
	height: 10px;
	background: blue;
	${row(0, "center", "center")};

	@media ${mobile} {
		display: none;
	}
`;

export const TooltipContainer = styled.div`
	position: absolute;
	right: 0px;
	width: 350px;
	box-shadow: 0 52px 101px 0 rgba(70, 62, 62, 0.15);
	background-color: ${colors.white};
	padding: ${sizes.S};
	border-radius: 3px;
`;

const TooltipTipContainer = styled.div`
	position: absolute;
	${row(0, "center", "center")};
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	svg {
		position: absolute;
		right: -9px;
	}
`;
