import {HTMLMotionProps, motion} from "framer-motion";
import {useI18n} from "../../utils/i18n";
import React, {useCallback, useState, useEffect} from "react";
import {Patient, patientHasFilledTheirInfo} from "../../domain/patient";
import {SurgeryStep} from "../../domain/surgery-step";
import {Button} from "../button";
import {PasswordInput} from "../inputs/text-input";
import styled from "@emotion/styled";
import {colors, desktop, mobile, row, stack} from "../../style";
import {
  Practitioner,
  practitionerHasFilledTheirInfo,
  Secretary,
  secretaryHasFilledTheirInfo,
  hospitalHasFilledTheirInfo,
  hpuHasFilledTheirInfo, supportTechnicalHasFilledTheirInfo,
} from "../../domain/surgery";
import { CredentialsUpdate} from "../../domain/utils";
import { PatternPasswordInputLonger} from "../inputs/text-input";
import CheckBadge from "@assets/icons/badge-check.svg"
import BadgeError from "@assets/icons/badge-error.svg"
import {Hpu} from "../../domain/hpu";
import { SupportTechnical } from "../../domain/support-technical";

interface PasswordUpdate extends HTMLMotionProps<"div"> {
    patient: Patient | null;
    secretary: Secretary | null;
    practitioner: Practitioner | null;
    hpu: Hpu | null;
    hospitalId: string | null;
    supportTechnical: SupportTechnical | null;
    onEdit?: (data: Partial<Pick<CredentialsUpdate, "newPassword" | "newPasswordConfirmation" | "currentPassword">>) => Promise<void>;
    step?: SurgeryStep;
}

export const PatientPasswordInfoSectionUpdate: React.FC<PasswordUpdate> = ({patient,onEdit, ...props}) => {
    const { format } = useI18n()
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<{code: number } | null>(null);
    const [success, setSuccess] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const requiredLength = 8;
    const [currentColorLength, setCurrentColorLength] = useState<boolean>(false)
    const [currentColorUppercase, setCurrentColorUppercase] = useState<boolean>(false)
    const [currentColorLowercase, setCurrentColorLowercase] = useState<boolean>(false)
    const [currentColorNumber, setCurrentColorNumber] = useState<boolean>(false)

    useEffect(() => {
        setValidLength(newPassword.length >= requiredLength);
        setUppercase(newPassword.toLowerCase() !== newPassword);
        setLowercase(newPassword.toUpperCase() !== newPassword);
        setHasNumber(/\d/.test(newPassword));
        (newPassword.length >= requiredLength) ? setCurrentColorLength(true) : setCurrentColorLength(false);
        (newPassword.toLowerCase() === newPassword) ? setCurrentColorUppercase(false) : setCurrentColorUppercase(true);
        (newPassword.toUpperCase() === newPassword) ? setCurrentColorLowercase(false) : setCurrentColorLowercase(true);
        (/\d/.test(newPassword)) ? setCurrentColorNumber(true) : setCurrentColorNumber(false);
    }, [newPassword, requiredLength]);

    const setEditingAndChangeFields = useCallback(
        (editing: boolean) => {
            setEditing(editing);
            setNewPassword("");
            setNewPasswordConfirmation("");
            setCurrentPassword("");
            setSuccess(false);
            setError(null);
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    const saveUpdate = useCallback(async() => {
            try {
                await onEdit?.({
                    newPassword,
                    newPasswordConfirmation,
                    currentPassword,
                });
                setEditingAndChangeFields(false);
                setSuccess(true);
            } catch (e: any){
                e?.response?.status && setError({ code: e?.response?.status});
            }
        },
        [newPassword,newPasswordConfirmation,currentPassword]
    );

    return (
        <Encart>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    saveUpdate();
                }}
            >
                <SectionInformation {...props}>
                    {!patient ? (
                        <Info>
                            <SkeletonLine />
                            <SkeletonLine />
                            <SkeletonLine />
                            <SkeletonLine />
                        </Info>
                    ) : !editing ? (
                        <>
                            {onEdit && !patientHasFilledTheirInfo(patient, null)}
                            <Info>
                                {success &&(
                                    <SuccessMessage>
                                        {format("patient.change.success.new.passwordPlaceholder")}
                                    </SuccessMessage>
                                )}
                                <Title>{format("patient.dashboard.password.title")}</Title>
                                <SubtitleImportant>{format("patient.dashboard.password.information")}</SubtitleImportant>
                                <Subtitle>{format("patient.dashboard.password.secondary")}</Subtitle>
                                <Buttons>
                                    {onEdit && patient && !editing ? (
                                        <Button
                                            onClick={() => setEditingAndChangeFields(true)}
                                            type="button"
                                        >
                                            {patientHasFilledTheirInfo(patient, null)
                                                ? format("patient.dashboard.password.update")
                                                : format("patient.dashboard.password.update")}
                                        </Button>
                                    ) : null}
                                </Buttons>
                            </Info>
                        </>
                    ) : (
                        <Info>
                            <ErrorWrapper>
                                <Sections>
                                    <Section>
                                    <PasswordInput
                                        placeholder={format("patient.actual.passwordPlaceholder")}
                                        value={currentPassword}
                                        onChange={e => setCurrentPassword(e.target.value)}
                                        autoComplete="current-password"
                                        required
                                    />
                                    <PatternPasswordInputLonger
                                        placeholder={format("newPassword.passwordPlaceholder")}
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.target.value)}
                                        autoComplete="new-password"
                                        required
                                    />
                                    <PatternPasswordInputLonger
                                        placeholder={format("newPassword.passwordConfirmationPlaceholder")}
                                        value={newPasswordConfirmation}
                                        onChange={e => setNewPasswordConfirmation(e.target.value)}
                                        autoComplete="new-password"
                                        required
                                    />
                                    </Section>
                                    <SectionChecking>
                                        <PasswordCheckingInfo>
                                            <RowPasswordCheckingLength currentColor={currentColorLength}>  Comporte 8 caractères  {validLength ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLength>
                                            <RowPasswordCheckingNumber number={currentColorNumber}>  Comporte un chiffre  {hasNumber ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingNumber>
                                            <RowPasswordCheckingUppercase upperCase={currentColorUppercase}>  Comporte une majuscule  {uppercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingUppercase>
                                            <RowPasswordCheckingLowercase lowerCase={currentColorLowercase}>  Comporte une minuscule  {lowercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLowercase>
                                        </PasswordCheckingInfo>
                                    </SectionChecking>
                                </Sections>
                                {error && (
                                    <ErrorMessage>
                                        {error.code === 400
                                            ? format("patient.change.error.current.passwordPlaceholder")
                                            : error.code === 401
                                                ? format("patient.change.error.new.passwordPlaceholder")
                                                : format("login.error.unknownError")}
                                    </ErrorMessage>
                                )}
                            </ErrorWrapper>
                            <Buttons>
                                <Button
                                    key="submit"
                                    type="submit"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: 0.3 } }}
                                >
                                    {format("patient.dashboard.info.submit")}
                                </Button>
                                <Button
                                    secondary
                                    onClick={() => setEditingAndChangeFields(false)}
                                    type="button"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: 0.3 } }}
                                >
                                    {format("patient.dashboard.info.cancel")}
                                </Button>
                            </Buttons>
                        </Info>
                    )}
                </SectionInformation>
            </form>
        </Encart>
    );
}

export const PractitionerPasswordInfoSectionUpdate: React.FC<PasswordUpdate> = ({practitioner, onEdit, ...props}) => {
    const {format} = useI18n()
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<{ code: number } | null>(null);
    const [success, setSuccess] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const requiredLength = 8;
    const [currentColorLength, setCurrentColorLength] = useState<boolean>(false)
    const [currentColorUppercase, setCurrentColorUppercase] = useState<boolean>(false)
    const [currentColorLowercase, setCurrentColorLowercase] = useState<boolean>(false)
    const [currentColorNumber, setCurrentColorNumber] = useState<boolean>(false)

    useEffect(() => {
        setValidLength(newPassword.length >= requiredLength);
        setUppercase(newPassword.toLowerCase() !== newPassword);
        setLowercase(newPassword.toUpperCase() !== newPassword);
        setHasNumber(/\d/.test(newPassword));
        (newPassword.length >= requiredLength) ? setCurrentColorLength(true) : setCurrentColorLength(false);
        (newPassword.toLowerCase() === newPassword) ? setCurrentColorUppercase(false) : setCurrentColorUppercase(true);
        (newPassword.toUpperCase() === newPassword) ? setCurrentColorLowercase(false) : setCurrentColorLowercase(true);
        (/\d/.test(newPassword)) ? setCurrentColorNumber(true) : setCurrentColorNumber(false);
    }, [newPassword, requiredLength]);

    const setEditingAndChangeFields = useCallback(
        (editing: boolean) => {
            setEditing(editing);
            setNewPassword("");
            setNewPasswordConfirmation("");
            setCurrentPassword("");
            setSuccess(false);
            setError(null);
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    const saveUpdate = useCallback(async () => {
            try {
                await onEdit?.({
                    newPassword,
                    newPasswordConfirmation,
                    currentPassword,
                });
                setEditingAndChangeFields(false);
                setSuccess(true);
            } catch (e: any) {
                e?.response?.status && setError({code: e?.response?.status});
            }
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    return (
        <Encart>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    saveUpdate();
                }}
            >
                <SectionInformation {...props}>
                    {!practitioner ? (
                        <Info>
                            <SkeletonLine/>
                            <SkeletonLine/>
                            <SkeletonLine/>
                            <SkeletonLine/>
                        </Info>
                    ) : !editing ? (
                        <>
                            {onEdit && !practitionerHasFilledTheirInfo(practitioner, null)}
                            <Info>
                                {success && (
                                    <SuccessMessage>
                                        {format("patient.change.success.new.passwordPlaceholder")}
                                    </SuccessMessage>
                                )}
                                    <Title>{format("patient.dashboard.password.title")}</Title>
                                    <SubtitleImportant>{format("patient.dashboard.password.information")}</SubtitleImportant>
                                    <Subtitle>{format("patient.dashboard.password.secondary")}</Subtitle>
                                <Buttons>
                                    {onEdit && practitioner && !editing ? (
                                        <Button
                                            onClick={() => setEditingAndChangeFields(true)}
                                            type="button"
                                        >
                                            {practitionerHasFilledTheirInfo(practitioner, null)
                                                ? format("patient.dashboard.password.update")
                                                : format("patient.dashboard.password.update")}
                                        </Button>
                                    ) : null}
                                </Buttons>
                            </Info>
                        </>
                    ) : (
                        <Info>
                            <ErrorWrapper>
                                <Sections>
                                    <Section>
                                    <PasswordInput
                                        placeholder={format("patient.actual.passwordPlaceholder")}
                                        value={currentPassword}
                                        onChange={e => setCurrentPassword(e.target.value)}
                                        autoComplete="current-password"
                                        required
                                    />
                                    <PatternPasswordInputLonger
                                        placeholder={format("newPassword.passwordPlaceholder")}
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.target.value)}
                                        autoComplete="new-password"
                                        required
                                    />
                                    <PatternPasswordInputLonger
                                        placeholder={format("newPassword.passwordConfirmationPlaceholder")}
                                        value={newPasswordConfirmation}
                                        onChange={e => setNewPasswordConfirmation(e.target.value)}
                                        autoComplete="new-password"
                                        required
                                    />
                                </Section>
                                    <SectionChecking>
                                        <PasswordCheckingInfo>
                                            <RowPasswordCheckingLength currentColor={currentColorLength}>  Comporte 8 caractères  {validLength ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLength>
                                            <RowPasswordCheckingNumber number={currentColorNumber}>  Comporte un chiffre  {hasNumber ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingNumber>
                                            <RowPasswordCheckingUppercase upperCase={currentColorUppercase}>  Comporte une majuscule  {uppercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingUppercase>
                                            <RowPasswordCheckingLowercase lowerCase={currentColorLowercase}>  Comporte une minuscule  {lowercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLowercase>
                                        </PasswordCheckingInfo>
                                    </SectionChecking>
                                </Sections>
                                {error && (
                                    <ErrorMessage>
                                        {error.code === 400
                                            ? format("patient.change.error.current.passwordPlaceholder")
                                            : error.code === 401
                                                ? format("patient.change.error.new.passwordPlaceholder")
                                                : format("login.error.unknownError")}
                                    </ErrorMessage>
                                )}
                            </ErrorWrapper>
                            <Buttons>
                                <Button
                                    key="submit"
                                    type="submit"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1, transition: {delay: 0.3}}}
                                >
                                    {format("patient.dashboard.info.submit")}
                                </Button>
                                <Button
                                    secondary
                                    onClick={() => setEditingAndChangeFields(false)}
                                    type="button"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1, transition: {delay: 0.3}}}
                                >
                                    {format("patient.dashboard.info.cancel")}
                                </Button>
                            </Buttons>
                        </Info>
                    )}
                </SectionInformation>
            </form>
        </Encart>
    );
}

export const SecretaryPasswordInfoSectionUpdate: React.FC<PasswordUpdate> = ({secretary, onEdit, ...props}) => {
    const { format } = useI18n()
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<{code: number } | null>(null);
    const [success, setSuccess] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const requiredLength = 8;
    const [currentColorLength, setCurrentColorLength] = useState<boolean>(false)
    const [currentColorUppercase, setCurrentColorUppercase] = useState<boolean>(false)
    const [currentColorLowercase, setCurrentColorLowercase] = useState<boolean>(false)
    const [currentColorNumber, setCurrentColorNumber] = useState<boolean>(false)

    useEffect(() => {
        setValidLength(newPassword.length >= requiredLength);
        setUppercase(newPassword.toLowerCase() !== newPassword);
        setLowercase(newPassword.toUpperCase() !== newPassword);
        setHasNumber(/\d/.test(newPassword));
        (newPassword.length >= requiredLength) ? setCurrentColorLength(true) : setCurrentColorLength(false);
        (newPassword.toLowerCase() === newPassword) ? setCurrentColorUppercase(false) : setCurrentColorUppercase(true);
        (newPassword.toUpperCase() === newPassword) ? setCurrentColorLowercase(false) : setCurrentColorLowercase(true);
        (/\d/.test(newPassword)) ? setCurrentColorNumber(true) : setCurrentColorNumber(false);
        }, [newPassword, requiredLength]);

        const setEditingAndChangeFields = useCallback(
            (editing: boolean) => {
                setEditing(editing);
                setNewPassword("");
                setNewPasswordConfirmation("");
                setCurrentPassword("");
                setSuccess(false);
                setError(null);
            },
            [newPassword, newPasswordConfirmation, currentPassword]
        );

        const saveUpdate = useCallback(async() => {
                try {
                    await onEdit?.({
                        newPassword,
                        newPasswordConfirmation,
                        currentPassword,
                    });
                    setEditingAndChangeFields(false);
                    setSuccess(true);
                } catch (e: any){
                    e?.response?.status && setError({ code: e?.response?.status});
                }
            },
            [newPassword,newPasswordConfirmation,currentPassword]
        );

        return (
            <Encart>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        saveUpdate();
                    }}
                >
                    <SectionInformation {...props}>
                        {!secretary ? (
                            <Info>
                                <SkeletonLine />
                                <SkeletonLine />
                                <SkeletonLine />
                                <SkeletonLine />
                            </Info>
                        ) : !editing ? (
                            <>
                                {onEdit && !secretaryHasFilledTheirInfo(secretary, null)}
                                <Info>
                                    {success &&(
                                        <SuccessMessage>
                                            {format("patient.change.success.new.passwordPlaceholder")}
                                        </SuccessMessage>
                                    )}
                                    <Title>{format("patient.dashboard.password.title")}</Title>
                                    <SubtitleImportant>{format("patient.dashboard.password.information")}</SubtitleImportant>
                                    <Subtitle>{format("patient.dashboard.password.secondary")}</Subtitle>
                                    <Buttons>
                                        {onEdit && secretary && !editing ? (
                                            <Button
                                                onClick={() => setEditingAndChangeFields(true)}
                                                type="button"
                                            >
                                                {secretaryHasFilledTheirInfo(secretary, null)
                                                    ? format("patient.dashboard.password.update")
                                                    : format("patient.dashboard.password.update")}
                                            </Button>
                                        ) : null}
                                    </Buttons>
                                </Info>
                            </>
                        ) : (
                            <Info>
                                <ErrorWrapper>
                                    <Sections>
                                        <Section>
                                            <PasswordInput
                                                placeholder={format("patient.actual.passwordPlaceholder")}
                                                value={currentPassword}
                                                onChange={e => setCurrentPassword(e.target.value)}
                                                autoComplete="current-password"
                                                required
                                            />
                                            <PatternPasswordInputLonger
                                                placeholder={format("newPassword.passwordPlaceholder")}
                                                value={newPassword}
                                                onChange={e => setNewPassword(e.target.value)}
                                                autoComplete="new-password"
                                                required
                                            />
                                            <PatternPasswordInputLonger
                                                placeholder={format("newPassword.passwordConfirmationPlaceholder")}
                                                value={newPasswordConfirmation}
                                                onChange={e => setNewPasswordConfirmation(e.target.value)}
                                                autoComplete="new-password"
                                                required
                                            />
                                        </Section>
                                        <SectionChecking>
                                            <PasswordCheckingInfo>
                                                <RowPasswordCheckingLength currentColor={currentColorLength}>  Comporte 8 caractères  {validLength ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLength>
                                                <RowPasswordCheckingNumber number={currentColorNumber}>  Comporte un chiffre  {hasNumber ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingNumber>
                                                <RowPasswordCheckingUppercase upperCase={currentColorUppercase}>  Comporte une majuscule  {uppercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingUppercase>
                                                <RowPasswordCheckingLowercase lowerCase={currentColorLowercase}>  Comporte une minuscule  {lowercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLowercase>
                                            </PasswordCheckingInfo>
                                        </SectionChecking>
                                    </Sections>
                                    {error && (
                                        <ErrorMessage>
                                            {error.code === 400
                                                ? format("patient.change.error.current.passwordPlaceholder")
                                                : error.code === 401
                                                    ? format("patient.change.error.new.passwordPlaceholder")
                                                    : format("login.error.unknownError")}
                                        </ErrorMessage>
                                    )}
                                </ErrorWrapper>
                                <Buttons>
                                    <Button
                                        key="submit"
                                        type="submit"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1, transition: { delay: 0.3 } }}
                                    >
                                        {format("patient.dashboard.info.submit")}
                                    </Button>
                                    <Button
                                        secondary
                                        onClick={() => setEditingAndChangeFields(false)}
                                        type="button"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1, transition: { delay: 0.3 } }}
                                    >
                                        {format("patient.dashboard.info.cancel")}
                                    </Button>
                                </Buttons>
                            </Info>
                        )}
                    </SectionInformation>
                </form>
            </Encart>
        );
}

export const HospitalPasswordInfoSectionUpdate: React.FC<PasswordUpdate> = ({ hospitalId,onEdit, ...props}) => {
    const {format} = useI18n()
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<{ code: number } | null>(null);
    const [success, setSuccess] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const requiredLength = 8;
    const [currentColorLength, setCurrentColorLength] = useState<boolean>(false)
    const [currentColorUppercase, setCurrentColorUppercase] = useState<boolean>(false)
    const [currentColorLowercase, setCurrentColorLowercase] = useState<boolean>(false)
    const [currentColorNumber, setCurrentColorNumber] = useState<boolean>(false)

    useEffect(() => {
        setValidLength(newPassword.length >= requiredLength);
        setUppercase(newPassword.toLowerCase() !== newPassword);
        setLowercase(newPassword.toUpperCase() !== newPassword);
        setHasNumber(/\d/.test(newPassword));
        (newPassword.length >= requiredLength) ? setCurrentColorLength(true) : setCurrentColorLength(false);
        (newPassword.toLowerCase() === newPassword) ? setCurrentColorUppercase(false) : setCurrentColorUppercase(true);
        (newPassword.toUpperCase() === newPassword) ? setCurrentColorLowercase(false) : setCurrentColorLowercase(true);
        (/\d/.test(newPassword)) ? setCurrentColorNumber(true) : setCurrentColorNumber(false);
    }, [newPassword, requiredLength]);

    const setEditingAndChangeFields = useCallback(
        (editing: boolean) => {
            setEditing(editing);
            setNewPassword("");
            setNewPasswordConfirmation("");
            setCurrentPassword("");
            setSuccess(false);
            setError(null);
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    const saveUpdate = useCallback(async () => {
            try {
                await onEdit?.({
                    newPassword,
                    newPasswordConfirmation,
                    currentPassword,
                });
                setEditingAndChangeFields(false);
                setSuccess(true);
            } catch (e: any) {
                e?.response?.status && setError({code: e?.response?.status});
            }
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    return (
        <Encart>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    saveUpdate();
                }}
            >
                <SectionInformation {...props}>
                    {!hospitalId ? (
                        <Info>
                            <SkeletonLine/>
                            <SkeletonLine/>
                            <SkeletonLine/>
                            <SkeletonLine/>
                        </Info>
                    ) : !editing ? (
                        <>
                            {onEdit && !hospitalHasFilledTheirInfo(null, null)}
                            <Info>
                                {success && (
                                    <SuccessMessage>
                                        {format("patient.change.success.new.passwordPlaceholder")}
                                    </SuccessMessage>
                                )}
                                <Title>{format("patient.dashboard.password.title")}</Title>
                                <SubtitleImportant>{format("patient.dashboard.password.information")}</SubtitleImportant>
                                <Subtitle>{format("patient.dashboard.password.secondary")}</Subtitle>
                                <Buttons>
                                    {onEdit && hospitalId && !editing ? (
                                        <Button
                                            onClick={() => setEditingAndChangeFields(true)}
                                            type="button"
                                        >
                                            {hospitalHasFilledTheirInfo(null, null)
                                                ? format("patient.dashboard.password.update")
                                                : format("patient.dashboard.password.update")}
                                        </Button>
                                    ) : null}
                                </Buttons>
                            </Info>
                        </>
                    ) : (
                        <Info>
                            <ErrorWrapper>
                                <Sections>
                                    <Section>
                                        <PasswordInput
                                            placeholder={format("patient.actual.passwordPlaceholder")}
                                            value={currentPassword}
                                            onChange={e => setCurrentPassword(e.target.value)}
                                            autoComplete="current-password"
                                            required
                                        />
                                        <PatternPasswordInputLonger
                                            placeholder={format("newPassword.passwordPlaceholder")}
                                            value={newPassword}
                                            onChange={e => setNewPassword(e.target.value)}
                                            autoComplete="new-password"
                                            required
                                        />
                                        <PatternPasswordInputLonger
                                            placeholder={format("newPassword.passwordConfirmationPlaceholder")}
                                            value={newPasswordConfirmation}
                                            onChange={e => setNewPasswordConfirmation(e.target.value)}
                                            autoComplete="new-password"
                                            required
                                        />
                                    </Section>
                                    <SectionChecking>
                                        <PasswordCheckingInfo>
                                            <RowPasswordCheckingLength currentColor={currentColorLength}>  Comporte 8 caractères  {validLength ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLength>
                                            <RowPasswordCheckingNumber number={currentColorNumber}>  Comporte un chiffre  {hasNumber ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingNumber>
                                            <RowPasswordCheckingUppercase upperCase={currentColorUppercase}>  Comporte une majuscule  {uppercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingUppercase>
                                            <RowPasswordCheckingLowercase lowerCase={currentColorLowercase}>  Comporte une minuscule  {lowercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLowercase>
                                        </PasswordCheckingInfo>
                                    </SectionChecking>
                                </Sections>
                                {error && (
                                    <ErrorMessage>
                                        {error.code === 400
                                            ? format("patient.change.error.current.passwordPlaceholder")
                                            : error.code === 401
                                                ? format("patient.change.error.new.passwordPlaceholder")
                                                : format("login.error.unknownError")}
                                    </ErrorMessage>
                                )}
                            </ErrorWrapper>
                            <Buttons>
                                <Button
                                    key="submit"
                                    type="submit"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1, transition: {delay: 0.3}}}
                                >
                                    {format("patient.dashboard.info.submit")}
                                </Button>
                                <Button
                                    secondary
                                    onClick={() => setEditingAndChangeFields(false)}
                                    type="button"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1, transition: {delay: 0.3}}}
                                >
                                    {format("patient.dashboard.info.cancel")}
                                </Button>
                            </Buttons>
                        </Info>
                    )}
                </SectionInformation>
            </form>
        </Encart>
    );
}

export const HpuPasswordInfoSectionUpdate: React.FC<PasswordUpdate> = ({hpu, onEdit, ...props}) => {
    const {format} = useI18n()
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<{ code: number } | null>(null);
    const [success, setSuccess] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const requiredLength = 8;
    const [currentColorLength, setCurrentColorLength] = useState<boolean>(false)
    const [currentColorUppercase, setCurrentColorUppercase] = useState<boolean>(false)
    const [currentColorLowercase, setCurrentColorLowercase] = useState<boolean>(false)
    const [currentColorNumber, setCurrentColorNumber] = useState<boolean>(false)

    useEffect(() => {
        setValidLength(newPassword.length >= requiredLength);
        setUppercase(newPassword.toLowerCase() !== newPassword);
        setLowercase(newPassword.toUpperCase() !== newPassword);
        setHasNumber(/\d/.test(newPassword));
        (newPassword.length >= requiredLength) ? setCurrentColorLength(true) : setCurrentColorLength(false);
        (newPassword.toLowerCase() === newPassword) ? setCurrentColorUppercase(false) : setCurrentColorUppercase(true);
        (newPassword.toUpperCase() === newPassword) ? setCurrentColorLowercase(false) : setCurrentColorLowercase(true);
        (/\d/.test(newPassword)) ? setCurrentColorNumber(true) : setCurrentColorNumber(false);
    }, [newPassword, requiredLength]);

    const setEditingAndChangeFields = useCallback(
        (editing: boolean) => {
            setEditing(editing);
            setNewPassword("");
            setNewPasswordConfirmation("");
            setCurrentPassword("");
            setSuccess(false);
            setError(null);
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    const saveUpdate = useCallback(async () => {
            try {
                await onEdit?.({
                    newPassword,
                    newPasswordConfirmation,
                    currentPassword,
                });
                setEditingAndChangeFields(false);
                setSuccess(true);
            } catch (e: any) {
                e?.response?.status && setError({code: e?.response?.status});
            }
        },
        [newPassword, newPasswordConfirmation, currentPassword]
    );

    return (
        <Encart>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    saveUpdate();
                }}
            >
                <SectionInformation {...props}>
                    {!hpu ? (
                        <Info>
                            <SkeletonLine/>
                            <SkeletonLine/>
                            <SkeletonLine/>
                            <SkeletonLine/>
                        </Info>
                    ) : !editing ? (
                        <>
                            {onEdit && !hpuHasFilledTheirInfo(hpu, null)}
                            <Info>
                                {success && (
                                    <SuccessMessage>
                                        {format("patient.change.success.new.passwordPlaceholder")}
                                    </SuccessMessage>
                                )}
                                <Title>{format("patient.dashboard.password.title")}</Title>
                                <SubtitleImportant>{format("patient.dashboard.password.information")}</SubtitleImportant>
                                <Subtitle>{format("patient.dashboard.password.secondary")}</Subtitle>
                                <Buttons>
                                    {onEdit && hpu && !editing ? (
                                        <Button
                                            onClick={() => setEditingAndChangeFields(true)}
                                            type="button"
                                        >
                                            {hpuHasFilledTheirInfo(hpu, null)
                                                ? format("patient.dashboard.password.update")
                                                : format("patient.dashboard.password.update")}
                                        </Button>
                                    ) : null}
                                </Buttons>
                            </Info>
                        </>
                    ) : (
                        <Info>
                            <ErrorWrapper>
                                <Sections>
                                    <Section>
                                        <PasswordInput
                                            placeholder={format("patient.actual.passwordPlaceholder")}
                                            value={currentPassword}
                                            onChange={e => setCurrentPassword(e.target.value)}
                                            autoComplete="current-password"
                                            required
                                        />
                                        <PatternPasswordInputLonger
                                            placeholder={format("newPassword.passwordPlaceholder")}
                                            value={newPassword}
                                            onChange={e => setNewPassword(e.target.value)}
                                            autoComplete="new-password"
                                            required
                                        />
                                        <PatternPasswordInputLonger
                                            placeholder={format("newPassword.passwordConfirmationPlaceholder")}
                                            value={newPasswordConfirmation}
                                            onChange={e => setNewPasswordConfirmation(e.target.value)}
                                            autoComplete="new-password"
                                            required
                                        />
                                    </Section>
                                    <SectionChecking>
                                        <PasswordCheckingInfo>
                                            <RowPasswordCheckingLength currentColor={currentColorLength}>  Comporte 8 caractères  {validLength ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLength>
                                            <RowPasswordCheckingNumber number={currentColorNumber}>  Comporte un chiffre  {hasNumber ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingNumber>
                                            <RowPasswordCheckingUppercase upperCase={currentColorUppercase}>  Comporte une majuscule  {uppercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingUppercase>
                                            <RowPasswordCheckingLowercase lowerCase={currentColorLowercase}>  Comporte une minuscule  {lowercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLowercase>
                                        </PasswordCheckingInfo>
                                    </SectionChecking>
                                </Sections>
                                {error && (
                                    <ErrorMessage>
                                        {error.code === 400
                                            ? format("patient.change.error.current.passwordPlaceholder")
                                            : error.code === 401
                                                ? format("patient.change.error.new.passwordPlaceholder")
                                                : format("login.error.unknownError")}
                                    </ErrorMessage>
                                )}
                            </ErrorWrapper>
                            <Buttons>
                                <Button
                                    key="submit"
                                    type="submit"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1, transition: {delay: 0.3}}}
                                >
                                    {format("patient.dashboard.info.submit")}
                                </Button>
                                <Button
                                    secondary
                                    onClick={() => setEditingAndChangeFields(false)}
                                    type="button"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1, transition: {delay: 0.3}}}
                                >
                                    {format("patient.dashboard.info.cancel")}
                                </Button>
                            </Buttons>
                        </Info>
                    )}
                </SectionInformation>
            </form>
        </Encart>
    );
}

export const SupportTechnicalPasswordInfoSectionUpdate: React.FC<PasswordUpdate> = ({supportTechnical, onEdit, ...props}) => {
  const {format} = useI18n()
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<{ code: number } | null>(null);
  const [success, setSuccess] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [lowercase, setLowercase] = useState(false);
  const requiredLength = 8;
  const [currentColorLength, setCurrentColorLength] = useState<boolean>(false)
  const [currentColorUppercase, setCurrentColorUppercase] = useState<boolean>(false)
  const [currentColorLowercase, setCurrentColorLowercase] = useState<boolean>(false)
  const [currentColorNumber, setCurrentColorNumber] = useState<boolean>(false)

  useEffect(() => {
    setValidLength(newPassword.length >= requiredLength);
    setUppercase(newPassword.toLowerCase() !== newPassword);
    setLowercase(newPassword.toUpperCase() !== newPassword);
    setHasNumber(/\d/.test(newPassword));
    (newPassword.length >= requiredLength) ? setCurrentColorLength(true) : setCurrentColorLength(false);
    (newPassword.toLowerCase() === newPassword) ? setCurrentColorUppercase(false) : setCurrentColorUppercase(true);
    (newPassword.toUpperCase() === newPassword) ? setCurrentColorLowercase(false) : setCurrentColorLowercase(true);
    (/\d/.test(newPassword)) ? setCurrentColorNumber(true) : setCurrentColorNumber(false);
  }, [newPassword, requiredLength]);

  const setEditingAndChangeFields = useCallback(
    (editing: boolean) => {
      setEditing(editing);
      setNewPassword("");
      setNewPasswordConfirmation("");
      setCurrentPassword("");
      setSuccess(false);
      setError(null);
    },
    [newPassword, newPasswordConfirmation, currentPassword]
  );

  const saveUpdate = useCallback(async () => {
      try {
        await onEdit?.({
          newPassword,
          newPasswordConfirmation,
          currentPassword,
        });
        setEditingAndChangeFields(false);
        setSuccess(true);
      } catch (e: any) {
        e?.response?.status && setError({code: e?.response?.status});
      }
    },
    [newPassword, newPasswordConfirmation, currentPassword]
  );

  return (
    <Encart>
      <form
        onSubmit={e => {
          e.preventDefault();
          saveUpdate();
        }}
      >
        <SectionInformation {...props}>
          {!supportTechnical ? (
            <Info>
              <SkeletonLine/>
              <SkeletonLine/>
              <SkeletonLine/>
              <SkeletonLine/>
            </Info>
          ) : !editing ? (
            <>
              {onEdit && !supportTechnicalHasFilledTheirInfo(supportTechnical, null)}
              <Info>
                {success && (
                  <SuccessMessage>
                    {format("patient.change.success.new.passwordPlaceholder")}
                  </SuccessMessage>
                )}
                <Title>{format("patient.dashboard.password.title")}</Title>
                <SubtitleImportant>{format("patient.dashboard.password.information")}</SubtitleImportant>
                <Subtitle>{format("patient.dashboard.password.secondary")}</Subtitle>
                <Buttons>
                  {onEdit && supportTechnical && !editing ? (
                    <Button
                      onClick={() => setEditingAndChangeFields(true)}
                      type="button"
                    >
                      {supportTechnicalHasFilledTheirInfo(supportTechnical, null)
                        ? format("patient.dashboard.password.update")
                        : format("patient.dashboard.password.update")}
                    </Button>
                  ) : null}
                </Buttons>
              </Info>
            </>
          ) : (
            <Info>
              <ErrorWrapper>
                <Sections>
                  <Section>
                    <PasswordInput
                      placeholder={format("patient.actual.passwordPlaceholder")}
                      value={currentPassword}
                      onChange={e => setCurrentPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                    />
                    <PatternPasswordInputLonger
                      placeholder={format("newPassword.passwordPlaceholder")}
                      value={newPassword}
                      onChange={e => setNewPassword(e.target.value)}
                      autoComplete="new-password"
                      required
                    />
                    <PatternPasswordInputLonger
                      placeholder={format("newPassword.passwordConfirmationPlaceholder")}
                      value={newPasswordConfirmation}
                      onChange={e => setNewPasswordConfirmation(e.target.value)}
                      autoComplete="new-password"
                      required
                    />
                  </Section>
                  <SectionChecking>
                    <PasswordCheckingInfo>
                      <RowPasswordCheckingLength currentColor={currentColorLength}>  Comporte 8 caractères  {validLength ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLength>
                      <RowPasswordCheckingNumber number={currentColorNumber}>  Comporte un chiffre  {hasNumber ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingNumber>
                      <RowPasswordCheckingUppercase upperCase={currentColorUppercase}>  Comporte une majuscule  {uppercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingUppercase>
                      <RowPasswordCheckingLowercase lowerCase={currentColorLowercase}>  Comporte une minuscule  {lowercase ? <StyledCheckBadge /> : <StyledErrorCheckBadge /> }</RowPasswordCheckingLowercase>
                    </PasswordCheckingInfo>
                  </SectionChecking>
                </Sections>
                {error && (
                  <ErrorMessage>
                    {error.code === 400
                      ? format("patient.change.error.current.passwordPlaceholder")
                      : error.code === 401
                        ? format("patient.change.error.new.passwordPlaceholder")
                        : format("login.error.unknownError")}
                  </ErrorMessage>
                )}
              </ErrorWrapper>
              <Buttons>
                <Button
                  key="submit"
                  type="submit"
                  initial={{opacity: 0}}
                  animate={{opacity: 1, transition: {delay: 0.3}}}
                >
                  {format("patient.dashboard.info.submit")}
                </Button>
                <Button
                  secondary
                  onClick={() => setEditingAndChangeFields(false)}
                  type="button"
                  initial={{opacity: 0}}
                  animate={{opacity: 1, transition: {delay: 0.3}}}
                >
                  {format("patient.dashboard.info.cancel")}
                </Button>
              </Buttons>
            </Info>
          )}
        </SectionInformation>
      </form>
    </Encart>
  );
}

const Sections = styled.div`
	@media ${mobile} {
        ${stack("M", "center", "stretch")};
        padding: 20px;
	}
	@media ${desktop} {
	    ${row("L", "space-between", "stretch")};
	    flex-wrap: wrap;
	}
`;

const SectionInformation = styled(motion.div)`
	@media ${mobile} {
        ${stack("M", "center", "stretch")};
	}
	@media ${desktop} {
	    ${stack("XL", "flex-start", "stretch")}
	}
	background-color: ${colors.white};
	box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 15px;
`;

const Section = styled(motion.div)`
	@media ${mobile} {
        ${stack("M", "center", "stretch")};
	}
	@media ${desktop} {
	    ${stack("XL", "flex-start", "stretch")}
	}
	background-color: ${colors.white};
	border-radius: 12px;
	padding: 15px;
`;

const SectionChecking = styled(motion.div)`
	@media ${mobile} {
        ${stack("M", "center", "stretch")};
	}
	@media ${desktop} {
	    ${stack("XL", "flex-start", "stretch")}
	}
	background-color: ${colors.white};
	border-radius: 12px;
	padding: 15px;
`;

const Info = styled.div`
	${stack("S", "flex-start", "stretch")}
	
`;

const SkeletonLine = styled.div`
	height: 1rem;
	background-color: ${colors.grey};
	border-radius: 3px;
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};

	> * {
		flex-grow: 1;
	}
`;

const Encart = styled.div`
	width: 100%;
`;

const ErrorMessage = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;

const ErrorWrapper = styled.div`
	${stack("S")};
`;

const SuccessMessage = styled.div`
	color: ${colors.green};
	font-size: 20px;
`;

const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Subtitle = styled.div`
	font-size: 15px;
	color: ${colors.grey2};
`;

const SubtitleImportant = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;

const PasswordCheckingInfo = styled(motion.div)`
    @media ${mobile} {
     ${stack("M", "flex-start", "stretch")};
	    min-width: 140px;
	    padding:20px;
	}
    @media ${desktop} {
    ${stack("XL", "flex-start", "stretch")};
	    min-width: 170px;
	}
	font-size: 12px;
	border-radius: 12px;
	flex-grow: 1;
	background-color: ${colors.white};
`;

const RowPasswordCheckingLength = styled.div<{ currentColor?: boolean }>`
    @media ${mobile} {
     ${row("M", "center", "stretch")};
	}
    
    @media ${desktop} {
    ${row("M", "flex-start", "stretch")};
	}
    color: ${props => (props.currentColor ? colors.green : colors.grey2)};
`;

const RowPasswordCheckingNumber = styled.div<{ number?: boolean }>`
    @media ${mobile} {
     ${row("M", "center", "stretch")};
	}
	@media ${desktop} {
    ${row("M", "flex-start", "stretch")};
	}
    color: ${props => (props.number ? colors.green : colors.grey2)};
`;

const RowPasswordCheckingUppercase = styled.div<{ upperCase?: boolean }>`
    @media ${mobile} {
     ${row("M", "center", "stretch")};
	}
	@media ${desktop} {
    ${row("M", "flex-start", "stretch")};
	}
    color: ${props => (props.upperCase ? colors.green : colors.grey2)};
`;

const RowPasswordCheckingLowercase = styled.div<{ lowerCase?: boolean }>`
    @media ${mobile} {
     ${row("M", "center", "stretch")};
	}
	@media ${desktop} {
    ${row("M", "flex-start", "stretch")};
	}
    color: ${props => (props.lowerCase ? colors.green : colors.grey2)};
`;

const StyledCheckBadge = styled(CheckBadge)`
	width: 20px;
	height: 20px;
	color: ${colors.green};
`;

const StyledErrorCheckBadge = styled(BadgeError)`
	width: 20px;
	height: 20px;
	color: ${colors.red};
`;
