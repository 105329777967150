import styled from "@emotion/styled";
import { DocumentUploader } from "components/documents/document-uploader";
import { Practitioner, Surgery, SurgeryCompletionState } from "domain/surgery";
import {SurgeryHPU } from "domain/hpu";
import React, { useCallback, useEffect, useState } from "react";
import { useDocumentTypes, useDocumentTypesHealthCareProvider } from "services/document-type-service";
import { practitionerService } from "services/practitioner-service";
import { cardsColorsArray, colors, desktop, mobile, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { isPatientMinor } from "utils/isPatientMinor";
import { motion } from "framer-motion";
import { css } from "@emotion/react";
import BackIcon from "@assets/icons/back.svg";
import { DocumentsCategories } from "./documents-categories";
import { DocumentType, SurgeryDocumentSource, SurgeryDocumentValidationStatus, Document } from "../../domain/document";
import DownloadIcon from "@assets/icons/download.svg";
import axios, { AxiosError } from "axios";
import { downloadFile } from "../../utils/web";
import { useModules, useSecretaryModules } from "../../services/hospital-service";
import { Button } from "../button";
import { createModal } from "../../services/modal-service";
import { MySignModalPractitioner } from "../modals/practitioner/my-sign-modal-practitioner";
import { ApiError } from "../../services/api";

export const UploadSection: React.FC<{
	surgery: Surgery | SurgeryHPU;
	onDocCustomTypeSelected?: (docType: DocumentType) => void;
	practitioner?: Practitioner | null;
}> = ({ surgery, onDocCustomTypeSelected, practitioner }) => {
	const { format, formatDate } = useI18n();
	const { documentTypes } = useDocumentTypes();
	const [showed, setShowed] = useState<boolean | null>(true);
	let result: any = null;
	const sign = useCallback(
		async (surgery: Surgery | SurgeryHPU) => {
			setProcedureLoading(true);
			try {
				setProcedureLoading(false);
				result = await createModal<string | undefined>(({ onClose }) => (
					<MySignModalPractitioner surgeryId={surgery.id} surgeryDate={surgery.surgeryDate} onClose={onClose} />
				));
				setDocumentsLoading(result === "success");
				if (result) {
					await practitionerService.fetchSurgery(surgery.id);
				}
			} catch (e: any) {
				const apiError: AxiosError<ApiError> = e;
				if (apiError.response?.data.code === "ERR_NO_PHONENUMBER_OTHER_GUARDIAN") {
					alert(format("signError.phoneNumber"));
				}
				console.log(e.message);
			} finally {
				setProcedureLoading(false);
				documents.map(async document => {
					await practitionerService.updateDocument(surgery.id, document.id, document);
				});
			}
		},
		[format, surgery.patient?.id]
	);
	const [procedureLoading, setProcedureLoading] = useState<boolean>(false);
	const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
	const patientIsMinor =
		!!surgery.patient && isPatientMinor(new Date(surgery.patient.birthDate), new Date(surgery.creationDate));
	let documentType = [""];
	const uppyEndpointFactory = useCallback(() => practitionerService.getDocumentUploadEndpoint(surgery.id), [
		surgery.id,
	]);
	const fullSurgery = practitionerService.surgeryByIds.get().get(surgery.id)
	let documents : Document[] = []
	if(practitioner?.evaluation) {
		documents = surgery.surgeryDocuments
	}else {
		documents = surgery.surgeryDocuments.filter(doc =>
			documentTypes.find(docType => docType.id === doc.documentType.id)
		);
	}
	documents
		.sort((docA, docB) => (docA.documentType.position > docB.documentType.position ? 1 : -1))
		.map(doc => {
			documentType = [...documentType, doc.documentType.type];
		});
	const doesDocumentsNeedSignature = () => {
		const docs = documents.filter(
			document =>
				document.needSignature &&
				!document.isSigned &&
				document.validationStatus != SurgeryDocumentValidationStatus.Rejected &&
				document.documentType.source == SurgeryDocumentSource.HPU
		);
		return docs.length > 0;
	};
	const someDocumentsAreNotSigned: boolean = doesDocumentsNeedSignature();
	const modules = useModules(surgery.hospitalId);
	const [hasDpm, setHasDpm] = useState(false);
	useEffect(() => {
		if (modules && Boolean(modules.find(module => module.type == "dpm"))) {
			setHasDpm(true);
		}
	}, [modules]);

	//Remove duplicate entries
	documentType = Array.from(new Set(documentType));
	const existingTypes = documents
		.map(doc => doc.documentType.id)
		.filter(docTypeId => !documentTypes.find(d => d.id === docTypeId)?.isOtherType);

	const documentIdsToUpload = documentTypes
		.filter(d =>
			(!d.isForTutoring || patientIsMinor) &&
			!d.isForAdmission &&
			d.id != "617916d1054e99d7095ae290e19e7a19" && // 617916d1054e99d7095ae290e19e7a19 : Lien vidéo
			hasDpm
				? d.label != "Dépassement honoraires à editer"
				: (d.label != "Dépassement honoraires à editer" && d.label != "Dépassement honoraires personnalisé")
		)
		.sort((docA, docB) => (docA.position > docB.position ? 1 : -1))
		.map(d => d.id);

	const download = useCallback(
		async (surgery: Surgery) => {
			if (!surgery) {
				return;
			}
			try {
				const JSZip = await import("jszip");
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				const zip = new JSZip();
				await Promise.all(
					surgery.surgeryDocuments
						.filter(doc => doc.documentType.id != "617916d1054e99d7095ae290e19e7a19") // 617916d1054e99d7095ae290e19e7a19 : Lien vidéo
						.map(async (document, index) => {
						if (!document.url) {
							return null;
						}
						await practitionerService.log(surgery.id, document.id); // DynamoDB log
						const result = await axios.get(document.url, { responseType: "arraybuffer" });
						const isOtherType = documentTypes.find(d => d.id === document.id)?.isOtherType;
						const filename = isOtherType
							? `${document.documentType.label}-${index}.pdf`
							: `${document.documentType.label}.pdf`;
						zip.file(filename, result.data);
					})
				);

				const archive = await zip.generateAsync({ type: "blob" });
				downloadFile(
					`${surgery.patient?.firstName}_${surgery.patient?.lastName}_${formatDate(surgery.patient?.birthDate, {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					})}.zip`,
					archive
				);
			} catch (e) {
				throw e;
			}
		},
		[documentTypes]
	);

	const surgeryNeedSignature =
		surgery?.surgeryDocuments.reduce(
			(needSignature, document) => needSignature || (document.needSignature && !document.isSigned),
			false
		) ?? false;

	return (
		<Section>
			<Header>
				{documents.length > 0 && (
					<Row>
						<ShowAllButton
							onClick={() => {
								setShowed(true);
							}}
						>
							<SmallArrowIcon src="/icons/back.svg" iconcolor={colors.pink} showed={false.toString()} />
							<Text textColor={colors.black}>{format("practitioner.surgery.document.showAll")}</Text>
						</ShowAllButton>
						<ShowAllButton
							onClick={() => {
								setShowed(false);
							}}
						>
							<SmallArrowIcon src="/icons/back.svg" iconcolor={colors.pink} showed={true.toString()} />
							<Text textColor={colors.black}>{format("secretary.surgery.document.hideAll")}</Text>
						</ShowAllButton>
					</Row>
				)}
				<Documents documentsUploaded={documents.length > 0}>
					<DocumentUploader
						uppyEndpointFactory={uppyEndpointFactory}
						documentTypeIds={documentIdsToUpload}
						disabledTypeIds={existingTypes}
						allowMultipleFiles={false}
						showSignatureNeededCheckbox={true}
						showToPrintCheckbox={true}
						onDocumentCustomTypeSelected={docType => {
							if (onDocCustomTypeSelected) {
								onDocCustomTypeSelected(docType);
							}
						}}
						onSuccess={() => {
							practitionerService.fetchSurgery(surgery.id).then();
							practitionerService.fetchDocCustom(surgery.id).then();
						}}
						surgery={surgery}
						hpu={false}
					/>
				</Documents>
			</Header>
			<TopButton>
				{someDocumentsAreNotSigned ? (
					<ModalButton
						loading={procedureLoading}
						disabled={documentsLoading}
						onClick={() => {
							/*logSign();*/
							sign(surgery);
						}}
					>
						{format("patient.forensicDocumentsStep.signButton")}
					</ModalButton>
				) : null}
			</TopButton>
			<DownloadWrapper>
				<DownloadLabel>
					{format("practitioner.surgery.upload.downloadAllDocs")}
				</DownloadLabel>
				<Download
					color={fullSurgery?.stateLegalDocuments == SurgeryCompletionState.Completed ? 1 : 0}
					onClick={() => {
						if (fullSurgery?.stateLegalDocuments == "Completed") {
							download(fullSurgery as Surgery);
						}
					}}
				/>
			</DownloadWrapper>
			{documents.length <= 0 ? (
				<span>{format("practitioner.surgery.upload.emptyMiddleSection")}</span>
			) : !surgeryNeedSignature ? (
				documentType
					.filter(type => type != "")
					.map((it, index) => {
						return (
							<DocumentsCategories
								documents={documents}
								title={it}
								surgeryId={surgery.id}
								cardsColor={cardsColorsArray[index]}
								showAll={showed}
								key={index}
								onShowed={bool => {
									setShowed(bool);
								}}
							/>
						);
					})
			) : (
				documentType
					.filter(type => type != "" && type != "À signer")
					.map((it, index) => {
						return (
							<DocumentsCategories
								documents={documents}
								title={it}
								surgeryId={surgery.id}
								cardsColor={cardsColorsArray[index]}
								showAll={showed}
								key={index}
								onShowed={bool => {
									setShowed(bool);
								}}
							/>
						);
					})
			)}
		</Section>
	);
};

const Row = styled.div`
	${row("S", "flex-start", "flex-start")}
`;

const Header = styled.div`
	${row("L", "space-between", "stretch")}
`;
const Section = styled.div`
	${stack("L", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

const ShowAllButton = styled.div`
	cursor: pointer;
	${row("S", "flex-start", "stretch")}
`;

export const DocumentTitle = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Documents = styled.div<{ documentsUploaded?: boolean }>`
	${props => (props.documentsUploaded ? row("S", "space-between", "flex-end") : row(0, "flex-end"))};
`;
const SmallArrowIcon = styled(BackIcon)<{ iconcolor?: string }>`
	width: 24px;
	path {
		fill: ${props => props.iconcolor ?? ""};
	}
	transform: ${props =>
		props.showed
			? css`
		 rotate(180deg)
	`
			: null};
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	font-weight: bold;
	color: ${props => props.textColor ?? "black"};
`;

const DownloadWrapper = styled.div`
	display: flex;
`;

const DownloadLabel = styled.span`
	margin-right: 12px;
	padding-top: 5px;
`;

const Download = styled(DownloadIcon)<{ color: boolean }>`
	cursor: pointer;
	height: 35px;
	width: 35px;
	path,
	rect {
		fill: ${props => (props.color ? colors.pink : colors.grey2)};
	}
`;

const TopButton = styled.div`
	@media ${mobile} {
		${stack("M", "flex-end", "stretch")};
	}

	@media ${desktop} {
		${row("XL", "flex-end", "flex-start")};
	}
`;

const ModalButton = styled(Button)`
	@media ${desktop} {
		margin-left: 400px;
	}
`;