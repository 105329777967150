import {useComputedObservable, WritableObservable} from "micro-observables";
import {UserProfile} from "../domain/user-profile";
import {api} from "./api";
import {useUser} from "./auth-service";
import {useCallback, useEffect} from "react";
import {Terms, TermsDocument} from "../domain/terms";

export class UserProfileService {
    public userProfile = new WritableObservable<UserProfile | null>(null);
    public termsDoc = new WritableObservable<TermsDocument | null>(null);

    async fetchUserProfile(userId: string | null | undefined, audience: string): Promise<UserProfile | null | string> {
        if (userId && userId?.length > 0 && audience) {
            try {
                const result = await api.get<UserProfile>("userprofile/accepted-terms", {
                    params: { userId, audience },
                });
                this.userProfile.set(result.data);
                return result.data;
            } catch (e) {
                console.log("Query fetchUserProfile aborted.");
            }
            return null;
        } else return null;
    }

    async setAcceptedLastTerms(
        userProfileId: string | undefined | null
    ) {
        try {
            if (userProfileId) {
                await api.post("userprofile/accepted-terms", { userProfileId } );
            }
        } catch (e) {
            console.log(e);
        }
    }

    async createUserProfile(userId: string, audience: string): Promise<UserProfile> {
        const newUserProfile = await api.post<UserProfile>("userprofile/new", {
            "userId": userId,
            "audience": audience,
        });
        return newUserProfile.data;
    }

    async addNewTermsRow(userProfile: UserProfile | null | undefined, touName: string, touDate: string, gdprName: string, gdprDate: string): Promise<Terms> {
        const newTerms = await api.post<Terms>("terms/new", {
            "userProfile": userProfile,
            "touName": touName,
            "touDate": touDate,
            "gdprName": gdprName,
            "gdprDate": gdprDate
        });
        return newTerms.data;
    }

    async fetchLastTermsDocumentOfCategory(category: string): Promise<TermsDocument> {
        const res = await api.get<TermsDocument>("terms/terms-documents/last", {params: {category}});
        this.termsDoc.set(res.data);
        return res.data;
    }

    async setHasWatchedTuto(
        userProfileId: string | undefined | null
    ) {
        try {
            if (userProfileId) {
                await api.post("userprofile/has-watched-tuto", { userProfileId } );
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export const userProfileService = new UserProfileService();

export function useUserProfile(userId: string | null | undefined, audience: string): {userProfile: UserProfile | null} {
    const user = useUser();
    const userProfile = useComputedObservable( () => userProfileService.userProfile.get(), []);
    const fetchUserProfile = useCallback( async () => {
        if (user && userId && userId.length > 0 && audience) {
            try {
                await userProfileService.fetchUserProfile(userId, audience);
            } catch (e) {
                console.log(e);
            }
        } else return null;
    }, [user]);
    useEffect( () => {
        fetchUserProfile().then();
    }, [fetchUserProfile]);
    return {userProfile};
}
