import styled from "@emotion/styled";
import React, { InputHTMLAttributes, useMemo } from "react";
import { colors, row, sizes } from "style";
import { isDateInputSupported } from "utils/time";

interface AdditionalInputProps {
	icon?: string;
}
export const DateInput: React.FC<InputHTMLAttributes<HTMLInputElement> & AdditionalInputProps> = ({
	icon,
	...props
}) => {
	const dateInputIsSupported = useMemo(() => isDateInputSupported(), []);
	return (
		<Container>
			<Input
				{...props}
				type="date"
				pattern={dateInputIsSupported ? "[0-9]{4}-[0-9]{2}-[0-9]{2}" : "[0-9]{2}/[0-9]{2}/[0-9]{4}"}
				placeholder={dateInputIsSupported ? props.placeholder : "JJ/MM/YYYY"}
			/>
			{icon ? <Icon src={icon} /> : null}
		</Container>
	);
};

const Container = styled.div`
	${row(0)};
	position: relative;
`;

const Icon = styled.img`
	width: 20px;
	height: 20px;
	position: absolute;
	top: ${sizes.S};
	left: ${sizes.S};
	pointer-events: none;
`;

const Input = styled.input<{ icon?: boolean }>`
	appearance: none;
	height: 40px;
	border-radius: 7px;
	border: none;
	box-shadow: 0px 0px 0px 1px #c8efec inset;
	background-color: ${props => (props.disabled ? "rgba(0,0,0,0.03)" : colors.white)};
	padding-left: ${props => (props.icon ? sizes.XL : sizes.S)};
	padding-right: ${sizes.S};
	flex-grow: 1;
	::placeholder {
		font-size: 13px;
		font-style: italic;
		color: ${colors.grey};
	}
	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
	transition: box-shadow 0.15s ease-out;
`;
