import {WritableObservable} from "micro-observables";
import {MediaFile} from "../domain/media-file"
import {api} from "./api";

export class MediaFileService {
    public mediaFile = new WritableObservable<MediaFile | null>(null);

    async fetchMediaFile(fileName: string): Promise<MediaFile> {
        const res = await api.get<MediaFile>("medias/get", {params: {fileName}});
        this.mediaFile.set(res.data);
        return res.data;
    }
}

export const mediaFileService = new MediaFileService();