import styled from "@emotion/styled";
import {Button} from "components/button";
import {FormField} from "components/form-field";
import {SelectInput} from "components/inputs/select-input";
import {TextInput} from "components/inputs/text-input";
import {Section, SectionTitle} from "components/section";
import { Surgery, SurgeryLaterality, SurgeryUpdateDTO } from "domain/surgery";
import {fullName} from "domain/utils";
import {motion} from "framer-motion";
import React, { CSSProperties, FormEvent, HTMLAttributes, useCallback, useMemo, useState } from "react";
import {colors, gammeColors, row, sizes, Spacer, stack} from "style";
import {useI18n} from "utils/i18n";
import {normalizeDate} from "utils/time";
import debounce from "lodash/debounce";
import {ConfirmSurgeryEditModal} from "../modals/confrm-surgery-edit-modal";
import {createModal} from "../../services/modal-service";
import {
	useCCAMDatabase,
} from "../../services/secretary-service";

interface SurgerySectionProps {
	surgery: Surgery;
	onEdit?: (data: SurgeryUpdateDTO) => Promise<void>;
	overrideTitle?: string;
	showPatient?: boolean;
	style?: CSSProperties;
	hospital?: boolean;
	practitionerId?: string;
}

export const SurgerySection: React.FC<SurgerySectionProps> = ({ surgery, onEdit, showPatient, style , hospital = false, practitionerId}) => {
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [classificationCodeDebounced, setClassificationCodeDebounced] = useState("");
	const [surgeryDate, setSurgeryDate] = useState(""); // format : 2020-06-30T00:00:00
	const [classificationCode, setClassificationCode] = useState("");
	const { format, formatDate } = useI18n();
	const [laterality, setLaterality] = useState(SurgeryLaterality.None.toString());
	const tagColors = ["#07dad8","#45D7FF","#8487FF","#DE9FFF","#FF9FD8","#47cc3e","#2BC2BC"];
	const { formatLaterality } = useI18n();
	const [needHealthcareProvider, setNeedHealthcareProvider] = useState(!!surgery.healthcareProviderId);

	const setEditingAndResetFields = useCallback(
		(editing: boolean) => {
			setEditing(editing);
			setSurgeryDate(surgery.surgeryDate);
			setClassificationCode(surgery.classificationCode ?? "");
			setLaterality(surgery.laterality ?? SurgeryLaterality.None.toString());
			setNeedHealthcareProvider(!!surgery.healthcareProviderId ?? null);
		},
		[surgery.classificationCode, surgery.laterality, surgery.surgeryDate, format, surgery.healthcareProviderId]
	);
	const debounceClassificationCode = useMemo(
		() =>
			debounce((code: string) => {
				setClassificationCodeDebounced(code);
			}, 1500),
		[]
	);
	const handleClassificationCodeInputChange = (e: { target: { value: string; }; }, index: number) => {
		const value = e.target.value.toUpperCase()
		const list = classificationCode.split(",");
		list[index] = value;
		setClassificationCode(list.toString());
	};
	const handleLateralityInputChange = (e: string, index: number) => {
		const value = e
		const list = laterality.split(",");
		list[index] = value;
		setLaterality(list.toString());
	};

	const handleRemoveClick  = (classificationValue: string, lateralityValue: string, index: number) => {
		const listClassificationCode = classificationCode.split(",");
		const listLaterality = laterality.split(",");
		if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
			listClassificationCode.splice(index, 1).toString();
			setClassificationCode(listClassificationCode.toString());
			listLaterality.splice(index, 1);
			if(listLaterality.length > 0){
				setLaterality(listLaterality.toString());
			}
			else{
				setLaterality(SurgeryLaterality.None.toString());
			}
		}
	};
	const handleAddClick  = () => {
		const listClassificationCode = classificationCode.split(",");
		const listLaterality = laterality.split(",");
		setClassificationCode([...listClassificationCode, ""].toString());
		setLaterality([...listLaterality, SurgeryLaterality.None.toString()].toString());
	};
	const save = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			if (loading) {
				return;
			}

			try {

				const onCloseReturn = await createModal<string | null>(({onClose}) => (
					<ConfirmSurgeryEditModal
						onClose={onClose}
						surgeryId={surgery.id}
						practitionerId={practitionerId}
						surgery={ surgery }
						surgeryDate={surgeryDate ?? undefined}
						classificationCode={classificationCode ?? undefined}
						laterality={laterality ?? undefined}
						needHealthcareProvider={needHealthcareProvider ?? undefined}
						/>
				));


				if(onCloseReturn != null) {
					setLoading(true);
					await onEdit?.({
						surgeryDate: surgeryDate ? new Date(normalizeDate(surgeryDate)).toISOString() : undefined,
						classificationCode: classificationCode ?? undefined,
						laterality: laterality ?? undefined,
					});
				}

			} catch (e) {
				console.log(e);
			} finally {
				setEditingAndResetFields(false);
				setLoading(false);
			}
		},
		[loading, onEdit, surgeryDate, classificationCode, laterality, setEditingAndResetFields]
	);

	const areDataMissing = !surgery.surgeryDate || !surgery.classificationCode || !surgery.laterality;

	const { ccamList } = useCCAMDatabase(practitionerId);

	return (
		<Section style={style}>
			<Form onSubmit={save}>
				<SectionTitle>{format("surgerySection.title")}</SectionTitle>
				{!editing ? (
					<>
						<Fields>
							{showPatient ? (
								<FormField label={format("surgerySection.patientLabel")} style={{ gridArea: "identity" }}>
									<FormValue>
										{fullName(surgery.patient)}
									</FormValue>
								</FormField>
							) : (
								<FormField label={format("surgerySection.practitionerLabel")} style={{ gridArea: "identity" }}>
									<FormValue>
										{fullName(surgery.practitioner)}
									</FormValue>
								</FormField>
							)}
							{
								surgery.codeExt ?
									<>
										<FormField
											label={format("surgerySection.codeExtLabel")}
											style={{ gridArea: "classificationCode" }}
										>
											<Tagdiv>
												<TagFlag color={gammeColors.orange} key={""}>{surgery.codeExt}</TagFlag>
											</Tagdiv>
										</FormField>
									</>
									:
									<>
										<FormField
											label={format("surgerySection.classificationCodeLabel")}
											style={{ gridArea: "classificationCode" }}
										>
											<Tagdiv>
												{surgery.classificationCode?.split(",").map(
													(value, index) => {
														return(
															<TagFlag color={tagColors[index % tagColors.length]} key={index}> <FormValue>{value}</FormValue></TagFlag>
														)
													})
												}
											</Tagdiv>
										</FormField>
										<FormField label={format("surgerySection.lateralityLabel")} style={{ gridArea: "laterality" }}>
											<Tagdiv>
												{surgery.laterality?.split(",").map(
													(value, index) => {
														return(
															<TagFlag color={tagColors[index % tagColors.length]} key={index}> <FormValue>{formatLaterality(value)}</FormValue> </TagFlag>
														)
													}
												)
												}
											</Tagdiv>
										</FormField>
									</>
							}

						</Fields>
						<Label>
							{format("surgerySection.surgeryNameLabel")}
						</Label>
						{
							surgery.codeDescExt ?
								<DivName key={""}>
									<ListName key={""}>
										{surgery.codeDescExt.toString()}
									</ListName>
								</DivName>
								:
							surgery.classificationCode ?
							surgery.classificationCode.split(",").map((value, index) => {
								return(
									<DivName key={index}>
										{ ccamList?
												<ListName key={index}>
													{ccamList.find(ccam => ccam.codeCCAM == value)?.descriptionCustom ?? ccamList.find(ccam => ccam.codeCCAM == value)?.description ??
													"..."}
												</ListName>
											: null
										}
									</DivName>
								)
							}): null
						}
						<Spacer />
						<Buttons>
							{onEdit && !hospital && (
								<Button onClick={() => setEditingAndResetFields(true)} type="button">
									{format(areDataMissing ? "surgerySection.completeButton" : "surgerySection.editButton")}
								</Button>
							)}
						</Buttons>
					</>
				) : (
					<>
						<EditFields>
							{showPatient ? (
								<FormField label={format("surgerySection.patientLabel")} style={{ gridArea: "identity" }}>
									<FormValue>
										{fullName(surgery.patient)}
									</FormValue>
								</FormField>
							) : (
								<FormField label={format("surgerySection.practitionerLabel")} style={{ gridArea: "identity" }}>
									<FormValue>
										{fullName(surgery.practitioner)}
									</FormValue>
								</FormField>
							)}
							{
								classificationCode?.split(",")?.map((value, index)=>{
									return (
										<Fields key={index}>

												<FormField
													label={format("surgerySection.classificationCodeLabel")}
													style={{ gridArea: "classificationCode" }}
												>				<TextInput
												value={value}
												placeholder={format("createSurgeryModal.classificationCode.placeholder")}
												onChange={e => {
													handleClassificationCodeInputChange(e, index);
													debounceClassificationCode(e.target.value);
												}}
												maxLength={7}
												required
											/>
										</FormField>
										<FormField label={format("surgerySection.lateralityLabel")} style={{ gridArea: "laterality" }}>
											<SelectInput<string>
												innerId="side"
												value={laterality.split(",")[index]}
												onChange={e =>{handleLateralityInputChange(e, index)}}
												options={[
													SurgeryLaterality.None,
													SurgeryLaterality.Left,
													SurgeryLaterality.Right,
													SurgeryLaterality.Both,
												]}
												itemRenderer={formatLaterality}
												required
											/>
										</FormField>
										<FormField label={format("createSurgeryModal.surgeryName.label")}
												   style={{gridArea: "name"}}>
											<SurgeryEditName>
												{
													!value
														? format("surgerySection.missingClassificationCode")
														: !ccamList
															? "…"
															: ccamList.find(ccam => ccam.codeCCAM == classificationCode.split(",")[index])?.descriptionCustom ?? ccamList.find(ccam => ccam.codeCCAM == classificationCode.split(",")[index])?.description ??
															(classificationCodeDebounced ? format("surgerySection.invalidClassificationCode") : "…")
												}
											</SurgeryEditName>
										</FormField>
										<FormField label={""} style={{gridArea: "button"}}>
											<Buttons>
												<Button secondary type="button" onClick={() => {
													handleRemoveClick(value, laterality.split(",")[index], index)}}>{format("practitioner.surgery.removeCCAM")}
												</Button>
											</Buttons>
										</FormField>
									</Fields>
								)})
							}
						</EditFields>
						<Spacer />
						<Buttons layout>
							<Button type="button" third  onClick={() => {
								handleAddClick()}}>{format("practitioner.surgery.addCCAM")}
							</Button>
							<Button
								key="submit"
								type="submit"
								loading={loading}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { delay: 0.3 } }}
							>
								{format("surgerySection.saveButton")}
							</Button>
							<Button
								secondary
								onClick={() => setEditingAndResetFields(false)}
								type="button"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { delay: 0.3 } }}
							>
								{format("surgerySection.cancelButton")}
							</Button>
						</Buttons>
					</>
				)}
			</Form>
		</Section>
	);
};


export const TagFlag: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, color, ...otherProps }) => {
	return (
		<Flag style={{ backgroundColor: color, color: "#ffffff" }} {...otherProps}>
			{children}
		</Flag>
	);
};

const Flag = styled.div`
	padding: 1px 8px 2px 8px;
	border-radius: 10px;
	font-size: 12px;
	font-weight: bold;
`;
const Form = styled.form`
	${stack("L", "flex-start", "stretch")};
	flex-grow: 1;
`;

const Label = styled(motion.label)`
	font-size: 12px;
	font-weight: bold;
	color: ${colors.grey};
	text-transform: uppercase;
`;
const FormValue = styled.div``;

const DivName = styled.div`
${row(0)}
`;
const Fields = styled.div`
	display: grid;
	grid-row-gap: ${sizes.M};
	grid-column-gap: ${sizes.XL};
	grid-gap: ${sizes.L};
	align-items: center;
	grid-template-areas:
		"identity date date"
		"classificationCode laterality button"
		"name name name"
`;

const Tagdiv = styled.div`
	${stack("S")}
`;

const EditFields = styled.div`
	${stack("M")};
`;

const ListName = styled.li`
`

const SurgeryEditName = styled.div`
	width: 600px;
`;


const Buttons = styled(motion.div)`
	align-self: flex-end;
	padding-top: 15px;
	${row("S", "flex-start", "stretch")};

	> * {
		flex-grow: 1;
	}
`;
