import SignIcon from "@assets/icons/signature.svg";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Surgery, SurgeryCompletionState } from "domain/surgery";
import React from "react";
import { colors, row } from "style";
import { GreenFlag, RedFlag } from "style/flags";
import { useI18n } from "utils/i18n";

export const StateLegalDocumentsField: React.FC<{ surgery: Surgery }> = ({ surgery }) => {
	const { format } = useI18n();
	const allDocumentsRequiredSignature = surgery.surgeryDocuments.filter(
		doc => doc.needSignature && doc.documentType.source != "HPU"
	);
	const allDocumentsSigned = surgery.surgeryDocuments.filter(
		doc => doc.needSignature && doc.isSigned && doc.documentType.source != "HPU"
	);

	return surgery.stateLegalDocuments &&
		surgery.stateLegalDocuments !== SurgeryCompletionState.NotApplicable &&
		allDocumentsRequiredSignature.length > 0 ? (
		surgery.stateLegalDocuments === SurgeryCompletionState.InProgress ? (
			<Row>
				{format("table.signed-documents.inProgress", {
					signedDocuments: allDocumentsSigned.length,
					totalDocuments: allDocumentsRequiredSignature.length,
				})}
				<NotSignIcon />
			</Row>
		) : surgery.stateLegalDocuments === SurgeryCompletionState.Completed ? (
			<Row>
				<GreenFlag withIcon withBackgroundColor>
					<GreenSignIcon />
				</GreenFlag>
			</Row>
		) : surgery.stateLegalDocuments === SurgeryCompletionState.Critical ? (
			<Row>
				<RedFlag withIcon>
					<RedSignIcon />
				</RedFlag>
			</Row>
		) : (
			<>{format("table.signed-documents.other")}</>
		)
	) : (
		<>{format("table.signed-documents.other")}</>
	);
};

const Row = styled.div`
	${row(0, "flex-start", "center")};
`;

const iconStyle = css`
	display: block;
	width: 15px;
	height: 15px;
	margin-left: 3px;
`;

const NotSignIcon = styled(SignIcon)`
	${iconStyle};

	path,
	rect {
		fill: #ff9e3f;
	}
`;

const GreenSignIcon = styled(SignIcon)`
	${iconStyle};

	path,
	rect {
		fill: ${colors.green};
	}
`;
const RedSignIcon = styled(SignIcon)`
	${iconStyle};

	path,
	rect {
		fill: ${colors.red};
	}
`;
