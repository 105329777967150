import styled from "@emotion/styled";
import { SurgeryStepIcon } from "components/surgery-step-icon";
import { Patient } from "domain/patient";
import { allHPUSteps, SurgeryStep } from "domain/surgery-step";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { colors, row } from "style";

interface HPUSurgeryTimelineProps {
	step: SurgeryStep;
	patient: Patient;
	surgeryId?: string;
}

export const HPUSurgeryTimeline: React.FC<HPUSurgeryTimelineProps> = ({ step: currentStep, patient, surgeryId }) => {
	// region Modules - on ajoute le module post operative
	const arraySteps = allHPUSteps;

	// endregion

	return (
		<Steps>
			{arraySteps.map((step, index) => (
				<Fragment key={index}>
					<Link key={step} to={`/hpu/patient/${patient.id}/surgery/${surgeryId}/${step}`}>
						<SurgeryStepIcon step={step} active={true} focused={currentStep === step} />
					</Link>
					{index < arraySteps.length - 1 ? <Progress pending={true} /> : null}
				</Fragment>
			))}
		</Steps>
	);
};

const Progress = styled.div<{ pending: boolean | undefined }>`
	width: 31px;
	height: 4px;
	border-radius: 2.5px;
	background-color: ${colors.grey};
	flex-shrink: 1;
	flex-grow: 1;

	div {
		background-color: ${props => (props.pending ? colors.orange : colors.green)};
	}
`;
const Steps = styled.div`
	${row("S", "center", "center")};
	position: relative;
`;
