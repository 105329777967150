import React, {InputHTMLAttributes} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import { denormalizeDate } from "utils/time";
import styled from "@emotion/styled";
import {colors, sizes} from "../../style";
registerLocale('fr', fr)
setDefaultLocale('fr', fr)

interface AdditionalInputProps {
    placeholder?: string;
    surgeryDate?: Date;
    newDate?: string | null;
    earlierDatesAllowed?: boolean;
    minDate?: string | null;
}
export const DatePickerInput: React.FC<InputHTMLAttributes<HTMLInputElement> & AdditionalInputProps > = ({
    placeholder,
    surgeryDate,
    newDate,
    earlierDatesAllowed,
    minDate,
    ...props
}) => {
    const today = new Date();

    return (
        <StyledDatePicker
            placeholderText={placeholder ?? ""}
            selected={surgeryDate ?? today}
            value={newDate ? denormalizeDate(newDate) : ""}
            minDate={minDate ? new Date(minDate) : earlierDatesAllowed ? new Date(1900, 1 ,1) : today}
            locale="fr"
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            peekNextMonth
            showMonthDropdown
            dropdownMode="select"
            fixedHeight
            yearDropdownItemNumber={122}
            scrollableYearDropdown
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.blur()}
            {...props}
        />
    );
};

const StyledDatePicker = styled(DatePicker)`
	appearance: none;
	height: 40px;
	border-radius: 7px;
	border: none;
	box-shadow: 0px 0px 0px 1px #c8efec inset;
	background-color: ${props => (props.disabled ? "rgba(0,0,0,0.03)" : colors.white)};
	padding-left: ${sizes.S};
	padding-right: ${sizes.S};
	flex-grow: 1;

	::placeholder {
		font-size: 13px;
		font-style: italic;
		color: ${colors.grey};
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
	transition: box-shadow 0.15s ease-out;
`;
