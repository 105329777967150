import styled from "@emotion/styled";
import React from "react";
import { colors, row } from "style";

export interface SelectInputProps<T> {
	innerId: string;
	value: T | null;
	options: readonly T[];
	disabledOptions?: readonly T[];
	onChange: (v: T) => void;
	label?: string;
	placeholder?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	required?: boolean;
	autoFocus?: boolean;
	disabledArrow?: boolean;
	className?: string;
	itemRenderer?: (item: T) => string;
	multiple?: boolean;
}

export type SelectInputType<K> = React.FC<SelectInputProps<K>>;

export function SelectInput<T>({
	innerId: id,
	label,
	onChange,
	placeholder,
	disabledArrow,
	options,
	disabledOptions,
	value,
	className,
	style,
	itemRenderer = v => `${v}`,
 	multiple,
	...rest
}: SelectInputProps<T>): JSX.Element {
	let selectedIndex: number | undefined;
	if (value !== null) {
		const valueStr = itemRenderer(value);
		const index = options.findIndex(it => itemRenderer(it) === valueStr);
		if (index >= 0) {
			selectedIndex = index;
		}
	}
	return (
		<SelectContainer className={className} hasLabel={!!label} style={style}>
			{label && <Label htmlFor={id}>{label}</Label>}
			<SelectWrapper>
				<StyledSelect
					name={id}
					id={id}
					onChange={e => onChange(options[+e.currentTarget.value])}
					value={selectedIndex !== undefined ? selectedIndex : placeholder}
					multiple={multiple}
					{...rest}
				>
					{placeholder && !value ? <option disabled>{placeholder}</option> : null}
					{options.map((v, index) => (
						<option key={index} value={index} disabled={disabledOptions?.includes(v) || undefined}>
							{itemRenderer(v)}
						</option>
					))}
				</StyledSelect>
				{!disabledArrow ? <ArrowDownIcon src="/icons/dropdown.svg" /> : null}
			</SelectWrapper>
		</SelectContainer>
	);
}

const SelectContainer = styled.div<{ hasLabel: boolean }>`
	display: inline-flex;
	flex-direction: ${props => (props.hasLabel ? "column" : "row")};
	flex-shrink: 1;
	flex-grow: 1;
`;

const Label = styled.label`
	font-weight: 600;
	color: ${colors.black};
	margin-bottom: 10px;
	text-transform: uppercase;
	font-size: 15px;
`;

const SelectWrapper = styled.div`
	position: relative;
	flex-shrink: 1;
	flex-grow: 1;
	${row(0)};
`;

const StyledSelect = styled.select`
	overflow: hidden;

	font-family: inherit;
	appearance: none;
	-webkit-appearance: none;
	font-size: 15px;
	color: ${colors.black};
	flex-shrink: 0;
	padding: 8px 30px 10px 16px;
	border-radius: 8px;
	border: none;
	box-shadow: 0px 0px 0px 1px #c8efec inset;
	background-color: ${colors.white};
	appearance: none;
	outline: 0;
	flex-grow: 1;

	@media (max-width: 380px) {
		width: 240px;
	}

	&:disabled {
		color: ${colors.grey2};
		background: #f4f4f4;
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
	transition: box-shadow 0.15s ease-out;
`;

const ArrowDownIcon = styled.img`
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	width: 15px;
`;
