import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { List } from "immutable";
import React, { CSSProperties } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let containerSingleton: ModalContainer<any> | null = null;

export interface ModalProps<T> {
	onClose: (param: T) => void;
	style?: CSSProperties;
}
interface ModalOptions<T> {
	valueOnBackdropClick?: T;
	backdrop?: boolean;
	displayAllElements?: boolean;
	anchor?: HTMLElement;
}

export enum Role {
	Secretary = "secretary",
	Practitioner = "practitioner",
	Hpu = "hpu",
	Patient = "patient",
	HospitalUser = "hospital",
}

export function setModal<T>(element: React.ComponentType<ModalProps<T>>, options: ModalOptions<T> = {}): Promise<T> {
	if (containerSingleton === null) {
		return Promise.reject("No <ModalContainer> mounted in current document");
	}
	containerSingleton.setModal(element, options);
	return containerSingleton.getCurrentModalPromise();
}

export function createModal<T>(element: React.ComponentType<ModalProps<T>>, options: ModalOptions<T> = {}): Promise<T> {
	if (containerSingleton === null) {
		return Promise.reject("No <ModalContainer> mounted in current document");
	}
	containerSingleton.addModal(element, options);
	return containerSingleton.getCurrentModalPromise();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearModal(): Promise<any[]> {
	if (containerSingleton === null) {
		return Promise.reject("No <ModalContainer> mounted in current document");
	}
	containerSingleton.setModal(null, {});
	return Promise.all(containerSingleton.promises);
}

export function isModalOpen(): boolean {
	return !!containerSingleton && containerSingleton.state.elements.size > 0;
}

interface ModalContainerState<T> {
	elements: List<{
		element: React.ComponentType<ModalProps<T>>;
		valueOnBackdropClick?: T;
		backdrop: boolean;
		anchor?: HTMLElement;
	}>;
	displayAllElements: boolean;
}
// eslint-disable-next-line @typescript-eslint/ban-types
export class ModalContainer<T> extends React.Component<{}, ModalContainerState<T>> {
	// eslint-disable-next-line @typescript-eslint/ban-types
	constructor(props: {}) {
		super(props);
		this.state = { elements: List(), displayAllElements: false };
	}

	componentDidMount(): void {
		containerSingleton = this;
	}

	setModal = (element: React.ComponentType<ModalProps<T>> | null, options: ModalOptions<T>) => {
		this.setState({
			elements: element
				? List([
						{
							element,
							backdrop: options.backdrop !== undefined ? options.backdrop : true,
							valueOnBackdropClick: options.valueOnBackdropClick,
						},
				  ])
				: List(),
			displayAllElements: options.displayAllElements || false,
		});
		if (element) {
			this.promises = this.promises.push(
				new Promise(resolve => {
					this.resolvers = this.resolvers.push(resolve);
				})
			);
		} else {
			this.promises = List();
			this.resolvers = List();
		}
	};

	addModal = (element: React.ComponentType<ModalProps<T>>, options: ModalOptions<T>) => {
		this.setState(state => ({
			elements: state.elements.push({
				element,
				valueOnBackdropClick: options.valueOnBackdropClick,
				backdrop: options.backdrop !== undefined ? options.backdrop : true,
				anchor: options.anchor,
			}),
			displayAllElements:
				options.displayAllElements === undefined ? state.displayAllElements : options.displayAllElements,
		}));
		this.promises = this.promises.push(
			new Promise(resolve => {
				this.resolvers = this.resolvers.push(resolve);
			})
		);
	};

	resolvers: List<(param: T) => void> = List();
	promises: List<Promise<T>> = List();

	closeModal = (result: T, index: number) => {
		this.setState(state => ({ elements: state.elements.delete(index) }));
		const resolve = this.resolvers.get(index);

		if (resolve) {
			resolve(result);
		}
		this.resolvers = this.resolvers.remove(index);
		this.promises = this.promises.remove(index);
	};

	getCurrentModalPromise(): Promise<T> {
		if (this.promises.size < 1) {
			throw Error("ModalContainer promises stack is empty");
		}
		return this.promises.last();
	}

	render(): List<JSX.Element> {
		const lastElement = this.state.elements.last(null);
		const { backdrop, valueOnBackdropClick, anchor } = lastElement || {
			backdrop: undefined,
			valueOnBackdropClick: undefined,
		};

		return this.state.elements.map((e, index) => (
			<ModalContent noBackdrop={!backdrop} key={index}>
				{this.state.elements.size > 0 ? (
					<Backdrop
						onClick={
							valueOnBackdropClick !== undefined
								? () => this.closeModal(valueOnBackdropClick, this.state.elements.size - 1)
								: // eslint-disable-next-line @typescript-eslint/no-empty-function
								  () => {}
						}
						background={!!backdrop}
					>
						{this.renderModal(e.element, index, anchor)}
					</Backdrop>
				) : null}
			</ModalContent>
		));
	}

	renderModal = (Element: React.ComponentType<ModalProps<T>>, index: number, anchor?: HTMLElement) => {
		return (
			<ModalWrapper
				key={Element.displayName}
				hidden={!this.state.displayAllElements && index < this.state.elements.size - 1}
			>
				<Element
					onClose={(param: T) => this.closeModal(param, index)}
					style={
						anchor
							? {
									position: "absolute",
									top: anchor.getBoundingClientRect().top,
									left: anchor.getBoundingClientRect().left,
							  }
							: undefined
					}
				/>
			</ModalWrapper>
		);
	};
}

const Backdrop: React.FC<{ onClick: () => void; background: boolean }> = ({ onClick, background, children }) => {
	return (
		<BackdropContent
			onClick={onClick}
			background={background}
			initial={{ background: "rgba(0,0,0,0)" }}
			animate={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}
			transition={{ duration: 0.1 }}
		>
			{children}
		</BackdropContent>
	);
};

const BackdropContent = styled(motion.div)<{ width?: number; height?: number; background: boolean }>`
	width: ${({ width }) => (width ? `${width}px` : `100vw`)};
	height: ${({ height }) => (height ? `${height}px` : `100vh`)};
	background: ${({ background }) => (background ? "rgba(0, 0, 0, 0.26)" : "none")};
	position: relative;
`;

const ModalContent = styled.div<{ noBackdrop: boolean }>`
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1000;

	pointer-events: ${({ noBackdrop }) => (noBackdrop ? "none" : null)};
`;

const ModalWrapper = styled.div<{ hidden: boolean }>`
	${({ hidden }) =>
		hidden
			? css`
					display: none;
			  `
			: css`
					position: absolute;
					min-width: 100vw;
					min-height: 100vh;
					display: flex;
					align-items: center;
					justify-content: center;
			  `};
`;

export const ModalCardContainer = styled.div`
	@keyframes modal-in {
		0% {
			transform: translateY(100px);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	animation: modal-in 200ms ease-out;
	overflow: hidden;
	background-color: #fff;
	border-radius: 26px;
	box-shadow: 0 32px 114px 0 rgba(0, 0, 0, 0.1);
	pointer-events: auto;
	position: absolute;
	will-change: opacity;
`;

export const ModalCloseIcon = (props: React.HTMLAttributes<HTMLImageElement>) => (
	<ModalCloseIconImage src="/icons/close.svg" {...props} />
);
const ModalCloseIconImage = styled.img`
	width: 24px;
	height: 24px;
	position: absolute;
	padding: 5px;
	top: 15px;
	right: 15px;
	box-sizing: content-box;
	cursor: pointer;
	border-radius: 12px;
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	z-index: 10;
	&:hover {
		transform: scale(1.1);
	}
`;
