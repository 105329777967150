import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import {colors, stack} from "../../style";
import { practitionerService, usePractitionerProfile } from "../../services/practitioner-service";
import { useI18n } from "../../utils/i18n";
import { FormField } from "../../components/form-field";
import { CheckboxInput } from "../../components/inputs/checkbox-input";
import { Button } from "../../components/button";

interface FileUploadProps {
	onClose?: () => void;
}
export const FileUpload: React.FC<FileUploadProps> = ({ onClose }) => {
	const [currentFile, setCurrentFile] = useState<Blob | undefined>();
	const [nameFile, setNameFile] = useState<string | undefined>();
	const { format } = useI18n();
	const profile = usePractitionerProfile();
	const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		const file = selectedFiles?.[0];
		setNameFile(file.name);
		const reader = new FileReader();
		reader.readAsText(file);
		setCurrentFile(file);
	};
	const saveSign = useCallback(async profile => {
		await practitionerService.saveSign(profile);
		await practitionerService.fetchProfile().then();
	}, []);
	return (
		profile && (
			<InputFile>
				<FormField label={format("practitioner.account.profile.saveSign")}>
					<CheckboxInput value={profile.saveSign} onChange={() => saveSign(profile)} />
					{profile.signature ? (
						<>
							{format("practitioner.account.profile.actualSign") + profile.nameSignature}
							<Signature src={`data:image/jpeg;base64,${profile?.signature}`} />
						</>
					) : (
						<>
						{format("practitioner.account.profile.actualSign")}
							<div style={{ color: `${colors.grey}` }}>
								&nbsp; aucune signature sélectionnée
							</div>
						</>
					)}
				</FormField>

				<FormField label={format("practitioner.account.profile.inputSign")}>
					<input type="file" name="input" onChange={selectFile} accept="image/png, image/jpeg" />
					<Marge>
						<Button
							disabled={!currentFile || !profile.saveSign}
							onClick={async () => {
								await practitionerService.storeSign(currentFile, nameFile);
								await practitionerService.fetchProfile().then();
							}}
						>
							Valider
						</Button>
					</Marge>
					<p style={{ clear: "both", marginLeft: "10px" }}>{format("signature.format")}</p>
				</FormField>
			</InputFile>
		)
	);
};

const InputFile = styled.div`
	${stack("M")};
`;

const Marge = styled.div`
	margin-left: 20px;
`;

const Signature = styled.img`
	height: 100px;
	max-width: 100%;
	padding-left: 10px;
`;
