import styled from "@emotion/styled";
import { colors } from "style";

export const Pagination = styled.div`
	.pagination {
		display: flex;
		justify-content: center;
		list-style-type: none;
		user-select: none;
		font-weight: 500;
		padding-left: 0;

		li {
			border-radius: 50px;
			margin: 5px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			color: ${colors.black};
			cursor: pointer;
			transition: background-color 0.2s linear;

			&.disabled {
				cursor: default;
				a {
					cursor: default;
				}
			}

			&.previous,
			&.next {
				a {
					position: relative;
					&::before {
						content: "";
						position: absolute;
						top: 25%;
						left: 25%;
						width: 50%;
						height: 50%;
						background: url("/icons/arrow-left.svg");
						background-size: contain;
						background-repeat: no-repeat;
						background-position: 50% 50%;
					}
				}
				&.disabled a {
					opacity: 0.33;
				}
			}
			&.next {
				transform: scaleX(-1);
			}
			a {
				display: block;
				text-decoration: none;
				border-radius: 50px;
				width: 30px;
				height: 30px;
				line-height: 26px;
				text-align: center;
				outline: none;
			}

			&:not(.selected):not(.break):not(.disabled):hover {
				background: #efefef;
				a {
					color: ${colors.black};
				}
			}
			&.selected {
				cursor: default;
				background: ${colors.pink};

				a {
					color: ${colors.white};
				}
			}
		}
	}
`;
