import React, { CSSProperties, useState } from "react";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip } from "recharts";
import { InfoIcon } from "@assets/icons/info.svg";
import styled from "@emotion/styled";
import { colors, desktop, sizes, stack } from "../../style";
import { createModal, ModalCardContainer, ModalCloseIcon } from "../../services/modal-service";
import { motion } from "framer-motion";

interface CustomizedTickProps {
	x: number;
	y: number;
	payload: { value: string };
}

interface ScoringRadarChartProps {
	scoringSophi: number | undefined;
	scoringDependency: number | undefined;
	scoringCicatrisation: number | undefined;
}

const TooltipModal: React.FC<{
	style?: CSSProperties;
	onClose: (result: null) => void;
	description: string;
	title: string;
}> = ({ onClose, description, title }) => {
	return (
		<Card onClick={e => e.stopPropagation()}>
			<ModalCloseIcon onClick={() => onClose(null)} />
			<Title>{title}</Title>
			<TooltipModalContent dangerouslySetInnerHTML={{ __html: description }} />
		</Card>
	);
};

const CustomTooltip = (props: any) => {
	const { active, payload } = props;
	if (active && payload && payload.length > 0) {
		return (
			<div style={{ backgroundColor: "white", padding: "10px", borderRadius: "5px" }}>
				<p style={{ color: colors.pink }}>{`Score ${payload[0].payload.subject}: ${payload[0].value}`}</p>
			</div>
		);
	}

	return null;
};

const CustomizedTick: React.FC<CustomizedTickProps> = ({ x, y, payload }) => {
	const [hover, setHover] = useState(false);
	let position = { x: 0, y: 0 };
	let modalText = "";
	let modalTitle = "";
	let positionIcon = { x: 0, y: 0 };
	switch (payload.value) {
		case "SOPHI":
			position = { x: 0, y: -20 };
			positionIcon = { x: 30, y: -5 };
			modalText =
				"Ce score évalue le risque de complication post soin. Axé sur le type de soin et le terrain, il constitue une indication et n'a pas pour objectif de ce substituer à l'évaluation du soignant.";
			modalTitle = "Score SOPHI";
			break;
		case "Dépendance":
			position = { x: 20, y: 0 };
			positionIcon = { x: 80, y: -5 };
			modalText = "Ce score évalue le risque de dégradation de la dépendance post soin.";
			modalTitle = "Score Dépendance";
			break;
		case "Cicatrisation":
			position = { x: -70, y: 0 };
			positionIcon = { x: -45, y: -5 };
			modalText = "Ce score évalue le risque de trouble de la cicatrisation post soin.";
			modalTitle = "Score Cicatrisation";
			break;
	}
	return (
		<g
			transform={`translate(${x + position.x}, ${y + position.y})`}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
			style={{ cursor: hover ? "pointer" : "default" }}
		>
			<text
				x={-20}
				y={10}
				fill="#666"
				onMouseOver={() => setHover(true)}
				onMouseOut={() => setHover(false)}
				style={{ cursor: hover ? "pointer" : "default" }}
				onClick={e => {
					e.stopPropagation();
					createModal(props => <TooltipModal {...props} description={modalText} title={modalTitle} />, {
						valueOnBackdropClick: null,
					});
				}}
			>
				{payload.value}
			</text>
			<svg
				x={positionIcon.x}
				y={positionIcon.y}
				width={18}
				height={18}
				onMouseOver={() => setHover(true)}
				onMouseOut={() => setHover(false)}
				style={{ cursor: hover ? "pointer" : "default" }}
				onClick={e => {
					e.stopPropagation();
					createModal(props => <TooltipModal {...props} description={modalText} title={modalTitle} />, {
						valueOnBackdropClick: null,
					});
				}}
			>
				<InfoIcon fill={hover ? "#000" : "#666"} />
			</svg>
		</g>
	);
};

export const ScoringRadarChart: React.FC<ScoringRadarChartProps> = props => {
	const { scoringSophi, scoringDependency, scoringCicatrisation } = props;

	const data = [
		{
			subject: "SOPHI",
			A: scoringSophi ? Math.round((scoringSophi * 10) / 20.5) : 0,
			fullMark: 10,
		},
		{
			subject: "Dépendance",
			A: scoringDependency ? Math.round((scoringDependency * 10) / 5) : 0,
			fullMark: 10,
		},
		{
			subject: "Cicatrisation",
			A: scoringCicatrisation ? Math.round((scoringCicatrisation * 10) / 8) : 0,
			fullMark: 10,
		},
	];

	return (
		<RadarChart outerRadius={90} width={500} height={250} data={data}>
			<Tooltip
				contentStyle={{ borderStyle: "unset", background: colors.lightGrey }}
				wrapperStyle={{ outline: "none", boxShadow: "0px 10px 8px 0 rgba(0, 0, 0, 0.08)" }}
				content={CustomTooltip}
			/>
			<PolarGrid />
			<PolarAngleAxis dataKey="subject" tick={props => <CustomizedTick {...props} />} />
			<PolarRadiusAxis angle={90} domain={[0, 10]} />
			<Radar name="Score patient" dataKey="A" stroke={colors.pink} fill={colors.pink} fillOpacity={0.6} />
			<Legend />
		</RadarChart>
	);
};

const TooltipModalContent = styled.div`
	padding: ${sizes.S};
`;

const Card = styled(ModalCardContainer)`
	padding: 63px 80px;
	${stack("M")};
	@media (${desktop}) {
		padding: ${sizes.S};
	}
`;
const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;
