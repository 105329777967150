import styled from "@emotion/styled";
import { AxiosError } from "axios";
import { Button } from "components/button";
import { UserType } from "domain/user-type";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ApiError } from "services/api";
import { authService } from "services/auth-service";
import { colors, desktop, row, stack } from "style";
import { useI18n } from "utils/i18n";
import { useQueryParams } from "utils/useQueryParams";
import { PasswordInput } from "components/inputs/text-input";

interface NewPasswordFormProps {
	userType: UserType;
}

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ userType }) => {
	const { format } = useI18n();
	const history = useHistory();
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [error, setError] = useState<AxiosError<ApiError> | "password-dont-match" | "no-email" | "no-token" | null>(
		null
	);
	const [state, setState] = useState(1);

	const queryParams = useQueryParams();
	const token = queryParams.get("token");
	const email = queryParams.get("email")?.replace(/\s/g, "+"); // Replace whitespace with "+" because as a query param get decoded as space
	const name = queryParams.get("name");

	const tutor = useCallback(
		async () => {
			if (token != null) {
				try {
					if (userType == UserType.Patient) {
						await authService.tutorCheckPassword(userType, token);
					}
				} catch (e: any) {
					console.log(e?.response);
					history.push("/404-link-tutor");
				}
			}

		}, [email, token]
	);

	useEffect(() => {
		if (state == 1){
			tutor().then()
		}
		setState(2);
	},[state, tutor]);

	const setNewPassword = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event?.preventDefault();
			if (password !== passwordConfirmation) {
				setError("password-dont-match");
				return;
			}
			if (!email) {
				setError("no-email");
				return;
			} else if (!token) {
				setError("no-token");
				return;
			}
			try {
				setError(null);
				await authService.setNewPassword(userType, password, token);
				await authService.login(userType, email, password);
				history.push(`/${userType}`);
			} catch (e: any) {
				console.log(e?.response);
				setError(e);
			}
		},
		[email, password, passwordConfirmation, history, token, userType]
	);

	return (
		<FormContainer>
			<Form onSubmit={setNewPassword}>
				<AppTitle>
					<img src="/images/logo.svg" alt="" />
					<div>SOPHI</div>
				</AppTitle>

				{name ? (
					<>
						<Title>{format("newPassword.newAccount.title", { name })}</Title>
						<Subtitle>{format("newPassword.newAccount.subtitle", { name })}</Subtitle>
					</>
				) : (
					<>
						<Title>{format("newPassword.title")}</Title>
						<Subtitle>{format("newPassword.subtitle")}</Subtitle>
					</>
				)}
				<ErrorWrapper>
					<Fields>
						<input type="text" value={email ?? ""} readOnly autoComplete="username" style={{ display: "none" }} />
						<PasswordInput
							autoFocus
							placeholder={format("newPassword.passwordPlaceholder")}
							value={password}
							onChange={e => setPassword(e.target.value)}
							autoComplete="new-password"
						/>
						<PasswordInput
							placeholder={format("newPassword.passwordConfirmationPlaceholder")}
							value={passwordConfirmation}
							onChange={e => setPasswordConfirmation(e.target.value)}
							autoComplete="new-password"
						/>
					</Fields>
					{error && (
						<ErrorMessage>
							{error === "password-dont-match"
								? format("newPassword.error.passwordDontMatch")
								: format("newPassword.error.unknownError")}
						</ErrorMessage>
					)}
				</ErrorWrapper>
				<Button>{format("newPassword.submit")}</Button>
			</Form>
		</FormContainer>
	);
};

const AppTitle = styled.div`
	${row("S", "flex-start", "center")}
	font-size: 27px;
	font-weight: bold;
	color: ${colors.black};

	div {
		position: relative;
		top: -3px;
	}

	img {
		width: 29px;
		height: 25px;
	}
`;

const Title = styled.h1`
	font-size: 27px;
	max-width: 250px;
`;
const Subtitle = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;
const FormContainer = styled.div`
	${row(0, "center", "center")};

	@media ${desktop} {
		width: calc(50vw - 100px);
	}
`;
const Form = styled.form`
	${stack("XL")};
`;
const Fields = styled.div`
	${stack("M", "flex-start", "stretch")};
`;

const ErrorMessage = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;

const ErrorWrapper = styled.div`
	${stack("S")};
	max-width: 250px;
`;
