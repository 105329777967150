export function fullName(
	profile:
		| {
				firstName: string | undefined;
				lastName: string | undefined;
		  }
		| undefined
		| null
): string {
	return `${profile?.lastName ?? ""} ${profile?.firstName ?? ""}`;
}
export function fullNameHPU(
	profile:
		| {
				hpuFirstName: string | undefined;
				hpuLastName: string | undefined;
		  }
		| undefined
		| null
): string {
	return `${profile?.hpuLastName ?? ""} ${profile?.hpuFirstName ?? ""}`;
}

export interface CredentialsUpdate {
	currentPassword: string | undefined;
	newPassword: string | undefined;
	newPasswordConfirmation: string | undefined;
}
export interface booleanClick {
	isClicked: boolean;
}
