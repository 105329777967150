export const colors = {
	black: "#252525",
	pink: "#f45c73",
	grey: "#7c8f8e",
	red: "#ed0024",
	green: "#00bfba",
	orange: "#ff9d3f",
	pink2: "#ff6f7a",
	blue2: "#509fee",
	white: "#ffffff",
	orange2: "#ff9d3f",
	grey2: "#9b9b9b",
	lightGrey: "#E8E8E8",
	placeholder: "#8ebdbf",
	orangeLight: "#ffdabb",
	green700: "#12c99b",
};

export const cardsColorsArray = [
	"#dfffc3",
	"#d1fffd",
	"#fffabc",
	"#eecbfd",
	"#ffc98e",
	"#ECFFB4",
	"#ABFFF6",
	"#FFABBE",
];

export const cardsColorsPSADArray = [
	"#b5d6d6",
	"#d1cfe2",
	"#f2f5ff",
	"#7ec4cf",
	"#52b2cf",
	"#eac9c1",
	"#ff8fab",
	"#a5ffd6",
];

export const gammeColors = {
	orange: "#e3a429",
};

export const tagColors = ["#07dad8", "#45D7FF", "#8487FF", "#DE9FFF", "#FF9FD8", "#47cc3e", "#2BC2BC"];
