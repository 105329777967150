import { Patient } from "domain/patient";
import { useComputedObservable, useObservable, WritableObservable } from "micro-observables";
import { api } from "./api";
import { UserProfile } from "../domain/user-profile";
import { CredentialsUpdate } from "../domain/utils";
import { Rectangle, SupportTechnical } from "../domain/support-technical";
import { Hospital, Practitioner } from "../domain/hpu";
import { useUser } from "./auth-service";
import { useCallback, useEffect, useState } from "react";
import { hpuService } from "./hpu-service";
import { CCAM } from "../domain/surgery";
import { AxiosResponse } from "axios";

export class SupportTechnicalService {
	profile = new WritableObservable<SupportTechnical | null>(null);
	public supportTechnical = new WritableObservable<SupportTechnical | null>(null);
	public supportTechnicalCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);
	practitionersSettings = new WritableObservable<Practitioner[]>([]);
	ccams = new WritableObservable<CCAM[]>([]);
	hospitals = new WritableObservable<Hospital[]>([]);

	async fetchProfile(): Promise<SupportTechnical> {
		const profile = await api.get<SupportTechnical>("/support-technical/me");
		this.profile.update(() => profile.data);
		return profile.data;
	}
	async assignUserProfile(userProfile: UserProfile | null) {
		const result = await api.post("/support-technical/userprofile", userProfile);
		this.supportTechnical.set(result.data);
		return result.data;
	}
	async searchByLastNamePatient(firstName: string|null, lastName: string|null): Promise<Patient[]> {
		const newPatients = await api.post<Patient[]>("/support-technical/patients", null, {
			params: { firstName: firstName, lastName: lastName},
		});
		return newPatients.data;
	}
	async updatePassword(
		supportCredentialCredentials: CredentialsUpdate | null,
		patch: Partial<Omit<CredentialsUpdate, "assign">>
	) {
		const newCredentials = await api.post<CredentialsUpdate>("/support-technical/password-update", {
			newPassword: supportCredentialCredentials?.newPassword,
			newPasswordConfirmation: supportCredentialCredentials?.newPasswordConfirmation,
			currentPassword: supportCredentialCredentials?.currentPassword,
			...patch,
		});
		this.supportTechnicalCredentials.update(item => (item ? newCredentials.data : null));
	}
	async generateDocument(rectangles: Rectangle[]) {
	return await api.post("/support-technical/docCustom", rectangles)
	}
	async fetchListPractitioners(): Promise<Practitioner[]>{
		const listPractitioners = await api.get<Practitioner[]>("/support-technical/list-practitioners");
		this.practitionersSettings.update(() => listPractitioners.data);
		return listPractitioners.data
	}
	async fetchCCAMS(): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/support-technical/ccam`);
		this.ccams.update(() => ccamData.data);
		return ccamData.data;
	}
	async fetchHospitals(): Promise<Hospital[]> {
		const hospitals = await api.get<Hospital[]>("/support-technical/hospitals");
		this.hospitals.set(hospitals.data);
		return hospitals.data;
	}
	async postDocCustom(formData: FormData):Promise<AxiosResponse>{
		return await api.post(`/support-technical/addDocCustom`, formData)
	}
}

export const supportTechnicalService = new SupportTechnicalService();

export function useSupportTechicalProfile() {
	return useObservable(supportTechnicalService.profile);
}
export function useHospitals(): { hospitals: Hospital[] } {
	const user = useUser();
	const hospitals = useComputedObservable(() => supportTechnicalService.hospitals.get(), []);
	const fetchHospitals = useCallback(() => {
		try {
			if (user) {
				supportTechnicalService.fetchHospitals();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchHospitals();
	}, [fetchHospitals]);
	return { hospitals };
}

export function useCCAMDatabase(): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => supportTechnicalService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user) {
				await supportTechnicalService.fetchCCAMS();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}

export function usePractitionersForSettings() : {loading: boolean, practitioners: Practitioner[] | undefined;} {
	const user = useUser();
	const [loading, setLoading] = useState(true);
	const practitioners = useComputedObservable(() => supportTechnicalService.practitionersSettings.get(), [user]);
	const fetchPractitioners = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				await supportTechnicalService.fetchListPractitioners();
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);
	useEffect(() => {
		fetchPractitioners();
	}, [fetchPractitioners]);
	return {
		loading,
		practitioners,
	};
}