export function diffrenceInYears(oldDate: Date, newDate: Date): number {
	const yNew = newDate.getFullYear();
	const mNew = newDate.getMonth();
	const dNew = newDate.getDate();
	const yOld = oldDate.getFullYear();
	const mOld = oldDate.getMonth();
	const dOld = oldDate.getDate();
	const diff = yNew - yOld;
	return mOld > mNew || (mOld === mNew && dOld > dNew) ? diff - 1 : diff;
}

export function normalizeDate(date: string) {
	const match = date.match(/(\d{0,2})\/(\d{0,2})\/(\d{0,4})/);
	if (match) {
		return `${match[3]}-${match[2]}-${match[1]}`;
	} else {
		return date;
	}
}

export function denormalizeDate(date: string) {
	const match = date.match(/(\d{0,4})-(\d{0,2})-(\d{0,2})/);
	if (match) {
		return `${match[3]}/${match[2]}/${match[1]}`;
	} else {
		return date;
	}
}

export function isDateSameDay(date1: Date, date2: Date): boolean {
	return (
		date1.getDate() === date2.getDate() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getFullYear() === date2.getFullYear()
	);
}

export function splitDate(date: Date): { shortDate: string; time: string } {
	return {
		shortDate: date.toISOString().split("T")[0],
		time: `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`,
	};
}

export function composeDate(date: { shortDate: string; time: string }): Date {
	const match = date.shortDate.match(/(\d{2})\/(\d{2})\/(\d{4})/);
	if (match) {
		date.shortDate = `${match[3]}-${match[2]}-${match[1]}`;
	}
	const newDate = new Date(date.shortDate);
	const [hours, minutes] = date.time.split(":");
	newDate.setHours(+hours);
	newDate.setMinutes(+minutes);
	return newDate;
}

export function isDateInputSupported() {
	const input = document.createElement("input");
	const value = "a";
	input.setAttribute("type", "date");
	input.setAttribute("value", value);
	return input.value !== value;
}

export function getCurrentDate(): string {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, "0");
	const day = String(today.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}

export function getCurrentDateForExport(): string {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, "0");
	const day = String(today.getDate()).padStart(2, "0");
	const hour = String(today.getHours()).padStart(2, "0");
	const min = String(today.getMinutes()).padStart(2, "0");
	const sec = String(today.getSeconds()).padStart(2, "0");
	return `${year}-${month}-${day}-${hour}${min}${sec}`;
}
