import { Tokens } from "./auth-service";

export const loginSuccess = (userType: string, tokens: Tokens) => ({
	type: "LOGIN_SUCCESS" as const,
	payload: { userType, tokens },
});

export const logoutSuccess = () => ({
	type: "LOGOUT_SUCCESS" as const,
});
