import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { fullName } from "domain/utils";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { practitionerService, usePractitionerProfile } from "services/practitioner-service";
import { colors, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { AccountSelector } from "./account-selector";
import { UserType } from "../../domain/user-type";
import { Button } from "../button";
import QuestionIcon from "@assets/icons/question.svg";
import { createModal } from "../../services/modal-service";
import { IdeaModal } from "../modals/idea-modal";
import { Location } from "history";
import { Practitioner } from "../../domain/surgery";

export const PractitionerNavBar: React.FC<{
	className?: string;
	counter?: number | null;
	location?: Location | null;
	practitioner?: Practitioner | null;
}> = ({ className, counter, location: locationProp, practitioner }) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const location = locationProp || useLocation(); // This should not cause ESLint error
	const { format } = useI18n();
	const profile = usePractitionerProfile();
	const [bounce, setBounces] = useState<boolean>(
		!(
			!!location.pathname.match(`/practitioner/dashboard-follow-ups`) ||
			!!location.pathname.match(
				/^\/practitioner\/[a-zA-Z0-9]+\/patient\/[a-zA-Z0-9]+\/surgery\/[a-zA-Z0-9]+\/forensic-healthcare-provider-documents+$/i
			)
		)
	);
	useEffect(() => {
		practitionerService.fetchProfile().then();
	}, []);

	return (
		<>
			<Container className={className}>
				<LogoContainer>
					<LogoSophi src="/images/logo.svg" />
					<span>{format("practitioner.header.logo")}</span>
				</LogoContainer>
				<Spacer />
				<Tab
					to="/practitioner/dashboard-patients"
					selected={
						!!location.pathname.match("/practitioner/dashboard-patients$") ||
						!!location.pathname.match("/practitioner/patient/[a-zA-Z0-9]+$") ||
						!!location.pathname.match("/practitioner/patient/[a-zA-Z0-9]/surgery/create")
					}
				>
					{format("practitioner.header.patients")}
				</Tab>
				<Tab
					to="/practitioner/dashboard-surgeries"
					selected={
						!!location.pathname.match("/practitioner/dashboard-surgeries$") ||
						!!location.pathname.match(/^\/practitioner\/patient\/[a-zA-Z0-9]+\/surgery\/(?!.*healthcare).*$/i)
					}
				>
					{practitioner?.evaluation || profile?.evaluation ? format("practitioner.header.folders") : format("practitioner.header.surgeries")}
				</Tab>
				{
					!profile?.evaluation && <Tab
						to="/practitioner/dashboard-follow-ups"
						selected={
							!!location.pathname.match("/practitioner/dashboard-follow-ups") ||
							!!location.pathname.match(`/practitioner/hpu-archived`) ||
							!!location.pathname.match(
								"/practitioner/patient/[a-zA-Z0-9]+/surgery/[a-zA-Z0-9]+/*forensic-healthcare-provider-documents*"
							)
						}
						onClick={() => {
							// afficher/masquer la notification pour l'onglet suivi
							setBounces(false);
							practitionerService.clickSuivi().then();
						}}
					>
						{format("practitioner.header.follow.ups")}
						{
							// Pour afficher la notification, il y a 2 criteres : practitioner.displayNotif & il faut des ordonnances PSAD à signer
							typeof counter == "number" && counter > 0 && profile?.displayNotifSuivi ? (
							<CounterPractitioner fontSize={counter && counter > 99 ? 14 : 16} bounce={bounce}>
								{counter && counter > 99 ? "99+" : counter}
							</CounterPractitioner>
						) : null}
					</Tab>
				}
				<Spacer />
				<AccountSelector name={fullName(profile)} withAccount accountType={UserType.Practitioner} />
			</Container>
			<div
				onClick={() => {
					createModal(({ onClose }) => <IdeaModal onClose={onClose} profile={profile} />);
				}}
			>
				<ButtonSupport>
					<Bulb />
				</ButtonSupport>
				<ToolTipIdeas>{format("ideas.box.button.tooltip")}</ToolTipIdeas>
			</div>
		</>
	);
};

export const ToolTipIdeas = styled.div`
	position: fixed;
	width: 200px;
	color: ${colors.black};
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	font-size: 12px;
	font-weight: bold;
	/* Position the tooltip */
	z-index: 99;
	right: 0;
	bottom: 0;
	margin-bottom: 50px;
	margin-right: 5px;
`;

const Container = styled.div`
	${row("L", "flex-start", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "center", "center")}
	}
`;

const LogoContainer = styled.div`
	${row(5, "flex-start", "center")}
	color: ${colors.black};
`;

const LogoSophi = styled.img`
	width: 29px;
`;

const Tab = styled(Link)<{ selected: boolean }>`
	display: block;
	position: relative;
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;

	${({ selected }) =>
		selected &&
		css`
			opacity: 1;
			:after {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 6px;
				width: 6px;
				border-radius: 3px;
				background-color: ${colors.pink};

				@media ${mobile} {
					left: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		`}
`;

export const ButtonSupport = styled(Button)`
	position: fixed;
	right: 0;
	bottom: 0;
	margin-right: 80px;
	margin-bottom: 80px;
	border-radius: 80px;
	width: 52px;
	height: 52px;
	z-index: 3;
	&:hover ${ToolTipIdeas} {
		visibility: visible;
		display: block;
	}
`;

export const Bulb = styled(QuestionIcon)`
	width: 42px;
	height: 42px;
	position: relative;
	top: 2px;
	path {
		fill: ${colors.white};
	}
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;
export const Counter = styled.div<{ fontSize: number; bounce: boolean }>`
	color: white;
	font-size: ${props => props.fontSize}px;
	position: absolute;
	opacity: 0.8;
	bottom: 5px;
	${props =>
		props.bounce
			? css`
					left: 44px;
			  `
			: css`
					left: 58px;
			  `};
	transform: translateX(-50%);
	text-align: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background-color: ${colors.pink};
	animation: ${props =>
		props.bounce
			? css`
					${bounceAnimation} 1s infinite;
			  `
			: "none"};
`;
const CounterPractitioner = styled(Counter)`
	left: 86px;
`;
