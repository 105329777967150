import { loginSuccess, logoutSuccess } from "./auth-actions";
import store from "./store";
import { UserType } from "domain/user-type";
import decodeJWT from "jwt-decode";
import { useObservable, WritableObservable } from "micro-observables";
import { api } from "./api";

export interface Tokens {
	accessToken: string;
	refreshToken: string;
}

class Store {
	constructor(private key: string) {}

	save(value: string) {
		return localStorage.setItem(this.key, value);
	}

	get() {
		return localStorage.getItem(this.key);
	}
}

interface JWT {
	aud: string;
	exp: number;
	sub: string;
}

interface User {
	type: string;
	tokens: Tokens;
	usurp: string|null;
}

export class AuthService {
	public user = new WritableObservable<User | null>(null);
	private accessTokenStore: Store = new Store("access-token");
	private refreshTokenStore: Store = new Store("refresh-token");
	private userTypeStore: Store = new Store("user-type");
	public usurpStore: Store = new Store("usurp");

	constructor() {
		const accessToken = this.accessTokenStore.get();
		const refreshToken = this.refreshTokenStore.get();
		const userType = this.userTypeStore.get();
		const usurp = this.usurpStore.get();
		if (accessToken && refreshToken && userType) {
			this.user.set({ type: userType as UserType, tokens: { accessToken, refreshToken }, usurp: usurp });
		}

		api.interceptors.request.use(
			async config => {
				if (config.url?.endsWith("login-refresh")) {
					return config;
				}

				let user = this.user.get();
				if (user) {
					const token = decodeJWT(user.tokens.accessToken) as JWT;
					if (token.exp <= Date.now() / 1000) {
						try {
							const response = await api.post<Tokens>(`${token.aud}/login-refresh`, null, {
								params: { refresh: user.tokens.refreshToken },
							});
							this.accessTokenStore.save(response.data.accessToken);
							this.refreshTokenStore.save(response.data.refreshToken);

							let usurpMark = null
							if (token.aud == "support-technical") {
								usurpMark = "true"
								this.usurpStore.save(usurpMark);
							} else {
								this.usurpStore.save("");
							}

							this.user.set({ type: user.type, tokens: response.data, usurp: usurpMark });
							user = { type: user.type, tokens: response.data, usurp: usurpMark };
							store.dispatch(loginSuccess(user.type, response.data));
						} catch {
							// Si le token refresh est lui aussi expiré, alors on déconnecte l'utilisateur
							store.dispatch(logoutSuccess());
							authService
								.logout()
								.then(() => window.location.reload())
								.catch(e => e.message);
						}
					}
					config.headers["Authorization"] = `Bearer ${user.tokens.accessToken}`;
				}
				return config;
			},
			error => Promise.reject(error)
		);
	}

	async login(loginType: string, email: string, password: string) {
		const response = await api.post<Tokens>(`${loginType}/login`, null, {
			params: { login: email, password },
		});
		store.dispatch(loginSuccess(loginType, response.data));
		if (loginType != "support-technical" && this.usurpStore.get() != "true") {
			this.usurpStore.save("");
		}
		store.dispatch(loginSuccess(loginType, response.data));
		this.accessTokenStore.save(response.data.accessToken);
		this.refreshTokenStore.save(response.data.refreshToken);
		this.userTypeStore.save(loginType as string);
		this.user.set({ type: loginType, tokens: response.data, usurp: null });
	}

	async logout() {
		this.accessTokenStore.save("");
		this.refreshTokenStore.save("");
		let usurp: string;
		if (this.userTypeStore.get() == "support-technical" || this.usurpStore.get() == "true") {
			usurp = "true"
			this.usurpStore.save(usurp);
		} else {
			usurp = ""
			this.usurpStore.save(usurp);
		}
		this.userTypeStore.save("");
		this.user.set(null);
		store.dispatch(logoutSuccess());
		return usurp;
	}

	async recoverPassword(userType: string, email: string) {
		await api.post(`${userType}/password-reset`, null, { params: { email } });
	}

	async setNewPassword(userType: string, password: string, token: string) {
		await api.post(`${userType}/password-reset`, null, { params: { password, token } });
	}

	async tutorCheckPassword(userType: string, token: string) {
		await api.post(`${userType}/password-reset/tutor`, null, { params: { token } });
	}

	async usurp(loginType: string, patientId: string) {
		const response = await api.post<Tokens>(`support-technical/usurpation/${loginType}`, null, {
			params: { patientId: patientId },
		});
		// await authService.logout();
		this.usurpStore.save("true")
		store.dispatch(loginSuccess(loginType, response.data));
		this.accessTokenStore.save(response.data.accessToken);
		this.refreshTokenStore.save(response.data.refreshToken);
		this.userTypeStore.save(loginType as string);
		this.user.set({ type: loginType, tokens: response.data, usurp: "true" });
	}
}

export const authService = new AuthService();
export const useUser = () => useObservable(authService.user);
