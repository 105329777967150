import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "babel-polyfill";
import store from "../src/services/store"
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

Sentry.init({
	enabled: process.env.NODE_ENV === "production",
	dsn: "https://d5c9f6697db34333aa34b27694ca4fc7@o531037.ingest.sentry.io/5651222",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
	environment: process.env.SENTRY_ENV,
	release: process.env.PACKAGE_VERSION,
});

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("react-root")
);