import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "../../components/button";
import { supportTechnicalService } from "../../services/support-technical-service";
import { useRequireAuth } from "../../utils/navigation";
import { UserType } from "../../domain/user-type";
import { Rectangle } from "../../domain/support-technical";
import { colors, desktop, mobile, row, sizes, stack } from "../../style";
import { Helmet } from "react-helmet";
import { SupportTechnicalNavBar } from "../../components/navigation/support-technical-nav-bar";
import { motion } from "framer-motion";
import { useI18n } from "../../utils/i18n";
import { SelectInput } from "../../components/inputs/select-input";
import { AlertComponent } from "../../components/notifications/alertComponent";
import { stubArray } from "lodash";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export enum PdfCustomField {
	lastNamePatient = "lastNamePatient",
	firstNamePatient = "firstNamePatient",
	birthDatePatient = "birthDatePatient",
	lastNamePractitioner = "lastNamePractitioner",
	firstNamePractitioner = "firstNamePractitioner",
	rppsCode = "rppsCode",
	prescriptionDate = "prescriptionDate",
	startDate = "startDate",
	classification_code = "classification_code",
	creation_date = "creation_date",
	checkBox = "checkBox"
}
const PdfEditor: React.FC = () => {
	useRequireAuth(UserType.Support);
	const { format, formatPdfField } = useI18n();
	const [rectangles, setRectangles] = useState<Rectangle[]>([]);
	const [selectedFieldName, setSelectedFieldName] = useState<string>("");
	const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
	const [currentFile, setCurrentFile] = useState<string>();
	const [currentFileName, setCurrentFileName] = useState<string>("");
	const [numPages, setNumPages] = useState<number>(0);
	const [selectedRectangle, setSelectedRectangle] = useState<Rectangle | null>(null);
	const [selectedRectangleWidth, setSelectedRectangleWidth] = useState<number>(100);
	const [selectedRectangleHeight, setSelectedRectangleHeight] = useState<number>(15);
	const [isEditingRectangle, setIsEditingRectangle] = useState<boolean>(false);
	const [zoomLevel, setZoomLevel] = useState<number>(1.2);
	const [fileError, setFileError] = useState<boolean>(false)
	const [selectedFilesNames, setSelectedFilesNames] = useState<string[]>([]);
	const [files, setFiles] = useState<File[]>([]);
	const [fileIndex, setFileIndex] = useState<number>(0);
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [state, setState] = useState<string>("success");
	const [message, setMessage] = useState<string>("");

	const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
	};

	const adjustCoordinatesForZoom = (rectangles: Rectangle[], zoomLevel: number): Rectangle[] => {
		return rectangles.filter((rectangle) => rectangle.fileName === currentFileName).map((rectangle) => {
			const adjustedX = rectangle.x / zoomLevel;
			const adjustedY = rectangle.y / zoomLevel;
			const adjustedWidth = rectangle.width / zoomLevel;
			const adjustedHeight = rectangle.height / zoomLevel;
			return { ...rectangle, x: adjustedX, y: adjustedY, width: adjustedWidth, height: adjustedHeight };
		});
	};

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const container = document.getElementById("pdf-container");
		if (!container || !selectedFieldName) return;

		const rect = container.getBoundingClientRect();
		const pdfHeight = rect.bottom - rect.top;
		const scaleX = container.clientWidth / container.offsetWidth;
		const scaleY = pdfHeight / container.offsetHeight;
		const x = (event.clientX - rect.left) / scaleX;
		const y = (pdfHeight - (event.clientY - rect.top)) / scaleY;

		const clickedRectangle = rectangles.find(
			(rectangle) =>
				x >= rectangle.x &&
				x <= rectangle.x + rectangle.width &&
				y >= rectangle.y &&
				y <= rectangle.y + rectangle.height &&
				rectangle.pageNumber === currentPageNumber
		);

		if (clickedRectangle) {
			setSelectedRectangle(clickedRectangle);
			setSelectedRectangleWidth(clickedRectangle.width);
			setSelectedRectangleHeight(clickedRectangle.height);
			setIsEditingRectangle(true);
		} else {
			const id = (Math.random() * 10 + Date.now()).toString(); // Generating unique id
			setRectangles([
				...rectangles,
				{
					id,
					x,
					y,
					width: selectedFieldName == PdfCustomField.checkBox ? 10 : 100,
					height: selectedFieldName == PdfCustomField.checkBox ? 10 : 15,
					fieldName: selectedFieldName,
					pageNumber: currentPageNumber,
					file: currentFile,
					fileName: currentFileName
				},
			]);
			setSelectedRectangle(null);
			setIsEditingRectangle(false);
		}
	};

	const handleRemoveRectangle = (rectangleToRemove: Rectangle) => {
		const updatedRectangles = rectangles.filter(
			(rectangle) =>
				!(rectangle.x === rectangleToRemove.x && rectangle.y === rectangleToRemove.y && rectangle.pageNumber === rectangleToRemove.pageNumber)
		);
		setRectangles(updatedRectangles);
		setSelectedRectangle(null);
		setIsEditingRectangle(false);
	};

	const renderRectangleList = () => {
		return (
			<div>
				<div>Liste des éléments de la page {currentPageNumber} / {numPages}</div>
				{rectangles
					.filter((rectangle) => rectangle.pageNumber === currentPageNumber && rectangle.fileName == currentFileName)
					.map((rectangle) => {
						const isSelectedRectangle = selectedRectangle && selectedRectangle.id === rectangle.id;
						return (
							<Stack key={rectangle.id}>
								<RowList>
									<RowList>
										<StyledFieldName onClick={() => setSelectedRectangle(rectangle)}>
											{rectangle.fieldName}
										</StyledFieldName>
											{!isSelectedRectangle && (
												<Button onClick={() => handleRemoveRectangle(rectangle)}>Supprimer</Button>
											)}
									</RowList>
									{isSelectedRectangle && isEditingRectangle && (
										<Stack>
											<div>
												<label>Largeur:</label>
												<StyledInput
													type="number"
													value={selectedRectangleWidth}
													onChange={(e) => {
														const newWidth = parseInt(e.target.value);
														setSelectedRectangleWidth(newWidth);
														if (selectedRectangle) {
															const updatedRectangles = rectangles.map((rect) =>
																rect.id === rectangle.id ? { ...rect, width: newWidth } : rect
															);
															setRectangles(updatedRectangles);
														}
													}}
												/>
											</div>
											<div>
												<label>Hauteur:</label>
												<StyledInput
													type="number"
													value={selectedRectangleHeight}
													onChange={(e) => {
														const newHeight = parseInt(e.target.value);
														setSelectedRectangleHeight(newHeight);
														if (selectedRectangle) {
															const updatedRectangles = rectangles.map((rect) =>
																rect.id === rectangle.id ? { ...rect, height: newHeight } : rect
															);
															setRectangles(updatedRectangles);
														}
													}}
												/>
											</div>
										</Stack>
									)}
								</RowList>

							</Stack>
						);
					})}
			</div>
		);
	};

	const handleRectangleMove = (newX: number, newY: number) => {
		if (selectedRectangle) {
			const updatedRectangles = rectangles.map((rectangle) =>
				rectangle === selectedRectangle ? { ...rectangle, x: newX, y: newY } : rectangle
			);
			setRectangles(updatedRectangles);
		}
	};

	const renderRectangles = () => {
		return rectangles
			.filter((rectangle) => rectangle.pageNumber === currentPageNumber && rectangle.fileName === currentFileName)
			.map((rectangle) => (
				<RectangleStyled
					key={rectangle.id}
					style={{
						left: rectangle.x,
						bottom: rectangle.y,
						width: rectangle.fieldName == PdfCustomField.checkBox ? 20 : rectangle.width,
						height: rectangle.fieldName == PdfCustomField.checkBox ? 20 : rectangle.height,
					}}
					onMouseDown={(e) => handleRectangleMouseDown(e, rectangle)}
					isSelected={selectedRectangle === rectangle}
				>
					<FieldName>{rectangle.fieldName}</FieldName>
				</RectangleStyled>
			));
	};
	const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFiles: File[] = [];
			Array.from(e.target.files).forEach((file, index) => {
				newFiles[index] = file;
			});
			setFileError(false);
			setFiles(prevFiles => ({ ...prevFiles, ...newFiles }));
			setSelectedFilesNames(newFiles.map(file => file.name)); // Mettre à jour la liste des noms de fichiers sélectionnés
			const reader = new FileReader()
			reader.readAsDataURL(newFiles[0])
			reader.onload = () => {
				const result = reader.result as string;
				setCurrentFile(result.split(",")[1])
				setCurrentFileName(newFiles[0].name)
			}
		}
	}, []);
	const base64ToBlob = (base64: string, type: string) => {
		const binaryString = window.atob(base64);
		const length = binaryString.length;
		const bytes = new Uint8Array(length);
		for (let i = 0; i < length; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return new Blob([bytes], { type });
	};

	const handleRectangleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, rectangle: Rectangle) => {
		event.preventDefault();
		setSelectedRectangle(rectangle);
		setSelectedRectangleWidth(rectangle.width);
		setSelectedRectangleHeight(rectangle.height);
		setIsEditingRectangle(true);
		const container = document.getElementById("pdf-container");
		if (!container) return;

		const initialX = event.clientX;
		const initialY = event.clientY;

		const handleMouseMove = (event: MouseEvent) => {
			const mouseX = event.clientX - initialX;
			const mouseY = event.clientY - initialY;

			const containerRect = container.getBoundingClientRect();
			const pdfHeight = containerRect.bottom - containerRect.top;

			const scaleX = container.clientWidth / container.offsetWidth;
			const scaleY = pdfHeight / container.offsetHeight;

			let newX = rectangle.x + mouseX / scaleX;
			let newY = rectangle.y - mouseY / scaleY;

			if (newX < 0) newX = 0;
			if (newX + rectangle.width > container.offsetWidth) {
				newX = container.offsetWidth - rectangle.width;
			}
			if (newY < 0) newY = 0;
			if (newY + rectangle.height > pdfHeight) {
				newY = pdfHeight - rectangle.height;
			}

			handleRectangleMove(newX, newY);
		};

		const handleMouseUp = () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		};

		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("mouseup", handleMouseUp);
	};

	const goToNextDocument = (files: File[]) => {
		setFileIndex(fileIndex + 1);
		const reader = new FileReader()
		reader.readAsDataURL(files[fileIndex + 1])
		reader.onload = () => {
			const result = reader.result as string;
			setCurrentFile(result.split(",")[1])
			setCurrentFileName(files[fileIndex + 1].name)
		}
		setCurrentPageNumber(1);
		setMessage("")
		setState("success")
		setShowNotification(false)
	};
	const goToPreviousDocument = (files: File[]) => {
		setFileIndex(fileIndex - 1);
		const reader = new FileReader()
		reader.readAsDataURL(files[fileIndex - 1])
		reader.onload = () => {
			const result = reader.result as string;
			setCurrentFile(result.split(",")[1]);
			setCurrentFileName(files[fileIndex - 1].name);
		}
		setCurrentPageNumber(1);
		setZoomLevel(1.2);
		setMessage("")
		setState("success")
		setShowNotification(false)
	};

	const goToNextPage = () => {
		setCurrentPageNumber(currentPageNumber + 1);
	};

	const goToPreviousPage = () => {
		setCurrentPageNumber(currentPageNumber - 1);
	};

	const handleZoomIn = () => {
		setZoomLevel(zoomLevel + 0.1);
	};

	const handleZoomOut = () => {
		setZoomLevel(zoomLevel - 0.1);
	};

	return (
		<Pages>
			<Helmet>
				<title>{format("patient.dashboard.pageTitle")}</title>
			</Helmet>
			<Sections>
				<SupportTechnicalNavBar />
				<Section>
					<Header>
						<Title>{format("supportTechnical.pdf.custom.title")}</Title>
						<SubtitleImportant>{format("supportTechnical.pdf.custom.info")}</SubtitleImportant>
						<Subtitle>{format("supportTechnical.pdf.custom.zoom")}</Subtitle>
						{showNotification && 	<AlertComponent
							openAlertBox={true}
							state={state}
							message={message}
						/>}
					</Header>
				</Section>
				<Stack>
					<FileInputLabel htmlFor="fileInput" hasError={fileError}>
						{selectedFilesNames.length > 0 ? (
							<ul>
								{selectedFilesNames.map((fileName, index) => (
									<li key={index}>{fileName}</li>
								))}
							</ul>
						) : (
							"Sélectionner des fichiers ici"
						)}
					</FileInputLabel>
					<FileInput id="fileInput" multiple type="file" onChange={handleFileChange} accept=".pdf, .html" style={{display: "none"}} />
						{selectedFilesNames.length > 0 ? <Row>
							<PdfEditorWrapper>
								<div id="pdf-container" onMouseDown={handleMouseDown}>
									<Document file={files[fileIndex]} onLoadSuccess={handleDocumentLoadSuccess}>
										<Page
											pageNumber={currentPageNumber}
											renderTextLayer={false}
											renderAnnotationLayer={false}
											scale={zoomLevel}
										/>
									</Document>
									{renderRectangles()}
								</div>
							</PdfEditorWrapper>
							<Stack>
								<RowList>
									{currentPageNumber > 1 && <Button secondary onClick={goToPreviousPage}>Page précédente</Button>}
									{currentPageNumber < numPages && <Button onClick={goToNextPage}>Page suivante</Button>}
									{currentPageNumber == numPages &&
										<>
											<Button onClick={async () => {
												const adjustRectangles = adjustCoordinatesForZoom(rectangles, zoomLevel)
												const response = await supportTechnicalService.generateDocument(adjustRectangles)
												if (response.status == 200) {
													setMessage("Génération du document OK")
													setState("success")
													setShowNotification(true)
													const newFileBase64 = response.data;
													const blob = base64ToBlob(newFileBase64, 'application/pdf');
													const url = URL.createObjectURL(blob);
													const link = document.createElement('a');
													link.href = url;
													link.setAttribute('download', `${currentFileName}`);
													document.body.appendChild(link);
													link.click();
													document.body.removeChild(link);
												}
											}}>Générer</Button>
											{selectedFilesNames.length > 0 && fileIndex < selectedFieldName.length -1 &&
												<Button onClick={() => goToNextDocument(files)}>
												Document suivant
											</Button>}
											{selectedFilesNames.length > 0 && fileIndex <= selectedFieldName.length-1 && fileIndex != 0 &&
												<Button onClick={() => goToPreviousDocument(files)}>
													Document précédent
												</Button>}
										</>

									}
									<div>
										<ZoomIn src={"/icons/magnifying-glass-plus.svg"} onClick={handleZoomIn} alt={"zoomIn"} />
										<ZoomOut src={"/icons/magnifying-glass-minus.svg"} onClick={handleZoomOut} alt={"zoomOut"} />
									</div>
								</RowList>
								<div>
									<SelectInput<string>
										innerId="side"
										value={selectedFieldName}
										onChange={
											e => {
												setSelectedFieldName(e)
											}}
										placeholder={"Sélectionner un champs"}
										options={[
											PdfCustomField.lastNamePatient,
											PdfCustomField.firstNamePatient,
											PdfCustomField.birthDatePatient,
											PdfCustomField.lastNamePractitioner,
											PdfCustomField.firstNamePractitioner,
											PdfCustomField.rppsCode,
											PdfCustomField.prescriptionDate,
											PdfCustomField.startDate,
											PdfCustomField.classification_code,
											PdfCustomField.creation_date,
											PdfCustomField.checkBox
										]}
										itemRenderer={formatPdfField}
										required
									/>
								</div>
								<div>
									{renderRectangleList()}
								</div>
							</Stack>
						</Row> : <Section>Aucun document PDF séléctionné</Section>
						}
				</Stack>
			</Sections>
		</Pages>
	);
};

const Sections = styled.div`
    ${stack("S", "flex-start", "stretch")}
`;
const Header = styled.div`
    ${stack("S")};
`;
const PdfEditorWrapper = styled.div`
    position: relative;
    width: auto;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
`;

const RectangleStyled = styled.div<{ isSelected: boolean }>`
    position: absolute;
    background-color: ${colors.lightGrey};
    pointer-events: auto;
    cursor: grab;
    ${(props) => props.isSelected && "box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);"}
`;

const FieldName = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: black;
    cursor: grab;
`;

const Row = styled.div`
    ${row("S", "flex-start", "flex-start")} @media ${desktop} {
    padding: ${sizes.S};
    border-radius: 12px;
    box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    flex-grow: 1;
}
`;
const RowList = styled.div`
    ${row("S", "flex-start", "flex-start")}
`;
const Title = styled(motion.div)`
    font-size: 20px;
    font-weight: bold;
    color: ${colors.black};
`;

const Subtitle = styled.div`
    font-size: 15px;
    color: ${colors.grey2};
`;

const SubtitleImportant = styled.div`
    font-size: 15px;
    color: ${colors.black};
`;

const Stack = styled.div`
    ${stack("S", "flex-start", "stretch")}
`;
const Section = styled(motion.div)`
    ${stack("XL", "flex-start", "stretch")}
    @media ${desktop} {
    padding: ${sizes.L};
    border-radius: 12px;
    box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    flex-grow: 1;
}
`;
const Pages = styled.div`
    ${stack("XL", "flex-start", "stretch")}
    flex-grow: 1;
    min-height: 100vh;
    margin: 0 auto;
    width: 1100px;
    max-width: calc(100vw - 40px);

    @media ${desktop} {
        padding-top: ${sizes.XL};
        padding-bottom: ${sizes.XL};
    }

    @media ${mobile} {
        padding-bottom: 100px;
    }
`;
const ZoomIn = styled.img`
    width: 22px;
    height: 22px;
`

const ZoomOut = styled.img`
    width: 22px;
    height: 22px;
`
const FileInputLabel = styled.label<{ hasError: boolean }>`
    background-color: #f7fff7;
    width: 350px;
    padding: 20px 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0 10px 20px, rgba(0, 0, 0, 0.28) 0 6px 6px;
    border: ${({ hasError }) => (hasError ? `2px solid ${colors.red}` : `2px dashed ${colors.green}`)};
    cursor: pointer;
`;
const FileInput = styled.input`
    background-color: #f7fff7;
    width: 350px;
    padding: 20px 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0 10px 20px, rgba(0, 0, 0, 0.28) 0 6px 6px;
`;
const StyledFieldName = styled.div`
    cursor: pointer;		
`

const StyledInput = styled.input`
    overflow: hidden;

    font-family: inherit;
    appearance: none;
    -webkit-appearance: none;
    font-size: 15px;
    color: ${colors.black};
    flex-shrink: 0;
    padding: 8px 30px 10px 16px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 0 0 1px #c8efec inset;
    background-color: ${colors.white};
    outline: 0;
    flex-grow: 1;
		width: 100px;

    &:disabled {
        color: ${colors.grey2};
        background: #f4f4f4;
    }

    &:focus {
        box-shadow: 0 0 0 2px ${colors.green} inset;
        outline: 0;
    }
    transition: box-shadow 0.15s ease-out;
`;
export default PdfEditor;
