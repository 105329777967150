import styled from "@emotion/styled";
import ClipBoardIcon from "@assets/icons/clipboard.svg";
import ClipBoardCheckIcon from "@assets/icons/clipboard-check.svg";
import React, { useCallback, useState } from "react";
import { ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { practitionerService } from "services/practitioner-service";
import { colors, desktop, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { DownShift } from "../inputs/down-shift";
import { CCAM } from "../../domain/surgery";
import { secretaryService } from "../../services/secretary-service";
import { motion } from "framer-motion";

interface SearchCCAMModalProps {
	onClose: (patientId: string | null) => void;
	practitionerId?: string;
}

export const SearchCCAMModal: React.FC<SearchCCAMModalProps> = ({ onClose, practitionerId }) => {
	const { format } = useI18n();
	const [listCCAM, setlistCCAM] = useState<CCAM[]>([]);
	const [ccamChoose, setCCAMChoose] = useState<string | undefined>("");
	const [clipBoardCopy, setclipBoardCopy] = useState<boolean>(false);
	const handleTextClick = () => {
		if (ccamChoose) {
			navigator.clipboard.writeText(ccamChoose).then();
		}
	};

	const getListCCAM = useCallback(listCCAM => {
		const listName: string[] = [];
		listCCAM.map((ccam: CCAM) => listName.push(ccam.descriptionCustom ?? ccam.description));
		return listName;
	}, []);

	const getCCAM = useCallback(async ccam => {
		if (ccam.length > 3) {
			if (practitionerId) {
				const CCAMsList = await secretaryService.fetchCCAMByDescription(ccam, practitionerId);
				if (CCAMsList && CCAMsList.length > 0) setlistCCAM(CCAMsList);
			} else {
				const CCAMsList = await practitionerService.fetchCCAMByDescription(ccam);
				if (CCAMsList && CCAMsList.length > 0) setlistCCAM(CCAMsList);
			}
		}
	}, []);
	return (
		<Card>
			<ModalCloseIcon onClick={() => onClose(null)} />
			<Stack>
				<Title>{format("searchClassificationCode.title")}</Title>
				<Label>{format("searchClassificationCode.text.description")}</Label>
				<Label>{format("searchClassificationCode.text.example")}</Label>
			</Stack>
			<Content>
				<Row>
					<DownShiftCustom
						notFoundText={"Aucun code CCAM correspondant dans SOPHI. \nContactez l'équipe technique SOPHI."}
						textInputPlaceHolder={format("searchClassificationCode.placeholder")}
						list={getListCCAM(listCCAM).slice(0, 10)}
						onChange={value => getCCAM(value)}
						startSearch={4}
						onClick={item => {
							setCCAMChoose(listCCAM.find(ccam => ccam.description == item || ccam.descriptionCustom == item)?.codeCCAM);
						}}
					/>
					{ccamChoose && ccamChoose?.length > 0 && (
						<Row>
							<Text
								onClick={() => {
									handleTextClick();
									setclipBoardCopy(true);
								}}
							>
								{ccamChoose}
							</Text>
							{clipBoardCopy ? (
								<Row>
									<ClipBoardCheck /> <TextCopy>{format("searchClassificationCode.copy")}</TextCopy>
								</Row>
							) : (
								<ClipBoard
									onClick={() => {
										handleTextClick();
										setclipBoardCopy(true);
									}}
								/>
							)}
						</Row>
					)}
				</Row>
			</Content>
		</Card>
	);
};

const Card = styled(ModalCardContainer)`
	padding: 63px 80px;
	width: 60%;
	height: 90%;
	${stack("S")};
	@media (max-width: 920px) {
		padding: ${sizes.L};
	}
`;

const Title = styled.h1`
	font-size: 27px;
	max-width: 500px;
	color: ${colors.black};
`;
const Content = styled.div`

	@media${desktop} {
		${row(0, "center", "center")};
	}
	@media${mobile} {
		${stack(0, "center", "center")};
	}
`;

const Row = styled.div`
	width: 920px;
`;

const Text = styled.text`
	padding-top: 5px;
	font-size: 18px;
	cursor: pointer;
	width: 70%;
	height: 80%;
`;

const TextCopy = styled.text`
	font-size: 12px;
	width: 70%;
	height: 80%;
	color: ${colors.green};
	font-weight: bolder;
`;

const ClipBoard = styled(ClipBoardIcon)`
	height: 15px;
	width: 15px;
	cursor: pointer;
	font-weight: bolder;
`;

const ClipBoardCheck = styled(ClipBoardCheckIcon)`
	height: 15px;
	width: 15px;
	path {
		fill: ${colors.green};
	}
`;

const Stack = styled.div`
	${stack("S")}
`;

const Label = styled(motion.label)`
	font-size: 12px;
	font-weight: bold;
	color: ${colors.grey};
`;

const DownShiftCustom = styled(DownShift)`
	width: 50%;
`;
