import Axios from "axios";
import { PaginationOptions, SortOption } from "utils/pagination";

export const apiConfig = {
	baseURL: process.env.API_URL,
	headers: {
		"x-api-version": "1.0",
	},
};

export const api = Axios.create(apiConfig);

export function buildPaginationParams(pageOptions?: PaginationOptions, sortOption?: SortOption) {
	return {
		...(pageOptions ? pageOptions : {}),
		...(sortOption ? { sort: `${sortOption.by},${sortOption.order}` } : {}),
	};
}
export function buildSearchParams(
	paramsObject?: Record<string, string | number | undefined>,
	sortOptions?: SortOption[]
): URLSearchParams {
	const params = new URLSearchParams();
	if (paramsObject) {
		Object.entries(paramsObject).forEach(entry => {
			const [key, value] = entry;
			value !== undefined && params.append(key, value.toString());
		});
	}
	sortOptions
		?.filter(
			(currentOption, i) => sortOptions.findIndex(previousOption => previousOption.by === currentOption.by) === i
		) // distinct and keep the first option with the same "by"
		.forEach(
			sortOption => params.get(sortOption.by) === null && params.append("sort", `${sortOption.by},${sortOption.order}`)
		);
	return params;
}

export interface ApiError {
	exception: string;
	message: string;
	path: string;
	code?: string;
	reason: string;
	requestId: string;
	status: number;
	timestamp: string;
}
