import styled from "@emotion/styled";
import { Surgery } from "domain/surgery";
import React, { useCallback, useState } from "react";
import { useDocumentTypes } from "services/document-type-service";
import { cardsColorsPSADArray, colors, desktop, mobile, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { motion } from "framer-motion";
import { css } from "@emotion/react";
import BackIcon from "@assets/icons/back.svg";
import { DocumentType, SurgeryDocumentValidationStatus } from "../../domain/document";
import { createModal } from "../../services/modal-service";
import { AxiosError } from "axios";
import { ApiError } from "../../services/api";
import { Button } from "../../components/button";
import { DocumentsCategories } from "../../components/sections/documents-categories";
import { secretaryService } from "../../services/secretary-service";
import { useParams } from "react-router";
import { MySignModalSecretary } from "../../components/modals/secretary/my-sign-modal-secretary";
import { DocumentsMessage } from "../../components/documents/document-message";
import { isPatientMinor } from "../../utils/isPatientMinor";
import { DocumentUploader } from "../../components/documents/document-uploader";

export const SecretaryForensicHealthcareProviderSection: React.FC<{
	surgery: Surgery;
	onDocCustomTypeSelected?: (docType: DocumentType) => void;
}> = ({ surgery }) => {
	const { format } = useI18n();
	const { documentTypes } = useDocumentTypes();
	const [showed, setShowed] = useState<boolean | null>(true);
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const patientIsMinor =
		!!surgery.patient && isPatientMinor(new Date(surgery.patient.birthDate), new Date(surgery.creationDate));
	const uppyEndpointFactory = useCallback(
		() => secretaryService.getDocumentUploadEndpoint(surgery.id, practitionerId),
		[surgery.id]
	);
	const documentIdsToUpload = documentTypes
		.filter(
			d =>
				(!d.isForTutoring || patientIsMinor) &&
				!d.isForAdmission
		)
		.sort((docA, docB) => (docA.position > docB.position ? 1 : -1))
		.map(d => d.id);

	let documentType = [""];
	let result: any = null;
	const documents = surgery.surgeryDocuments.filter(doc =>
		!doc.documentType.isForAdmission && doc.documentType.filename != "sign"
	);
	documents
		.sort((docA, docB) => (docA.documentType.position > docB.documentType.position ? 1 : -1))
		.map(doc => {
			documentType = [...documentType, doc.documentType.type];
		});
	const existingTypes = documents
		.map(doc => doc.documentType.id)
		.filter(docTypeId => !documentTypes.find(d => d.id === docTypeId)?.isOtherType);
	const [procedureLoading, setProcedureLoading] = useState<boolean>(false);
	const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
	//Remove duplicate entries
	documentType = Array.from(new Set(documentType));
	const doesDocumentsNeedSignature = () => {
		const docs = documents.filter(
			document =>
				document.needSignature &&
				!document.isSigned &&
				document.validationStatus != SurgeryDocumentValidationStatus.Rejected
		);
		return docs.length > 0;
	};

	// Modal Signature
	const sign = useCallback(
		async (surgery: Surgery) => {
			setProcedureLoading(true);
			try {
				setProcedureLoading(false);
				result = await createModal<string | undefined>(({ onClose }) => (
					<MySignModalSecretary
						surgeryId={surgery.id}
						surgeryDate={surgery.surgeryDate}
						onClose={onClose}
						practitionerId={practitionerId}
					/>
				));
				setDocumentsLoading(result === "success");
				if (result) {
					await secretaryService.fetchSurgery(surgery.id, practitionerId);
				}
			} catch (e: any) {
				const apiError: AxiosError<ApiError> = e;
				if (apiError.response?.data.code === "ERR_NO_PHONENUMBER_OTHER_GUARDIAN") {
					alert(format("signError.phoneNumber"));
				}
				console.log(e.message);
			} finally {
				setProcedureLoading(false);
				documents.map(async document => {
					await secretaryService.updateDocument(surgery.id, document.id, practitionerId, document);
				});
			}
		},
		[format, surgery.patient?.id]
	);

	const someDocumentsAreNotSigned: boolean = doesDocumentsNeedSignature();
	return (
		<Stack>
			<Section>
				<DocumentTitle>{format("practitioner.title.hpu.forensic.document.title")}</DocumentTitle>
				<Subtitle>{format("practitioner.title.hpu.forensic.document.subtitle")}</Subtitle>
			</Section>
			<Section>
				<Header>
					{documents.length > 0 && (
						<Row>
							<ShowAllButton
								onClick={() => {
									setShowed(true);
								}}
							>
								<SmallArrowIcon src="/icons/back.svg" iconcolor={colors.pink} showed={false.toString()} />
								<Text textColor={colors.black}>{format("practitioner.surgery.document.showAll")}</Text>
							</ShowAllButton>
							<ShowAllButton
								onClick={() => {
									setShowed(false);
								}}
							>
								<SmallArrowIcon src="/icons/back.svg" iconcolor={colors.pink} showed={true.toString()} />
								<Text textColor={colors.black}>{format("secretary.surgery.document.hideAll")}</Text>
							</ShowAllButton>
						</Row>
					)}
					<Documents documentsUploaded={documents.length > 0}>
						<DocumentUploader
							uppyEndpointFactory={uppyEndpointFactory}
							documentTypeIds={documentIdsToUpload}
							disabledTypeIds={existingTypes}
							allowMultipleFiles={false}
							showSignatureNeededCheckbox={false}
							onSuccess={() => {
								secretaryService.fetchSurgery(surgery.id, practitionerId).then();
							}}
							practitionerId={practitionerId}
							surgery={surgery}
							hpu={false}
						/>
					</Documents>
				</Header>

				<Row>
					<TopDocument>
						{someDocumentsAreNotSigned && (
							<DocumentsMessage color="orange" label={format("patient.forensicDocumentsStep.signIncentive")} />
						)}

						<TopButton>
							{someDocumentsAreNotSigned ? (
								<ModalButton
									loading={procedureLoading}
									disabled={documentsLoading}
									onClick={() => {
										/*logSign();*/
										sign(surgery);
									}}
								>
									{format("patient.forensicDocumentsStep.signButton")}
								</ModalButton>
							) : null}
						</TopButton>
					</TopDocument>
				</Row>

				{documents.length <= 0 ? (
					<span>{format("practitioner.surgery.upload.emptyMiddleSection")}</span>
				) : (
					documentType
						.filter(type => type != "")
						.map((it, index) => {
							return (
								<DocumentsCategories
									documents={documents}
									title={it}
									surgeryId={surgery.id}
									practitionerId={practitionerId}
									cardsColor={cardsColorsPSADArray[index]}
									showAll={showed}
									key={index}
									onShowed={bool => {
										setShowed(bool);
									}}
								/>
							);
						})
				)}
			</Section>
		</Stack>
	);
};

const Row = styled.div`
	${row("S", "flex-start", "flex-start")}
`;

const Stack = styled.div`
	${stack("L", "flex-start", "stretch")}
	flex-grow: 1;
`;

const Section = styled.div`
	${stack("L", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

const ShowAllButton = styled.div`
	cursor: pointer;
	${row("S", "flex-start", "stretch")}
`;

export const DocumentTitle = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const SmallArrowIcon = styled(BackIcon)<{ iconcolor?: string }>`
	width: 24px;
	path {
		fill: ${props => props.iconcolor ?? ""};
	}
	transform: ${props =>
		props.showed
			? css`
		 rotate(180deg)
	`
			: null};
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	font-weight: bold;
	color: ${props => props.textColor ?? "black"};
`;

const TopButton = styled.div`
	@media ${mobile} {
		${stack("M", "flex-end", "stretch")};
	}

	@media ${desktop} {
		${row("XL", "flex-end", "flex-start")};
	}
`;

const TopDocument = styled.div`
	width: 100%;
	@media ${mobile} {
		${stack("M", "flex-start", "stretch")};
	}

	@media ${desktop} {
		${row("XL", "space-between", "stretch")};
	}
`;

const ModalButton = styled(Button)`
	@media ${desktop} {
		margin-left: 400px;
	}
`;

const Header = styled.div`
	${row("L", "space-between", "stretch")}
`;

const Subtitle = styled(motion.div)`
	font-size: 15px;
	color: ${colors.grey};
`;

const Documents = styled.div<{ documentsUploaded?: boolean }>`
	${props => (props.documentsUploaded ? row("S", "space-between", "flex-end") : row(0, "flex-end"))};
`;
