export interface Document {
	id: string;
	documentType: DocumentTypeLight;
	uploadDate: string;
	needSignature: boolean;
	isSigned: boolean;
	url?: string;
	yousignUrl?: string;
	validationStatus?: SurgeryDocumentValidationStatus;
	rejectionMessage?: string;
	description?: string;
	manuallyAdded: boolean;
	toRead: boolean;
	toPrint: boolean;
	status?: SurgeryDocumentAdmission;
	rejectReason?: string;
	weblinkUrl?: string;
	guid?: string;
	guid2?: string;
	lienHss?: string;
	lienHss2?: string;
}

export interface DocumentType {
	id: string;
	label: string;
	isToBeSigned?: boolean;
	isForTutoring: boolean;
	isOtherType?: boolean;
	description?: string;
	source?: SurgeryDocumentSource;
	type: string;
	position: number;
	isForAdmission: boolean;
	destination?: string;
	filename?: string;
}

export interface RejectedDocument {
	documentName: string;
	documentDescription: string;
	rejectionMessage: string;
}

export type DocumentTypeLight = Pick<
	DocumentType,
	"id" | "description" | "label" | "type" | "position" | "isOtherType" | "isForAdmission" | "source" | "destination" | "filename"
>;

export type DocumentUpdateDTO = Partial<Pick<Document, "description">>;

export enum SurgeryDocumentValidationStatus {
	Pending = "Pending",
	Validated = "Validated",
	Rejected = "Rejected",
}

export enum SurgeryDocumentAdmission {
	Empty = "Empty",
	Pending = "Pending",
	Completed = "Completed",
	Rejected = "Rejected",
}

export enum SurgeryDocumentSource {
	Patient = "Patient",
	Practitioner = "Practitioner",
	HPU = "HPU",
}

export function someDocumentsRequireSignature(documents: Document[]) {
	return documents.reduce(
		(b, doc) => b || (doc.needSignature && !doc.isSigned && doc.documentType.source != "HPU"),
		false
	);
}

export function someDocCustomRequireToBeFill(documents: Document[]) {
	return documents.reduce((b, doc) => b || doc.status != SurgeryDocumentAdmission.Completed, false);
}
