import ProfileIcon from "@assets/icons/profile.svg";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { authService } from "services/auth-service";
import { createModal } from "services/modal-service";
import { colors, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { CreateVideoPlayerModal } from "../modals/video-player-modal";
import { MediaFile } from "../../domain/media-file";
import { mediaFileService } from "../../services/media-file-service";

export const AccountSelector: React.FC<{
	name: string;
	className?: string;
	withAccount?: boolean;
	accountType?: string;
	showAddDocCustom?:boolean;
}> = ({ name, withAccount, className, accountType,showAddDocCustom }) => {
	const history = useHistory();
	const contextMenuRef = useRef<HTMLDivElement | null>(null);
	const [mediaFile, setMediaFile] = useState<MediaFile | null>(null);
	function audience(accountType: string | undefined) {
		switch (accountType) {
			case "patient":
				return "partialPatient.mp4";
			case "hospital":
				return "partialHospital.mp4";
			case "hpu":
				return "partialHpu.mp4";
			case "practitioner":
				return "partialPractitioner.mp4";
			case "secretary":
				return "partialSecretary.mp4";
			case "support-technical":
				return "partialSupportTechnical.mp4";
			default:
				return "partialPatient.mp4";
		}
	}
	const nameVideo = audience(accountType);

	useEffect(() => {
		if (nameVideo) {
			mediaFileService.fetchMediaFile(nameVideo).then(value => {
				setMediaFile(value);
			});
		}
	}, [mediaFile?.fileName]);

	return (
		<Container
			onClick={async () => {
				const action = await createModal<"legalNotice" | "account" | "logout" | "ChangePassword" | "showTuto" | "showAddDocCustom" | null>(
					({ style, onClose }) => {
						const refRect = contextMenuRef.current?.getBoundingClientRect();
						return (
							<AccountContextMenu
								style={refRect ? { ...style, top: refRect.top + refRect.height, left: refRect.left + 27 } : style}
								onClose={onClose}
								showAccount={withAccount}
								showAddDocCustom={showAddDocCustom}
							/>
						);
					},
					{ valueOnBackdropClick: null, anchor: contextMenuRef.current ?? undefined }
				);

				if (action === "logout") {
					const usurp = await authService.logout();
					if (usurp == "true") {
						history.push("/support-technical");
						authService.usurpStore.save("");
					} else {
						history.push("/");
					}
				} else if (action === "legalNotice") {
					history.push("/legal-notice");
				} else if (action === "account" && accountType === "practitioner") {
					history.push("/practitioner/account");
				} else if (action === "account" && accountType === "secretary") {
					history.push("/secretary/account");
				} else if (action === "account" && accountType === "support-technical") {
					history.push("/support-technical/account");
				} else if (action === "ChangePassword" && accountType === "practitioner") {
					history.push("/practitioner/password-update");
				} else if (action === "ChangePassword" && accountType === "secretary") {
					history.push("/secretary/password-update");
				} else if (action === "ChangePassword" && accountType === "hospital") {
					history.push("/hospital/password-update");
				} else if (action === "ChangePassword" && accountType === "patient") {
					history.push("/patient/password-update");
				} else if (action === "ChangePassword" && accountType === "hpu") {
					history.push("/hpu/password-update");
				} else if (action === "ChangePassword" && accountType === "support-technical") {
					history.push("/support-technical/password-update");
				} else if (action === "ChangePassword" && accountType === undefined) {
					console.log("accountType non défini");
				} else if (action === "showTuto") {
					createModal<string | null>(({ onClose }) => (
						<CreateVideoPlayerModal onClose={onClose} videoPath={mediaFile?.url} />
					));
				}
				else if (action ==  "showAddDocCustom") {
					history.push("/hpu/settings")
				}
			}}
			className={className}
			ref={contextMenuRef}
		>
			<StyledProfileIcon src="/icons/profile.svg" />
			<ProfileName>{name}</ProfileName>
			<ArrowIcon src="/icons/back.svg" />
		</Container>
	);
};

export const AccountContextMenu: React.FC<{
	onClose: (action: "legalNotice" | "account" | "logout" | "ChangePassword" | "showTuto"  |"showAddDocCustom"| null) => void;
	style?: CSSProperties;
	showAccount?: boolean;
	showAddDocCustom?: boolean,
}> = ({ onClose, style, showAccount: showAccount, showAddDocCustom:showAddDocCustom }) => {
	const { format } = useI18n();
	return (
		<ContextMenuModal style={style} initial={{ y: -15, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
			<ContextMenuOption onClick={() => onClose("showTuto")}>
				<OptionIcon src="/icons/video-tutorial.svg" />
				<OptionLabel>{format("profile.show-tuto")}</OptionLabel>
			</ContextMenuOption>
			<ContextMenuOption onClick={() => onClose("legalNotice")}>
				<OptionIcon src="/icons/legal-notice.svg" />
				<OptionLabel>{format("profile.legal-notice")}</OptionLabel>
			</ContextMenuOption>
			{showAccount && (
				<ContextMenuOption onClick={() => onClose("account")}>
					<OptionIcon src="/icons/medical.svg" />
					<OptionLabel>{format("profile.account")}</OptionLabel>
				</ContextMenuOption>
			)}
			{showAddDocCustom && (
				<ContextMenuOption onClick={() => onClose("showAddDocCustom")}>
					<OptionIcon src="/icons/file-pdf.svg" />
					<OptionLabel>{format("profile.addDocCustom")}</OptionLabel>
				</ContextMenuOption>
			)}
			<ContextMenuOption onClick={() => onClose("ChangePassword")}>
				<OptionIcon src="/icons/password-change.svg" />
				<OptionLabel>{format("profile.password-change")}</OptionLabel>
			</ContextMenuOption>
			<ContextMenuOption onClick={() => onClose("logout")}>
				<OptionIcon src="/icons/log-out.svg" />
				<OptionLabel>{format("profile.logout")}</OptionLabel>
			</ContextMenuOption>
		</ContextMenuModal>
	);
};

const ContextMenuModal = styled(motion.div)`
	${stack(0)};
	border-radius: 10px;
	box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
	background-color: ${colors.white};
	margin-top: 5px;
	overflow: hidden;
`;
const ContextMenuOption = styled.div`
	cursor: pointer;
	padding: ${sizes.S};
	${row("S")};

	:hover {
		background: rgba(0, 0, 0, 0.05);
		width: 100%;
	}
`;

const Container = styled.div`
	flex-wrap: wrap;
	position: relative;
	cursor: pointer;
	${row("S", "flex-start", "center")};
`;

const StyledProfileIcon = styled(ProfileIcon)`
	width: 20px;
	height: 20px;

	path {
		fill: ${colors.black};
	}
`;

const ProfileName = styled.div`
	font-size: 15px;
	color: ${colors.black};
	font-weight: bold;
`;

const ArrowIcon = styled.img`
	width: 18px;
	height: 18px;
	margin-top: 2px;
`;

const OptionLabel = styled.div`
	font-size: 13px;
	color: ${colors.black};
`;

const OptionIcon = styled.img`
	width: 20px;
	height: 20px;
`;
