import { UserType } from "domain/user-type";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { authService, useUser } from "../services/auth-service";

export function useRequireAuth(loginType: UserType) {
	const user = useUser();
	const history = useHistory();

	const location = useLocation();
	const userWasLoggedIn = useRef<boolean>(!!user);

	useEffect(() => {
		if (!user && !userWasLoggedIn.current) {
			history.replace(`/${loginType}/login?redirectTo=${encodeURIComponent(location.pathname)}`);
		}
		userWasLoggedIn.current = userWasLoggedIn.current || !!user;
	}, [history, location.pathname, loginType, user]);

	useEffect(() => {
		if (user && user.type !== loginType) {
			userWasLoggedIn.current = false;
			authService.logout();
		}
	}, [loginType, user]);

	return [user] as const;
}
