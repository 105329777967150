import { css } from "@emotion/react";
import DoctorIcon from "@assets/icons/doctor.svg";
import styled from "@emotion/styled";
import { fullName } from "domain/utils";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { colors, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { AccountSelector } from "./account-selector";
import { secretaryService, useSecretaryPractitioners, useSecretaryProfile } from "../../services/secretary-service";
import { UserType } from "../../domain/user-type";
import { Location } from "history";

export const SecretaryPractitionerNavBar: React.FC<{ className?: string; location?: Location | null }> = ({
	className,
}) => {
	const { format } = useI18n();
	const profile = useSecretaryProfile();
	const practitioners = useSecretaryPractitioners();
	const history = useHistory();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	let subPath = "";
	if (location.pathname.match("dashboard")) {
		subPath = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
	} else {
		subPath = "dashboard-patients";
	}
	useEffect(() => {
		secretaryService.fetchProfile();
		secretaryService.fetchPractitioners();
	}, []);

	useEffect(() => {
		secretaryService.countSurgeriesWithHpusAction(practitionerId).then();
	}, [practitionerId]);
	return (
		<Container className={className}>
			<LogoContainer>
				<LogoSophi src="/images/logo.svg" />
				<span>{format("practitioner.header.logo")}</span>
			</LogoContainer>
			<Spacer />
			{practitioners?.map(practitioner => (
				<Tab
					selected={!!location.pathname.match(`/secretary/practitioner/${practitioner.id}/*`)}
					key={practitioner.id}
					onClick={() => {
						history.push({
							pathname: `/secretary/practitioner/${practitioner.id}/${subPath}`,
							state: { fromLogin: false },
						});
					}}
				>
					<Row>
						<SmallDoctorIcon
							src="/icons/doctor.svg"
							selected={!!location.pathname.match(`/secretary/practitioner/${practitioner.id}/*`)}
						/>
						{practitioner.lastName} {practitioner.firstName}
					</Row>
				</Tab>
			))}
			<Spacer />
			<AccountSelector name={fullName(profile)} withAccount accountType={UserType.Secretary} />
		</Container>
	);
};

const Container = styled.div`
	${row("M", "flex-start", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "center", "center")}
	}
`;

const LogoContainer = styled.div`
	${row("S", "flex-start", "center")}
	color: ${colors.black};
`;

const SmallDoctorIcon = styled(DoctorIcon)<{ selected: boolean }>`
	width: 20px;
	padding-right: 2px;

	${({ selected }) =>
		selected &&
		css`
			opacity: 1;
			path {
				fill: ${colors.white};
			}
		`}
`;

const LogoSophi = styled.img`
	width: 29px;
`;

const Tab = styled.div<{ selected: boolean }>`
	${row("XL", "flex-start", "center")}
	position: relative;
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;
	max-width: 50%;
	${({ selected }) =>
		selected &&
		css`
			opacity: 1;

			background-color: ${colors.pink};
			color: ${colors.white};
			padding: ${sizes.S};
			border-radius: 20px;
			:after {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 6px;
				width: 6px;
				border-radius: 3px;
				background-color: ${colors.pink};
				@media ${mobile} {
					left: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		`}
`;
const Row = styled.div`
	${row("L", "center", "center")}
	overflow-wrap: break-word;
	flex-wrap: wrap;
`;
