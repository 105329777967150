import { TextArea } from "components/inputs/text-input";
import React from "react";
import { useDocumentType } from "services/document-type-service";
import { useI18n } from "utils/i18n";

interface Props {
	documentTypeId: string;
	value: string | null | undefined;
	onChange: (value: string) => void;
}

export const DocumentDescriptionEditor: React.FC<Props> = ({ documentTypeId, value, onChange }) => {
	const { format } = useI18n();
	const documentType = useDocumentType(documentTypeId);

	return (
		<TextArea
			style={{ width: 200, height: 55, fontSize: 13 }}
			value={value || ""}
			placeholder={format(
				documentType?.isOtherType
					? "practitioner.surgery.document.descriptionPlaceholder.required"
					: "practitioner.surgery.document.descriptionPlaceholder.optional"
			)}
			onChange={e => e.currentTarget.value.length <= 60 && onChange(e.currentTarget.value.replace("\n", ""))}
			maxLength={60}
		/>
	);
};
