import styled from "@emotion/styled";
import {motion} from "framer-motion";
import React, {CSSProperties} from "react";
import {sizes} from "style";
import {useI18n} from "utils/i18n";
import {HospitalService} from "../../services/hospital-service";
import {PatientService} from "../../services/patient-service";
import {PractitionerService} from "../../services/practitioner-service";
import {SecretaryService} from "../../services/secretary-service";

export type DocumentMenuContextAction = "delete" | "download" | "update" | "cancel" | "downloadHss" | "downloadHss2";

export const DocumentMenuContextMenu: React.FC<{
	onClose: (action: DocumentMenuContextAction | null) => void;
	style?: CSSProperties;
	showUpdate?: boolean;
	showDelete?: boolean;
	showCancel?: boolean;
	audience?: string;
	surgeryId: string;
	documentId: string;
	showHss?: boolean;
	showHss2?: boolean;
}> = ({ onClose, style, showUpdate, showDelete , audience, surgeryId, documentId, showCancel, showHss, showHss2 }) => {
	const { format } = useI18n();

	function log() : void {
		if (audience == 'hospital') {
			const hospitalService = new HospitalService()
			hospitalService.log(surgeryId, documentId)
		} if (audience == 'practitioner') {
			const practitionerService = new PractitionerService()
			practitionerService.log(surgeryId, documentId)
		} if (audience == 'secretary') {
			const secretaryService = new SecretaryService()
			secretaryService.log(surgeryId, documentId)
		} if (audience == 'patient') {
			const patientService = new PatientService()
			patientService.log(surgeryId, documentId)
		}
	}

	return (
		<ContextMenuModal style={style} initial={{ y: -15, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
			{showHss && (
				<ContextMenuOption onClick={() => onClose("downloadHss")}>{format("document.context.downloadHss")}</ContextMenuOption>
			)}
			{showHss2 && (
				<ContextMenuOption onClick={() => onClose("downloadHss2")}>{format("document.context.downloadHss2")}</ContextMenuOption>
			)}
			{!showHss && !showHss2 && (
				<ContextMenuOption onClick={() => { log(); onClose("download"); } }>{format("document.context.download")}</ContextMenuOption>
			)}
			{showUpdate && (
				<ContextMenuOption onClick={() => onClose("update")}>{format("document.context.update")}</ContextMenuOption>
			)}
			{showDelete && (
				<ContextMenuOption onClick={() => onClose("delete")}>{format("document.context.delete")}</ContextMenuOption>
			)}
			{showCancel && (
				<ContextMenuOption onClick={() => onClose("cancel")}>{format("document.context.cancel")}</ContextMenuOption>
			)}
		</ContextMenuModal>
	);
};

const ContextMenuModal = styled(motion.div)`
	background: white;
	border-radius: 9px;
	overflow: hidden;
`;
const ContextMenuOption = styled.div`
	padding: ${sizes.M};
	cursor: pointer;

	:hover {
		background: rgba(0, 0, 0, 0.05);
	}
`;
