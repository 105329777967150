import React, { InputHTMLAttributes } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import styled from "@emotion/styled";
import { colors, sizes } from "../../style";
registerLocale("fr", fr);
setDefaultLocale("fr", fr);

interface AdditionalInputProps {
	placeholder?: string;
	newTime?: string;
	timeIntervals?: number;
	filterTime?: (time: Date) => boolean;
}
export const TimePickerInput: React.FC<InputHTMLAttributes<HTMLInputElement> & AdditionalInputProps> = ({
	placeholder,
	newTime,
	timeIntervals,
	...props
}) => {
	return (
		<StyledTimePicker
			placeholderText={placeholder ?? ""}
			showTimeSelect
			showTimeSelectOnly
			timeIntervals={timeIntervals ?? 5}
			timeCaption="Heure"
			dateFormat="hh:mm aa"
			value={newTime ?? ""}
			locale="fr"
			{...props}
		/>
	);
};

const StyledTimePicker = styled(DatePicker)`
	appearance: none;
	height: 40px;
	border-radius: 7px;
	border: none;
	box-shadow: 0 0 0 1px #c8efec inset;
	background-color: ${props => (props.disabled ? "rgba(0,0,0,0.03)" : colors.white)};
	padding-left: ${sizes.S};
	padding-right: ${sizes.S};
	flex-grow: 1;

	::placeholder {
		font-size: 13px;
		font-style: italic;
		color: ${colors.grey};
	}

	&:focus {
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
	transition: box-shadow 0.15s ease-out;
`;
