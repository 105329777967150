import React, {useRef, useState} from "react";
import styled from "@emotion/styled";
import {colors, desktop, row, sizes, stack} from "../../style";
import {motion} from "framer-motion";
import ReactPlayer from "react-player";
import {CheckboxInput} from "../inputs/checkbox-input";
import {UserProfile} from "../../domain/user-profile";
import {useI18n} from "../../utils/i18n";
import {userProfileService} from "../../services/user-profile-service";
import {Button} from "../button";
import ProfileIcon from "@assets/icons/profile.svg";

export const VideoTutoSection: React.FC<{
    setHasBeenClosed: any;
    videoPath: string | undefined;
    timelineCursor?: number;
    displayCheckbox?: boolean;
    userProfile?: UserProfile | null;
}> = ({ setHasBeenClosed, videoPath, timelineCursor, displayCheckbox, userProfile }) => {
    const { format } = useI18n();
    const [checked, setChecked] = useState(userProfile?.hasWatchedTuto);
    const handleChange = async () => {
        await setChecked(!checked)
    };
    const handleClose = async () => {
        if (checked && userProfile){
            const strUserProfileId = JSON.parse(JSON.stringify(userProfile.id)).value.toString();
            await userProfileService.setHasWatchedTuto(strUserProfileId)
        }
        setHasBeenClosed(true);
    }
    const [isReady, setIsReady] = React.useState(false);
    const playerRef = useRef<ReactPlayer>(null);
    const onReady = React.useCallback(() => {
        if (!isReady && playerRef.current && timelineCursor) {
            playerRef.current.seekTo(timelineCursor ?? 0, "seconds");
            setIsReady(true);
        }
    }, [isReady, playerRef.current]);

    return (
        <Sections>
            <Section>
                <Title>Comment utiliser SOPHI?</Title>
                <SubtitleImportant>
                    Première visite sur SOPHI? Voici une vidéo explicative pour vous aider à utiliser l&apos;application pas à pas.
                    <br/>
                    <p style={{ display: "flex" }}>
                        A tout moment, retrouvez la vidéo depuis votre menu Utilisateur / Tutoriel.
                        <StyledProfileIconWrapper>
                            <StyledProfileIcon src="/icons/profile.svg" />
                            <img src="/icons/back.svg" />
                        </StyledProfileIconWrapper>
                    </p>
                </SubtitleImportant>
                <Content>
                    <ReactPlayer
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onContextMenu={(e: Event) => e.preventDefault()}
                        ref={playerRef}
                        url={videoPath}
                        playing={false}
                        width="80%"
                        height="80%"
                        controls={true}
                        onReady={onReady}
                        // volume={0.1}
                        muted
                    />
                </Content>
                {checked != undefined && (
                    <Row>
                        <RowIcon>
                            <CheckboxInput
                                value={checked}
                                onChange={handleChange}
                            >
                            </CheckboxInput>
                            {format("video-player-modal.checkbox.label")}
                        </RowIcon>
                        <RowIcon>
                            <Button onClick={() => handleClose()}>Fermer le tutoriel</Button>
                        </RowIcon>
                    </Row>
                )}
            </Section>
        </Sections>
    )
}

const Sections = styled.div`
	${stack("S", "flex-start", "stretch")}
`;

const Section = styled(motion.div)`
	${stack("XL", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const SubtitleImportant = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;

const Content = styled.div`
	${row(0, "center", "center")};
`;

const Row= styled.div`
	${row("L", "space-between", "center")};
    width: 100%;
`;

const RowIcon= styled.div`
	${row("S", "flex-end", "flex-end")}
`;

const StyledProfileIconWrapper = styled.div`
    margin-left: 12px;
    border: solid ${colors.lightGrey};
    border-radius: 10px;
    width: 50px;
    display: flex;
    justify-content: space-between;
`;

const StyledProfileIcon = styled(ProfileIcon)`
	width: 20px;
	height: 20px;
    
	path {
		fill: ${colors.black};
	}
`;
