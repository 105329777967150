import styled from "@emotion/styled";
import { Button } from "components/button";
import { RadioButtonsInput, RadioButtonsType } from "components/inputs/radio-buttons-input";
import { Surgery, SurgeryUpdateDTO } from "domain/surgery";
import { AnimateSharedLayout, HTMLMotionProps, motion } from "framer-motion";
import React, { useCallback, useMemo, useState } from "react";
import { colors, mobile, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import {composeDate, isDateSameDay, normalizeDate, splitDate} from "utils/time";
import {DatePickerInput} from "../inputs/datepicker-input";
import {TimePickerInput} from "../inputs/timepicker-input";

interface AnesthesiaDateSectionProps {
	surgery: Surgery;
	onEdit?: (data: SurgeryUpdateDTO) => Promise<void>;
}

export const AnesthesiaDateSection = React.forwardRef<
	HTMLElement,
	AnesthesiaDateSectionProps & HTMLMotionProps<"section">
>(({ surgery, onEdit, ...otherProps }, ref) => {
	const { format } = useI18n();
	const anesthesiaDate = surgery.anesthesiaAppointmentDate ? new Date(surgery.anesthesiaAppointmentDate) : null;

	const getAnswerLabel = (answer: boolean) =>
		answer ? format("section.anesthesiaDate.question.yes") : format("section.anesthesiaDate.question.no");
	const [editing, setEditing] = useState(false);
	const currentDate = new Date();
	const isAnesthesiaTodayOrPast =
		!!surgery.anesthesiaAppointmentDate &&
		(isDateSameDay(currentDate, new Date(surgery.anesthesiaAppointmentDate)) ||
			currentDate > new Date(surgery.anesthesiaAppointmentDate));

	const commonProps = {
		anesthesiaDate,
		appointmentHonored: surgery.anesthesiaAppointmentHonored,
		isAnesthesiaTodayOrPast,
		getAnswerLabel,
		...otherProps,
		ref,
	};

	return editing ? (
		<AnesthesiaDateSectionEditionView {...commonProps} onEdit={onEdit} resetEdit={() => setEditing(false)} />
	) : (
		<AnesthesiaDateSectionView {...commonProps} edit={onEdit ? () => setEditing(true) : undefined} />
	);
});

AnesthesiaDateSection.displayName = "AnesthesiadateSection";

interface AnesthesiaDateSectionViewProps {
	anesthesiaDate: Date | null;
	appointmentHonored?: boolean;
	isAnesthesiaTodayOrPast: boolean;
	getAnswerLabel: (answer: boolean) => string;
	edit?: () => void;
}

const AnesthesiaDateSectionView = React.forwardRef<
	HTMLElement,
	AnesthesiaDateSectionViewProps & HTMLMotionProps<"section">
>(({ anesthesiaDate, appointmentHonored, isAnesthesiaTodayOrPast, getAnswerLabel, edit, ...otherProps }, ref) => {
	const { format, formatDate } = useI18n();
	const appointmentHonoredFilled = appointmentHonored === true || appointmentHonored === false;
	const displayConfirmButton = isAnesthesiaTodayOrPast && !appointmentHonoredFilled;
	const displayEditButton = anesthesiaDate && !displayConfirmButton;

	return (
		<Section layout {...otherProps} ref={ref}>
			<Header>
				<Subtitle>{format("section.anesthesiaDate.title")}</Subtitle>
				{edit && displayEditButton && (
					<Button onClick={edit}>
						{format("section.anesthesiaDate.edit.editButton")}
					</Button>
				)}
			</Header>
			<Info>
				{anesthesiaDate ? (
					<>
						<InfoField layout="position">
							<InputWrapper>
								<Label>{format("section.anesthesiaDate.date.label")}</Label>
								<Text>
									{formatDate(anesthesiaDate, {
										day: "numeric",
										month: "numeric",
										year: "numeric",
										hour: "numeric",
										minute: "numeric",
									})}
								</Text>
							</InputWrapper>
						</InfoField>
						{isAnesthesiaTodayOrPast && appointmentHonoredFilled && appointmentHonored !== undefined && (
							<QuestionRow>
								<QuestionWrapper>
									<QuestionText>
										<Question>{format("section.anesthesiaDate.question.label")}</Question>
									</QuestionText>
								</QuestionWrapper>
								<AnswerWrapper>
									<AnswerLabel>{getAnswerLabel(appointmentHonored)}</AnswerLabel>
								</AnswerWrapper>
							</QuestionRow>
						)}
					</>
				) : (
					<InfoField layout="position">{format("section.anesthesiaDate.empty")}</InfoField>
				)}
				{edit &&
					(!anesthesiaDate ? (
						<Button onClick={edit} type="button">
							{format("section.anesthesiaDate.edit.completeButton")}
						</Button>
					) : isAnesthesiaTodayOrPast && !appointmentHonoredFilled ? (
						<ConfirmButton onClick={edit} type="button">
							{format("section.anesthesiaDate.edit.confirmAppointment")}
						</ConfirmButton>
					) : null)}
			</Info>
		</Section>
	);
});

AnesthesiaDateSectionView.displayName = "AnesthesiaDateSectionView";

const AnesthesiaDateSectionEditionView = React.forwardRef<
	HTMLElement,
	Omit<AnesthesiaDateSectionViewProps, "edit"> & {
		resetEdit: () => void;
		onEdit?: (data: SurgeryUpdateDTO) => Promise<void>;
	} & HTMLMotionProps<"section">
>(
	(
		{
			anesthesiaDate: initialAnesthesiaDate,
			appointmentHonored: initialAppointmentHonored,
			isAnesthesiaTodayOrPast,
			getAnswerLabel,
			resetEdit,
			onEdit,
			...otherProps
		},
		ref
	) => {
		const { format } = useI18n();
		const { shortDate, time } = useMemo(
			() => (initialAnesthesiaDate ? splitDate(initialAnesthesiaDate) : { shortDate: "", time: "" }),
			[initialAnesthesiaDate]
		);

		const [anesthesiaDate, setAnesthesiaDate] = useState(shortDate);
		const [anesthesiaTime, setAnesthesiaTime] = useState(time);
		const [appointmentHonored, setAppointmentHonored] = useState(initialAppointmentHonored);
		const [loading, setLoading] = useState(false);

		const setEditingAndResetFields = useCallback(() => {
			resetEdit();
			setAnesthesiaDate(shortDate);
			setAnesthesiaTime(time);
			setAppointmentHonored(initialAppointmentHonored);
		}, [shortDate, time, initialAppointmentHonored, resetEdit]);

		const save = useCallback(async () => {
			try {
				setLoading(true);
				await onEdit?.({
					anesthesiaAppointmentDate:
						anesthesiaDate && anesthesiaTime
							? composeDate({ shortDate: anesthesiaDate, time: anesthesiaTime }).toISOString()
							: undefined,
					anesthesiaAppointmentHonored: appointmentHonored,
				});
			} catch (e) {
				console.log(e);
			} finally {
				setEditingAndResetFields();
				setLoading(false);
			}
		}, [anesthesiaDate, anesthesiaTime, setEditingAndResetFields, onEdit, appointmentHonored]);

		return (
			<AnimateSharedLayout>
				<form
					onSubmit={e => {
						e.preventDefault();
						!loading && save();
					}}
				>
					<Section layout {...otherProps} ref={ref}>
						<Header>
							<Subtitle>{format("section.anesthesiaDate.title")}</Subtitle>
						</Header>
						<Info>
							<InputWrapper spacing="S">
								<Label>{format("section.anesthesiaDate.date.label")}</Label>
								<DatePickerInput
									placeholder={format("section.anesthesiaDate.date.placeholder")}
									onChange={e => {
										setAnesthesiaDate(normalizeDate(e.toLocaleString()));
									}}
									newDate={anesthesiaDate}
									required
								/>
							</InputWrapper>
							<InputWrapper spacing="S">
								<Label>{format("section.anesthesiaDate.time.label")}</Label>
								<TimePickerInput
									value={anesthesiaTime}
									onChange={e => {
										if (e.toLocaleString().split("à")[1]) {
											setAnesthesiaTime(e.toLocaleString().split("à")[1].trim().slice(0,5))
										} else if (e.toLocaleString().split(",")[1]) {
											setAnesthesiaTime(e.toLocaleString().split(",")[1].trim().slice(0,5))
										} else {
											setAnesthesiaTime(e.toLocaleString().split(" ")[1].trim().slice(0,5))
										}
									}}
									placeholder={format("section.anesthesiaDate.time.placeholder")}
									required
								/>
							</InputWrapper>
							{isAnesthesiaTodayOrPast && (
								<QuestionRow>
									<QuestionWrapper>
										<QuestionText>
											<Question>{format("section.anesthesiaDate.question.label")}</Question>
										</QuestionText>
									</QuestionWrapper>
									<AnswerWrapper>
										<AnswerRadioButtons
											innerId="appointmentHonored"
											value={appointmentHonored}
											options={[true, false]}
											onChange={answer => setAppointmentHonored(answer)}
											itemRenderer={answer => (answer === true || answer === false ? getAnswerLabel(answer) : "")}
											buttonColorRenderer={answer => (answer ? colors.green : colors.orange)}
										/>
									</AnswerWrapper>
								</QuestionRow>
							)}
							<Buttons layout="position">
								<Button
									key="submit"
									type="submit"
									loading={loading}
									/*initial={{ opacity: 0 }}
									animate={{ opacity: 1, transition: { delay: 0.3 } }}*/
								>
									{format("section.anesthesiaDate.edit.validButton")}
								</Button>
								<Button
									secondary
									onClick={setEditingAndResetFields}
									type="button"
									/*initial={{ opacity: 0 }}
									animate={{ opacity: 1, transition: { delay: 0.3 } }}*/
								>
									{format("section.anesthesiaDate.edit.cancelButton")}
								</Button>
							</Buttons>
						</Info>
					</Section>
				</form>
			</AnimateSharedLayout>
		);
	}
);
AnesthesiaDateSectionEditionView.displayName = "AnesthesiaDateSectionEditionView";

const Subtitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Section = styled(motion.section)`
	${stack("L", "flex-start", "stretch")}
	padding: ${sizes.L};
	border-radius: 12px;
	box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
	background-color: ${colors.white};
`;

const Header = styled.div`
	${row("M", "flex-start", "baseline")}
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const Info = styled.div`
	${stack("M", "flex-start", "stretch")}
`;
const InfoField = styled(motion.div)`
	${row(0)};
	color: ${colors.black};
	font-size: 15px;

	> * {
		flex-grow: 1;
	}
`;

const Label = styled.div`
	font-size: 13px;
	color: ${colors.black};
	font-weight: bold;
`;

const Text = styled.div`
	color: ${colors.black};
	font-size: 15px;
`;

const InputWrapper = styled.div<{ spacing?: "S" }>`
	${props => stack(props.spacing ?? 0)}
`;

export const QuestionRow = styled.div`
	${row(0, "space-between", "center")}
	width: 100%;

	@media ${mobile} {
		${stack(0)};

		> *:not(:last-child) {
			margin-right: unset;
		}
	}
`;

const Question = styled.span`
	font-size: 15px;
	color: ${colors.black};
`;

const QuestionLabel = styled(Question)`
	font-weight: bold;
`;

const AnswerLabel = styled(QuestionLabel)``;

const QuestionText = styled.div``;

const QuestionWrapper = styled.div``;

const AnswerWrapper = styled.div``;

const AnswerRadioButtons = styled<RadioButtonsType<boolean | undefined>>(RadioButtonsInput)`
	${row("S")};
`;

const ConfirmButton = styled(Button)`
	white-space: nowrap;
`;
