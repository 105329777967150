import styled from "@emotion/styled";
import { Button } from "components/button";
import { EmailInput, PasswordInput } from "components/inputs/text-input";
import { UserType } from "domain/user-type";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { authService } from "services/auth-service";
import { colors, desktop, row, stack } from "style";
import { useI18n } from "utils/i18n";
import { WordingKey } from "utils/i18n-typings";
import { useQueryParams } from "utils/useQueryParams";
import  Home  from "@assets/icons/house.svg";

interface LoginWordingKeys {
	title: WordingKey;
	role: WordingKey;
	emailPlaceholder: WordingKey;
	passwordPlaceholder: WordingKey;
	recovery: WordingKey;
	login: WordingKey;
}
interface LoginFormProps {
	userType: UserType;
	wording: LoginWordingKeys;
	type?: "email" | "text";
	className?: string;
	showPasswordRecovery?: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({
	userType,
	type = "text",
	wording,
	className,
	showPasswordRecovery,
}) => {
	const history = useHistory();
	const redirectTo = useQueryParams().get("redirectTo");
	const { format } = useI18n();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState<{ code: number } | null>(null);
	const login = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event?.preventDefault();
			// Vider le local storage avant de s'authentifier et de re-setter le local storage à nouveau - probleme récurent de login le 19.02.22
			await authService.logout();
			try {
				setError(null);
				await authService.login(userType, email, password);
				history.push(
					(!redirectTo
						? redirectTo == "/hospital"
							? "/hospital/dashboard-admittance/practitioner/all/page/0"
							: redirectTo
						: redirectTo == "/hospital"
							? "/hospital/dashboard-admittance/practitioner/all/page/0"
							: redirectTo) ??
						(`/${userType}` == "/hospital" ? "/hospital/dashboard-admittance/practitioner/all/page/0" : `/${userType}`)
				);
			} catch (e: any) {
				console.log(e?.response);
				e?.response?.status && setError({ code: e?.response?.status });
			}
		},
		[userType, email, password, history, redirectTo]
	);

	return (
		<FormContainer className={className}>
			<Form onSubmit={login}>
				<Row to={`/`}>
					<HomeIcon/>
						<HomeLink>
							Accueil
						</HomeLink>
				</Row>
				<AppTitle>
					<img src="/images/logo.svg" />
					<div>SOPHI</div>
				</AppTitle>
				<Title>{format(wording.title)}<Role>{format(wording.role)}</Role></Title>
				<ErrorWrapper>
					<Fields>
						<EmailInput
							type={type}
							placeholder={format(wording.emailPlaceholder)}
							value={email}
							onChange={e => setEmail(e.target.value)}
							autoComplete="username"
						/>
						<Password>
							<PasswordInput
								placeholder={format(wording.passwordPlaceholder)}
								value={password}
								onChange={e => setPassword(e.target.value)}
								autoComplete="current-password"
							/>
							{showPasswordRecovery && (
								<Link to={`/${userType}/password-recovery`}>
									<PasswordRecoverLink>{format(wording.recovery)}</PasswordRecoverLink>
								</Link>
							)}
						</Password>
					</Fields>
					{error && (
						<ErrorMessage>
							{error.code === 401
								? type == "email"
									? format("login.error.incorrectEmail")
									: format("login.error.incorrectId")
								: format("login.error.unknownError")}
						</ErrorMessage>
					)}
				</ErrorWrapper>
				<Button>{format(wording.login)}</Button>
			</Form>
		</FormContainer>
	);
};

const AppTitle = styled.div`
	${row("S", "flex-start", "center")}
	font-size: 27px;
	font-weight: bold;
	color: ${colors.black};

	div {
		position: relative;
		top: -3px;
	}

	img {
		width: 29px;
		height: 25px;
	}
`;

const Title = styled.h1`
	font-size: 27px;
	max-width: 250px;
`;

const Role = styled.div`
	color: ${colors.pink};
	font-weight: bold;
`;
const FormContainer = styled.div`
	${row(0, "center", "center")};

	@media ${desktop} {
		width: calc(50vw - 100px);
	}
`;
const Form = styled.form`
	${stack("XL")};
`;
const Fields = styled.div`
	${stack("M", "flex-start", "stretch")};
`;
const Password = styled.div`
	${stack("S")}
`;
const PasswordRecoverLink = styled.span`
	font-size: 13px;
	font-style: italic;
	cursor: pointer;
`;

const ErrorMessage = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;

const ErrorWrapper = styled.div`
	${stack("S")};
	max-width: 250px;
`;
const HomeLink = styled.span`
    font-size: 15px;
    font-style: italic;
    cursor: pointer;
		text-decoration: underline;
    &:hover {
        color: ${colors.pink};
    }
`;


const HomeIcon = styled(Home)`
    width: 22px;
    height: 22px;
`;

const Row = styled(Link)`
  ${row(3, "flex-start", "flex-start")};
	cursor: pointer;
  &:hover {
    ${HomeLink} {
      color: ${colors.pink};
    }
    ${HomeIcon} {
        path {
            fill: ${colors.pink};
        }
    }
  }
`;