import styled from "@emotion/styled";
import { Button } from "components/button";
import { SectionTitle } from "components/section";
import React from "react";
import { ModalCardContainer, ModalCloseIcon, ModalProps } from "services/modal-service";
import { row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";

export const DeleteSurgeryModal: React.FC<ModalProps<boolean>> = ({ onClose }) => {
	const { format } = useI18n();

	return (
		<ModalContainer>
			<ModalCloseIcon onClick={() => onClose(false)} />
			<SectionTitle>{format("surgery.deleteModal.title")}</SectionTitle>
			<ContentParagraph>{format("surgery.deleteModal.text1")}</ContentParagraph>
			<ContentParagraph>{format("surgery.deleteModal.text2")}</ContentParagraph>
			<Spacer />
			<Buttons>
				<Button style={{ alignSelf: "center" }} secondary onClick={() => onClose(false)}>
					{format("surgery.deleteModal.cancelButton")}
				</Button>
				<Button style={{ alignSelf: "center" }} onClick={() => onClose(true)}>
					{format("surgery.deleteModal.confirmButton")}
				</Button>
			</Buttons>
		</ModalContainer>
	);
};

const ModalContainer = styled(ModalCardContainer)`
	padding: ${sizes.XL};
	${stack("XL", "flex-start", "stretch")};
	max-width: 600px;
`;

const Buttons = styled.div`
	${row("M", "flex-end", "stretch")}
`;

const ContentParagraph = styled.div``;
