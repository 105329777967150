import styled from "@emotion/styled";
import {Button} from "components/button";
import {FormField} from "components/form-field";
import {CheckboxInput} from "components/inputs/checkbox-input";
import {DateInput} from "components/inputs/date-input";
import {EmailInput, TextInput} from "components/inputs/text-input";
import {Patient} from "domain/patient";
import {fullName} from "domain/utils";
import {motion} from "framer-motion";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ModalCardContainer, ModalCloseIcon} from "services/modal-service";
import {PatientCreationDTO, practitionerService} from "services/practitioner-service";
import {colors, row, sizes, Spacer, stack} from "style";
import {capitalize, useI18n} from "utils/i18n";
import {isPatientMinor} from "utils/isPatientMinor";
import {normalizeDate} from "utils/time";
import {secretaryService} from "../../services/secretary-service";
import {userProfileService} from "../../services/user-profile-service";
import {patientService} from "../../services/patient-service";

interface CreatePatientModalProps {
	onClose: (patientId: string | null) => void;
	allowGuardians?: boolean;
	practitionerId?:string;
	isGuardian?: boolean;
	addTutorAfter?: boolean
	patient?: Patient | null;
}

interface PatientData {
	email: string;
	lastName: string;
	firstName: string;
	birthDate: string;
	phoneNumber1: string | null;
}

enum FormStep {
	Patient = "Patient",
	FirstGuardian = "FirstGuardian",
	SecondGuardian = "SecondGuardian",
}

export const CreatePatientModal: React.FC<CreatePatientModalProps> = ({ onClose, allowGuardians = true, practitionerId , isGuardian = false, patient}) => {
	const { format } = useI18n();
	const [existingPatientByEmail, setExistingPatientByEmail] = useState<Patient[] | null>(null);
	const [existingPatientsByIdentity, setExistingPatientsByIdentity] = useState<Patient[] | null>(null);
	const [step, setStep] = useState(FormStep.Patient);

	const [patientData, setPatientData] = useState<PatientData>({
		email: "",
		firstName: "",
		lastName: "",
		birthDate: "",
		phoneNumber1: null,
	});
	const [firstGuardianData, setFirstGuardianData] = useState<PatientData>({
		email: "",
		firstName: "",
		lastName: "",
		birthDate: "",
		phoneNumber1: null,
	});
	const [secondGuardianData, setSecondGuardianData] = useState<PatientData>({
		email: "",
		firstName: "",
		lastName: "",
		birthDate: "",
		phoneNumber1: null,
	});
	const [firstGuardianId, setFirstGuardianId] = useState<string | null>(null);
	const [secondGuardianId, setSecondGuardianId] = useState<string | null>(null);
	const [noSecondGuardian, setNoSecondGuardian] = useState<boolean>(false);
	const [patientHasGuardians, setPatientHasGuardians] = useState(false);

	const patientBirthDateRef = useRef("");
	useEffect(() => {
		if (patientBirthDateRef.current !== patientData.birthDate) {
			patientBirthDateRef.current = patientData.birthDate;
			if (isPatientMinor(new Date(normalizeDate(patientData.birthDate)), new Date()) && !isGuardian) {
				setPatientHasGuardians(true);
			}
		}
	}, [patientData.birthDate]);

	useEffect(() => {
		setExistingPatientByEmail(null);
	}, [patientData.email]);

	useEffect(() => {
		setExistingPatientsByIdentity(null);
	}, [patientData.firstName, patientData.lastName, patientData.birthDate, patientData.phoneNumber1]);

	const checkPatientIsUnique = useCallback(async (patientData: PatientData, allowGuardians = true) => {
		if (patientData.email) {
			let existingPatientByEmailResult;
			if(practitionerId){
				existingPatientByEmailResult = await secretaryService.getAllPatientByEmail(patientData.email);
			}else{
				existingPatientByEmailResult = await practitionerService.getAllPatientByEmail(patientData.email);
			}
			if (existingPatientByEmailResult.length > 0 && allowGuardians) {
				setExistingPatientByEmail(existingPatientByEmailResult);
				return false;
			}
		}
		if (patientData.firstName && patientData.lastName && patientData.birthDate) {
			let existingPatientsByIdentityResult
			if(practitionerId){
				existingPatientsByIdentityResult = await secretaryService.getAllPatientByIdentity(
					patientData.firstName,
					patientData.lastName,
					normalizeDate(patientData.birthDate)
				);
			}else{
				existingPatientsByIdentityResult = await practitionerService.getAllPatientByIdentity(
					patientData.firstName,
					patientData.lastName,
					normalizeDate(patientData.birthDate)
				);
			}
			if (existingPatientsByIdentityResult.length > 0) {
				setExistingPatientsByIdentity(existingPatientsByIdentityResult);
				return false;
			}
		}
		return true;
	}, [practitionerId]);

	const createPatient = useCallback(
		async (
			patientData: PatientData,
			legalGuardian1Id: string | null,
			legalGuardian2Id: string | null,
			assign: boolean,
			addTutorAfter: boolean | null,
			tutoredPatient: Patient | null,
		) => {
			try {
				setExistingPatientByEmail(null);
				setExistingPatientsByIdentity(null);
				const createPatientData: PatientCreationDTO = { ...patientData, assign };
				if (legalGuardian1Id) {
					createPatientData.legalGuardian1Id = legalGuardian1Id;
				}
				if (legalGuardian2Id) {
					createPatientData.legalGuardian2Id = legalGuardian2Id;
				}
				if (createPatientData.email == "") {
					createPatientData.email = undefined;
				}
				createPatientData.birthDate = normalizeDate(createPatientData.birthDate);
				createPatientData.addTutorAfter = addTutorAfter
				createPatientData.tutoredPatient = tutoredPatient
				let patient
				if(practitionerId){
					patient = await secretaryService.createPatient(createPatientData, practitionerId);
				}else{
					patient = await practitionerService.createPatient(createPatientData);
				}
				const userProfile = await userProfileService.createUserProfile(patient.id, 'patient');
				await patientService.assignUserProfile(userProfile);
				return patient.id;
			} catch (e) {
				console.log(e);
			}
			return null;
		},
		[practitionerId]
	);

	const createPatientWithGuardians = useCallback(async () => {
		let legalGuardian1Id = firstGuardianId;
		let legalGuardian2Id = secondGuardianId;
		if (!firstGuardianId) {
			const id = await createPatient(firstGuardianData, null, null, false, null, null);
			if (id) {
				legalGuardian1Id = id;
			}
		}
		if (!secondGuardianId && !noSecondGuardian) {
			const id = await createPatient(secondGuardianData, null, null, false, null, null);
			if (id) {
				legalGuardian2Id = id;
			}
		}
		return createPatient(patientData, legalGuardian1Id, legalGuardian2Id, true, null, null);
	}, [
		createPatient,
		firstGuardianData,
		firstGuardianId,
		noSecondGuardian,
		patientData,
		secondGuardianData,
		secondGuardianId,
	]);

	useEffect(() => {
		setExistingPatientByEmail(null);
		setExistingPatientsByIdentity(null);
	}, [step]);

	const assignPatient = useCallback(
		async (id: string) => {
			if(practitionerId){
				await secretaryService.assignPatient(id, practitionerId);
			}else{
				await practitionerService.assignPatient(id);
			}
			onClose(id);
		},
		[onClose, practitionerId]
	);

	if(patientHasGuardians) {
		patientData.email = ""
		patientData.phoneNumber1 = null
	}

	return (
		<Card>
			<ModalCloseIcon onClick={() => onClose(null)} />
			<Title>{format("practitioner.createPatient.title")}</Title>
			<Content>
				{step === FormStep.Patient ? (
					<>
						<PatientDataForm
							step={patientHasGuardians ? FormStep.Patient : undefined}
							value={patientData}
							onChange={setPatientData}
							patientHasGuardians={patientHasGuardians}
							onPatientHasGuardiansChange={allowGuardians ? setPatientHasGuardians : undefined}
							onCancel={() => onClose(null)}
							onSubmit={async () => {
								if (await checkPatientIsUnique(patientData, allowGuardians)) {
									if (patientHasGuardians) {
										setStep(FormStep.FirstGuardian);
									} else {
										const patientId = await createPatient(patientData, null, null, true, true, patient ?? null);
										onClose(patientId);
									}
								}
							}}
						/>
						{(existingPatientByEmail === null) && existingPatientsByIdentity === null ? (
							<SophiImage src="/icons/sophi-illustration.svg" />
						) : (
							<PatientReconciliation
								existingPatientByEmail={existingPatientByEmail}
								existingPatientsByIdentity={existingPatientsByIdentity}
								onCreatePatient={async () => {
									if (patientHasGuardians) {
										setStep(FormStep.FirstGuardian);
									} else {
										const patientId = await createPatient(patientData, null, null, true, true, patient ?? null);
										onClose(patientId);
									}
								}}
								onUsePatient={async (id: string) => {
									if (patientHasGuardians) {
										setStep(FormStep.FirstGuardian);
									} else {
										await assignPatient(id);
										onClose(id);
									}
								}}
							/>
						)}
					</>
				) : step === FormStep.FirstGuardian ? (
					<>
						<PatientDataForm
							step={FormStep.FirstGuardian}
							value={firstGuardianData}
							onChange={setFirstGuardianData}
							onCancel={() => onClose(null)}
							onPrevious={() => setStep(FormStep.Patient)}
							onSubmit={async () => {
								if (await checkPatientIsUnique(firstGuardianData)) {
									setStep(FormStep.SecondGuardian);
								}
							}}
						/>
						{existingPatientByEmail === null && existingPatientsByIdentity === null ? (
							<SophiImage src="/icons/sophi-illustration.svg" />
						) : (
							<PatientReconciliation
								existingPatientByEmail={existingPatientByEmail}
								existingPatientsByIdentity={existingPatientsByIdentity}
								onCreatePatient={async () => {
									setStep(FormStep.SecondGuardian);
								}}
								onUsePatient={async (id: string) => {
									setFirstGuardianId(id);
									setStep(FormStep.SecondGuardian);
								}}
							/>
						)}
					</>
				) : (
					// step === FormStep.SecondGuardian
					<>
						<PatientDataForm
							step={FormStep.SecondGuardian}
							value={secondGuardianData}
							onChange={setSecondGuardianData}
							ignore={noSecondGuardian}
							onIgnoreChange={value => setNoSecondGuardian(value)}
							onCancel={() => onClose(null)}
							onPrevious={() => setStep(FormStep.FirstGuardian)}
							onSubmit={async () => {
								if (await checkPatientIsUnique(secondGuardianData)) {
									const id = await createPatientWithGuardians();
									onClose(id);
								}
							}}
						/>
						{existingPatientByEmail === null && existingPatientsByIdentity === null ? (
							<SophiImage src="/icons/sophi-illustration.svg" />
						) : (
							<PatientReconciliation
								existingPatientByEmail={existingPatientByEmail}
								existingPatientsByIdentity={existingPatientsByIdentity}
								onCreatePatient={async () => {
									const id = await createPatientWithGuardians();
									onClose(id);
								}}
								onUsePatient={async (id: string) => {
									setSecondGuardianId(id);
									const patientId = await createPatientWithGuardians();
									onClose(patientId);
								}}
							/>
						)}
					</>
				)}
			</Content>
		</Card>
	);
};

const PatientDataForm: React.FC<{
	onSubmit?: () => void;
	onCancel?: () => void;
	onPrevious?: () => void;
	value: PatientData;
	step?: FormStep;
	onChange?: React.Dispatch<React.SetStateAction<PatientData>>;
	loading?: boolean;
	disabled?: boolean;
	patientHasGuardians?: boolean;
	onPatientHasGuardiansChange?: (patientHasLegalGuardians: boolean) => void;
	ignore?: boolean;
	onIgnoreChange?: (noSecondGuardian: boolean) => void;
}> = ({
	value,
	step,
	onChange,
	onSubmit,
	onCancel,
	onPrevious,
	patientHasGuardians,
	onPatientHasGuardiansChange,
	onIgnoreChange,
	ignore,
	loading,
	disabled,
}) => {
	const { format } = useI18n();
	return (
		<Form
			onSubmit={e => {
				e.preventDefault();
				onSubmit?.();
			}}
		>
			<TopPart>
				{step ? <FormFlow step={step} /> : <Description>{format("practitioner.createPatient.incentive")}</Description>}
			</TopPart>
			<Fields>
				<TextInputField
					placeholder={format("practitioner.createPatient.firstNamePlaceholder")}
					value={disabled ? "" : value.firstName}
					autoCapitalize="words"
					autoFocus
					onChange={e => {
						const firstName = capitalize(e.target.value);
						onChange?.(patient => ({ ...patient, firstName }));
					}}
					required={!ignore}
					disabled={ignore}
				/>
				<TextInputField
					placeholder={format("practitioner.createPatient.lastNamePlaceholder")}
					value={disabled ? "" : value.lastName}
					autoCapitalize="characters"
					onChange={e => {
						const lastName = e.target.value.toUpperCase();
						onChange?.(patient => ({ ...patient, lastName }));
					}}
					required={!ignore}
					disabled={ignore}
				/>
				<FormField label={format("practitioner.createPatient.birthDatePlaceholder")}>
					<DateInput
						value={disabled ? "" : value.birthDate}
						onChange={e => {
							const birthDate = e.target.value;
							onChange?.(patient => ({ ...patient, birthDate }));
						}}
						required={!ignore}
						disabled={ignore}
					/>
				</FormField>
				<EmailInputField
					placeholder={
						!(ignore || (step === FormStep.Patient && (patientHasGuardians ?? false)))
							? format("practitioner.createPatient.requiredEmailPlaceholder")
							: format("practitioner.createPatient.emailPlaceholder")
					}
					value={patientHasGuardians ? "" : value.email}
					onChange={(e) => {
						const email = e.target.value;
						onChange?.(patient => ({ ...patient, email }));
					}}
					autoComplete="username"
					required={!(ignore || (step === FormStep.Patient && (patientHasGuardians ?? false)))}
					disabled={(ignore || (step === FormStep.Patient && (patientHasGuardians ?? false)))}
				/>
				<TextInputField
					placeholder={format("patient.dashboard.info.phoneNumber1Placeholder")}
					value={patientHasGuardians ? "" : value.phoneNumber1 ?? ""}
					pattern="^(06|07|\+336|\+337|00336|00337)[0-9]{8}$"
					onChange={e => {
						const phoneNumber1: string | null = e.target.value.toUpperCase();
						onChange?.(patient => ({ ...patient, phoneNumber1 }));
					}}
					required={!(ignore || (step === FormStep.Patient && (patientHasGuardians ?? false)))}
					disabled={(ignore || (step === FormStep.Patient && (patientHasGuardians ?? false)))}
				/>
				{onPatientHasGuardiansChange && (
					<CheckboxInput value={patientHasGuardians ?? false} onChange={onPatientHasGuardiansChange}>
						Le patient est mineur ou sous tutelle
					</CheckboxInput>
				)}
				{onIgnoreChange && (
					<CheckboxInput value={ignore ?? false} onChange={onIgnoreChange}>
						Ignorer (un seul tuteur)
					</CheckboxInput>
				)}
			</Fields>
			{patientHasGuardians || step === FormStep.FirstGuardian || step === FormStep.SecondGuardian ? (
				<ButtonContainer>
					{step === FormStep.Patient ? (
						<Button secondary type="button" onClick={onCancel}>
							{format("practitioner.createPatient.cancelButton")}
						</Button>
					) : (
						<Button secondary type="button" onClick={onPrevious}>
							{format("practitioner.createPatient.previousButton")}
						</Button>
					)}
					{step === FormStep.SecondGuardian ? (
						<Button type="submit" loading={loading} disabled={disabled}>
							{format("practitioner.createPatient.createButton")}
						</Button>
					) : (
						<Button type="submit" loading={loading} disabled={disabled}>
							{format("practitioner.createPatient.nextButton")}
						</Button>
					)}
				</ButtonContainer>
			) : (
				<ButtonContainer>
					<Button
						secondary
						type="button"
						onClick={async e => {
							e.preventDefault();
							onCancel?.();
						}}
					>
						{format("practitioner.createPatient.cancelButton")}
					</Button>
					<Button type="submit" loading={loading} disabled={disabled}>
						{format("practitioner.createPatient.createButton")}
					</Button>
				</ButtonContainer>
			)}
		</Form>
	);
};

const FormFlow: React.FC<{ step: FormStep }> = ({ step }) => {
	const { format } = useI18n();
	return (
		<FlowContainer>
			<FlowStep active={step === FormStep.Patient}>{format("practitioner.createPatient.steps.patient")}</FlowStep>
			<FlowStep active={step === FormStep.FirstGuardian}>
				{format("practitioner.createPatient.steps.firstGuardian")}
			</FlowStep>
			<FlowStep active={step === FormStep.SecondGuardian}>
				{format("practitioner.createPatient.steps.secondGuardian")}
			</FlowStep>
		</FlowContainer>
	);
};

const PatientReconciliation: React.FC<{
	existingPatientByEmail?: Patient[] | null;
	existingPatientsByIdentity?: Patient[] | null;
	onCreatePatient?: () => void;
	onUsePatient?: (id: string) => void;
}> = ({ existingPatientByEmail, existingPatientsByIdentity, onCreatePatient, onUsePatient }) => {
	const { format, formatDate } = useI18n();
	return (
		<PatientReconciliationContainer initial={{ opacity: 0, x: -30 }} animate={{ opacity: 1, x: 0 }}>
			{!!existingPatientByEmail?.length && (
				<>
					<Description>{format("practitioner.createPatient.emailAlreadyExists")}</Description>
					<ExistingPatients>
						{existingPatientByEmail?.map(patient => (
							<ExistingPatient key={patient.id}>
								<ExistingPatientName>
									{fullName(patient)}
								</ExistingPatientName>
								<ExistingPatientBirthDate>{formatDate(patient.birthDate)}</ExistingPatientBirthDate>
								<ExistingPatientEmail>{patient.email}</ExistingPatientEmail>
								<Button onClick={() => onUsePatient?.(patient.id)}>Utiliser</Button>
							</ExistingPatient>
						))}
					</ExistingPatients>
				</>
			)}
			{existingPatientsByIdentity?.length && (
				<>
					<Description>{format("practitioner.createPatient.identityAlreadyExists")}</Description>
					<ExistingPatients>
						{existingPatientsByIdentity?.map(patient => (
							<ExistingPatient key={patient.id}>
								<ExistingPatientName>
									{fullName(patient)}
								</ExistingPatientName>
								<ExistingPatientBirthDate>{formatDate(patient.birthDate)}</ExistingPatientBirthDate>
								<ExistingPatientEmail>{patient.email}</ExistingPatientEmail>
								<Button onClick={() => onUsePatient?.(patient.id)}>Utiliser</Button>
							</ExistingPatient>
						))}
					</ExistingPatients>
				</>
			)}
			<Spacer />
			{!existingPatientByEmail?.length && (
				<Button onClick={onCreatePatient}>{format("practitioner.createPatient.createAnywayButton")}</Button>
			)}
		</PatientReconciliationContainer>
	);
};

const FlowContainer = styled.div`
	${row("M")};
`;

const FlowStep = styled.div<{ active: boolean }>`
	font-weight: ${props => (props.active ? 700 : 400)};
	color: ${props => (props.active ? colors.black : colors.grey2)};
`;

const TopPart = styled.div`
	height: 50px;
	${row(0, "flex-start", "center")}
`;

const Card = styled(ModalCardContainer)`
	padding: 63px 80px;
	${stack("M")};

	@media (max-width: 920px) {
		padding: ${sizes.L};
	}
`;

const Title = styled.h1`
	font-size: 27px;
	max-width: 300px;
	color: ${colors.black};
`;

const Description = styled.div`
	max-width: 300px;
	font-size: 15px;
	color: ${colors.black};
`;

const Content = styled.div`
	${row(0, "center", "center")};
`;

const Form = styled.form`
	${stack("XL")}
`;

const Fields = styled.div`
	${stack("M", "flex-start", "stretch")};
	min-width: 230px;
`;

const ButtonContainer = styled.div`
	${row("M")}
`;

const SophiImage = styled.img`
	width: 330px;
	margin-left: 30px;

	@media (max-width: 720px) {
		display: none;
	}
`;

const PatientReconciliationContainer = styled(motion.div)`
	min-width: 300px;
	flex-grow: 1;
	${stack("M", "flex-start", "flex-end")};
	align-self: stretch;
	margin-left: 30px;
`;

const ExistingPatient = styled.div`
	display: grid;
	${stack("S")}
	padding: ${sizes.S};
	max-width: 100%;
	overflow: hidden;
	align-self: stretch;
	flex-shrink: 0;

	&:not(:last-child) {
		border-bottom: 1px solid ${colors.grey};
	}

	button {
		align-self: flex-end;
	}
`;

const ExistingPatientName = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
`;

const ExistingPatientBirthDate = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
`;

const ExistingPatients = styled.div`
	${stack("M", "flex-start", "stretch")};
	align-self: stretch;
	max-height: 260px;
	overflow-y: auto;
	border: 2px solid ${colors.grey};
	border-radius: 9px;
	flex-grow: 1;
`;

const ExistingPatientEmail = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
`;

const TextInputField = styled(TextInput)`
	::placeholder {
		font-size: 13px;
		color: #000000;
	}
`;

const EmailInputField = styled(EmailInput)`
	::placeholder {
		font-size: 13px;
		color: #000000;
	}
`;
