import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import {  ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { AnimateSharedLayout, motion } from "framer-motion";
import { Document } from "domain/document";
import { colors, mobile, row, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { fullName } from "../../domain/utils";
import { useObservable } from "micro-observables";
import { patientService } from "../../services/patient-service";
import axios from "axios";
import { downloadFile } from "../../utils/web";
import ArrowDownloadIcon from "@assets/icons/download-arrow.svg";
import { Button } from "../button";
import { Surgery } from "../../domain/surgery";
import { RadioButtonsInput, RadioButtonsInputProps } from "../inputs/radio-buttons-input";

export const MySignModal: React.FC<{
	surgeryId: string;
	surgeryDate: string;
	documents: Document[];
	onClose: (result?: string) => void;
	surgery: Surgery | undefined;
}> = ({ surgeryId, surgeryDate, documents, onClose, surgery }) => {
	const { format, formatDate } = useI18n();
	const patient = useObservable(patientService.patient);
	const [count, setCounter] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const documentsTosign =
		documents.length > 0
			? documents
			: surgery?.surgeryDocuments.filter(documents => documents.needSignature && !documents.isSigned);
	const docLength = documentsTosign ? Object.keys(documentsTosign).length : 0;
	const [signatureProcess, setSignatureProcess] = useState(false);
	const [timerStatus, setTimerStatus] = useState(false);
	const [error, setError] = useState<{ code: number } | null>(null);
	const [apiCodeError, setApiCodeError] = useState<string>("");
	const [loop, setLoop] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [smsType, setSmsType] = useState<boolean>(false);
	const [removeOnclose, setRemoveOnclose] = useState<boolean>(false);

	// start Process API HSS
	const getGUIDApi = useCallback(async () => {
		try {
			setLoading(true);
			setError(null);
			setLoop(true)
			setApiCodeError(await patientService.signDocumentApiV3(surgeryId, smsType));
		} catch (e: any) {
			setLoading(false);
			setLoop(false)
			setError({ code: e?.response?.status });
		}

	}, [apiCodeError, surgeryId, smsType]);

	// Lance un check tous les 5 secondes
	const checkContract = useCallback(async (surgeryId) => {
		try {
			if (surgery?.id != undefined) {
				const response = await patientService.etatContratV3(surgeryId);
				// affichage du code erreur retour
				if (response != "30") {
					setLoop(true)
				} if (response == "99") {
					setLoop(false)
					alert("Il y a eu une erreur")
					onClose()
				} else if (response == "30") {
					setLoop(false)
					onClose()
					await patientService.fetchSurgeries();
				}
			}
		} catch (error) {
			console.error('Erreur check signature:', error);
		}
	}, [surgeryId]);

	// signer
	useEffect(() => {
		let intervalId: NodeJS.Timeout;
		let timeoutId: NodeJS.Timeout;

		// on stop la boucle d'appel de check au bout de 5 minutes
		const stopFetching = () => {
			clearInterval(intervalId);
			setLoop(false);
			onClose()
		};

		if (loop) {
			intervalId = setInterval(() => {
				checkContract(surgeryId).then();
			}, 12000);
			timeoutId = setTimeout(stopFetching, 5 * 60 * 1000); // 5 minutes
		}

		return () => {
			clearInterval(intervalId);
			clearTimeout(timeoutId);
		};
	}, [checkContract, loop, apiCodeError, loading, surgery, refresh]);

	const createCanvas = async () => {
		try {
			setTimeout(() => setTimerStatus(true), 300000);
			setSignatureProcess(true);
		} catch (e) {
			console.log(e);
		}
	};

	const decrement = () => {
		try {
			if (count > 0 && count <= docLength - 1) {
				setEdit(false);
				setCounter(count - 1);
			}
		} catch (e: any) {
			throw e.message;
		} finally {
		}
	};

	const increment = () => {
		try {
			if (count < docLength - 1) setCounter(count + 1);
			if (count == docLength - 2) setEdit(true);
		} catch (e: any) {
			throw e.message;
		} finally {
		}
	};

	return (
		<AnimateSharedLayout>
			<Card layout>
				{!removeOnclose &&
					(<ModalCloseIcon onClick={() => onClose()} />
					)}

				{signatureProcess ? (
					<Form>
						<Header>
							<Title>{format("patient.signModal.title")}</Title>
							<DescriptionWrapper>
								<Spacer />
								<PatientData>
									{patient && <PatientName>{fullName(patient)}</PatientName>}
									<PatientSurgery>
										{format("hospital.validationModal.surgeryDate")}{" "}
										{formatDate(surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })}
									</PatientSurgery>
								</PatientData>
							</DescriptionWrapper>
						</Header>
						<Content>
							<SendInBlue>
								<TitleContent>
									<VerificationTitle>{format("patient.signModal.verificationTitle")}</VerificationTitle>
								</TitleContent>
								<Instruction>
									<InfosSign>{format("patient.signModal.sign")}</InfosSign>

									<AnswerWrapper>
										<AnswerRadioButtons
											innerId="answer-radio-sms"
											required
											value={smsType}
											options={[true, false]}
											onChange={smsType => {
												if (smsType != null)
													setSmsType(smsType)
											}}
											itemRenderer={(item) => {
												if (item === false)
													return "Par Email"
												else
													return "Par SMS"
											}}
										/>
									</AnswerWrapper>
								</Instruction>
								<FinalSignButton
									key="validate"
									layout="position"
									loading={loading}
									onClick={e => {
										e.preventDefault();
										getGUIDApi().then();
									}}
								>
									{format("patient.signModal.signDocuments")}
								</FinalSignButton>
							</SendInBlue>

							{loop ? (
								<TextActionToDo>
									{format("patient.signModal.signataire")}
								</TextActionToDo>)
								:
								null
							}

						</Content>
					</Form>
				) : (
					<Regular>
						<Header>
							<Title>{format("patient.signModal.title")}</Title>
							<DescriptionWrapper>
								<Spacer />
								<PatientData>
									{patient && <PatientName>{fullName(patient)}</PatientName>}
									<PatientSurgery>
										{format("hospital.validationModal.surgeryDate")}{" "}
										{formatDate(surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })}
									</PatientSurgery>
								</PatientData>
							</DescriptionWrapper>
						</Header>
						<Body>
							<FolderContainer>
								{documentsTosign &&
									documentsTosign.map((doc, docIndex) => (
										<ShowDocuments key={docIndex} current={count == docIndex}>
											{doc.documentType.label}
											<ArrowDownload
												onClick={async () => {
													const JSZip = await import("jszip");
													// eslint-disable-next-line @typescript-eslint/ban-ts-comment
													//@ts-ignore
													const zip = new JSZip();
													if (!doc.url) {
														return null;
													}
													const result = await axios.get(doc.url, { responseType: "arraybuffer" });
													const filename = `${doc.documentType.label}.pdf`;
													zip.file(filename, result.data);
													const archive = await zip.generateAsync({ type: "blob" });
													downloadFile(`${filename}`, archive);
												}}
											/>
										</ShowDocuments>
									))}
							</FolderContainer>
							<FileViewer>
								<object
									data={documentsTosign && documentsTosign[count].url}
									type="application/pdf"
									width="100%"
									height="100%"
								>
									<iframe src={documentsTosign && documentsTosign[count].url} width="100%" height="100%"></iframe>
								</object>
							</FileViewer>
						</Body>

						<ButtonContainer>
							{count > 0 && (
								<PreviousButton loading={loading} onClick={decrement}>
									{format("patient.signModal.previousDocument")}
								</PreviousButton>
							)}
							{edit || docLength == 1 ? (
								<SignButton
									key="validate"
									layout="position"
									loading={loading}
									onClick={async e => {
										e.preventDefault();
										await createCanvas();
										setRemoveOnclose(true);
									}}
								>
									{format("patient.signModal.signDocuments")}
								</SignButton>
							) : (
								<NextButton key="validate" layout="position" loading={loading} onClick={increment}>
									{format("patient.signModal.nextDocument")}
								</NextButton>
							)}
						</ButtonContainer>
					</Regular>
				)}
			</Card>
		</AnimateSharedLayout>
	);
};

// region style
const Form = styled.form`
    width: 100%;
    height: 100%;
`;

const Card = styled(motion.custom(ModalCardContainer))`
    ${stack("M")};
    width: 96%;
    height: 96%;
    padding: 10px 50px;

    @media (max-width: 920px) {
        width: 100%;
        height: 100%;
        padding: 1px;
    }
`;

const Regular = styled.div`
    width: 100%;
    height: 100%;
    @media (max-width: 920px) {
        overflow: auto;
        width: 100%;
        height: 100%;
    }
`;

const Header = styled.div`
    ${stack("S")};
    height: 15%;
    width: 100%;
`;

const Instruction = styled.div`
    margin: auto;
		font-size: 22px;
`;

const TitleContent = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
`;

const InfosSign = styled.div`
    margin: auto;
		font-size: 22px;
`;

const Content = styled.div`
    height: 70%;
    width: 100%;
`;

const Body = styled.div`
    ${row("S", "flex-start", "flex-start")};
    width: 100%;
    height: 70%;
    position: relative;
    @media (max-width: 920px) {
        height: 40%;
    }
`;

//		HEADER		//

const Title = styled.h1`
    font-size: 32px;
    max-width: 600px;
    color: ${colors.black};
    @media ${mobile} {
        font-size: 24px;
    }
`;

const DescriptionWrapper = styled.div`
    ${row("S")};
    width: 100%;
`;

const PatientData = styled.div`
    ${stack(0, "flex-start", "flex-end")}
`;

const PatientName = styled.div`
    font-size: 26px;
    font-weight: bold;
    color: ${colors.black};
    @media ${mobile} {
        font-size: 20px;
    }
`;

const PatientSurgery = styled.div`
    font-size: 15px;
    color: ${colors.black};
`;

//		BODY		//

const FolderContainer = styled.div`
    ${stack("S", "flex-start", "flex-start")};
    max-height: 100%;
    width: 15%;
    overflow: auto;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 20px;
    border: 2px solid grey;
    border-radius: 13px;

    @media (max-width: 920px) {
        display: none;
    }
`;

const ShowDocuments = styled.div<{ current?: boolean }>`
    background-color: ${props => (props.current ? "#D3D3D3" : null)};
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 90%;
    height: 80%;
    ${row("M", "space-between", "center")};
`;

const ArrowDownload = styled(ArrowDownloadIcon)`
    height: 20px;
    width: 20px;
    path,
    rect {
        fill: ${colors.green};
    }
`;

const FileViewer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    border: none;
    @media ${mobile} {
        height: 90%;
    }
`;

//		FOOTER		//

const ButtonContainer = styled.div`
    ${stack("S", "center", "center")}
		margin: 10px;
`;

const NextButton = styled(Button)`
    background-color: ${colors.green};
`;

const PreviousButton = styled(Button)`
    background-color: ${colors.green};
`;

//					//
//		ELEMENT		//
//					//

const SignButton = styled(Button)`
    margin: auto;
`;

const FinalSignButton = styled(Button)`
		margin: auto;
`;

const VerificationTitle = styled.h1`
    text-align: center;
    font-size: 28px;
    color: ${colors.black};
`;

const SendInBlue = styled.div`
    @media (min-width: 920px) {
        width: 40%;
        min-height: 70%;
    }
    ${stack("M")};
    display: flex;
    padding: 35px;
    background-color: #f0f0f0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    border-radius: 26px;
    border: none;
`;

const AnswerWrapper = styled.div`
    margin-top: 40px;
		text-align: center;
    display: block ruby;
`;

const TextActionToDo = styled.div`
    color: orange;
		margin: auto;
    text-align: center;
    display: block;
`;

type AnswerProps = React.FC<RadioButtonsInputProps<boolean | null>>;
const AnswerRadioButtons = styled<AnswerProps>(RadioButtonsInput)``;
