import React, { useCallback, useEffect, useState } from "react";
import {colors, desktop, mobile, row, sizes, stack} from "../../style";
import styled from "@emotion/styled";
import FolderIcon from "@assets/icons/folder.svg";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import { Document, SurgeryDocumentAdmission, SurgeryDocumentValidationStatus } from "domain/document";
import { DocumentBigCard } from "../documents/document-big-card";
import { practitionerService } from "../../services/practitioner-service";
import { useUser } from "../../services/auth-service";
import { secretaryService } from "../../services/secretary-service";
import { hpuService } from "../../services/hpu-service";
import {Button} from "../button";

interface DocumentsCategoriesProps {
	documents: Document[];
	title: string;
	surgeryId: string;
	cardsColor: string;
	showAll: boolean | null;
	practitionerId?: string;
	onShowed?: (bool: boolean | null) => void;
}

export const DocumentsCategories: React.FC<DocumentsCategoriesProps> = ({
	documents,
	title,
	surgeryId,
	cardsColor,
	showAll,
	practitionerId,
	onShowed,
}) => {
	const user = useUser();
	const [showCategory, setShowCategory] = useState(true);
	useEffect(() => {
		if (showAll) {
			setShowCategory(true);
		} else if (showAll == null) {
		} else {
			setShowCategory(false);
		}
	}, [showAll]);

	const setShowAll = useCallback(() => {
		showAll = null;
		onShowed?.(showAll);
	}, []);
	const updateDocument = useCallback(
		async (document: Document) => {
			switch (user?.type) {
				case "practitioner": {
					await practitionerService.updateDocument(surgeryId, document.id, document);
					await practitionerService.fetchSurgery(surgeryId);
					break;
				}
				case "secretary": {
					await secretaryService.updateDocument(surgeryId, document.id, practitionerId ?? "", document);
					await secretaryService.fetchSurgery(surgeryId, practitionerId ?? "");
					break;
				}
				case "hpu": {
					await hpuService.updateDocument(surgeryId, document.id, document);
					await hpuService.fetchSurgery(surgeryId);
					break;
				}
				default:
					break;
			}
		},
		[surgeryId]
	);
	const deleteDocument = useCallback(
		async (documentId: string) => {
			switch (user?.type) {
				case "practitioner": {
					await practitionerService.deleteDocument(surgeryId, documentId);
					await practitionerService.fetchSurgery(surgeryId);
					break;
				}
				case "secretary": {
					await secretaryService.deleteDocument(surgeryId, documentId, practitionerId ?? "");
					await secretaryService.fetchSurgery(surgeryId, practitionerId ?? "");
					break;
				}
				case "hpu": {
					await hpuService.deleteDocument(surgeryId, documentId);
					await hpuService.fetchSurgery(surgeryId);
					break;
				}
				default:
					break;
			}
		},
		[surgeryId]
	);
	return (
		<Categories>
			<Container
				onClick={() => {
					setShowCategory(!showCategory);
					setShowAll();
				}}
			>
				<SmallFolderIcon src="/icons/folder.svg" iconcolor={colors.black} />
				<Text>{title}</Text>
				<ArrowIcon src="/icons/back.svg" showed={showCategory} />
			</Container>
			<CategoriesRow>
				{documents
					.sort((docA, docB) => (docA.documentType.position > docB.documentType.position ? 1 : -1))
					.filter(doc => title === doc.documentType.type)
					.map((document, documentIndex) => {
						return (
							document.weblinkUrl ? (
								<WeblinkLineCategory showed={showCategory} key={documentIndex}>
									<div style={{ maxWidth: "330px" }}>
										&bull; &nbsp;
										{/*-&nbsp;*/}
										<WebLink target="_blank" href={document.weblinkUrl}>
											{document.description?.slice(0, 39) + (document.description && document.description?.length > 39 ? "..." : "")}
											<PlayButton third>
												<PlayButtonIcon src="/icons/play.svg"></PlayButtonIcon>
											</PlayButton>
										</WebLink>
										<TrashButton
											onClick={ async () => {
												await deleteDocument(document.id);
											}}
										>
											<TrashButtonIcon src="/icons/effacer.svg" style={{ fill: colors.white, color: colors.white, stroke: colors.white }}></TrashButtonIcon>
										</TrashButton>
									</div>
								</WeblinkLineCategory>
							) : (
								<LineCategory showed={showCategory} key={documentIndex}>
									<DocumentCard
										color={
											document.validationStatus == SurgeryDocumentValidationStatus.Rejected
												? "rgba(255,111,122,0.7)"
												: document.status != null && document.status != SurgeryDocumentAdmission.Completed
												? colors.lightGrey
												: cardsColor
										}
										document={document}
										onDelete={() => deleteDocument(document.id)}
										onChange={it => updateDocument(it)}
										surgeryId={surgeryId}
										key={document.id}
									/>
								</LineCategory>
						));
					})}
			</CategoriesRow>
		</Categories>
	);
};

const Categories = styled.div`
	${stack("L", "flex-start", "stretch")}
`;

const CategoriesRow = styled.div`
	${row("S", "flex-start", "flex-start")}
	flex-wrap: wrap;
	transition: all 0.5s ease-in-out;
`;

const Container = styled.div`
	${row("S", "flex-start", "center")}
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	@media ${mobile} {
		padding: ${sizes.M};
		${stack("M", "flex-start", "flex-start")};
		margin-left: -20px;
		margin-right: -20px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;

const SmallFolderIcon = styled(FolderIcon)<{ iconcolor?: string }>`
	width: 24px;
	path {
		fill: ${props => props.iconcolor ?? ""};
	}
`;

const ArrowIcon = styled.img<{ showed?: boolean }>`
	width: 18px;
	height: 18px;
	margin-top: 10px;
	margin-left: 10px;
	transform: ${props =>
		props.showed
			? css`
		 rotate(180deg)
`
			: null};
`;

const LineCategory = styled.div<{ showed: boolean }>`
	display: none;
	transition: all 0.5s ease-in-out;
	cursor: pointer;
	${props =>
		props.showed
			? css`
					transition: all 0.5s ease-in-out;
					display: flex;
					padding: 3px;
			  `
			: null}
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	font-weight: bold;
	color: ${props => props.textColor ?? "black"};
`;

const DocumentCard = styled(DocumentBigCard)<{ color?: string }>`
	max-width: 300px;
	${props => (props.onEdit ? `height: 500px;` : `height: 150px;`)}
	background-color: ${props => props.color}
`;

const WeblinkLineCategory = styled.div<{ showed: boolean }>`
	position: relative;
	width: 100%;
	display: none;
	transition: all 0.5s ease-in-out;
	cursor: pointer;
	${props =>
	props.showed
		? css`
					transition: all 0.5s ease-in-out;
					display: flex;
					padding: 3px;
			  `
		: null}
`;

const WebLink = styled.a`
	cursor: pointer;
	width: 100%;
	color: ${colors.blue2};
	text-decoration: underline ${colors.blue2};
	:hover{
		text-decoration: none;
	}
`;

const TrashButton = styled(Button)`
	position: absolute;
	top: 2px;
	height: 24px;
	width: 20px;
	border-radius: 10px;
	@media ${desktop} {
		left: 360px;
	};
	@media ${mobile} {
		left: 360px;
	};
`;

const TrashButtonIcon = styled.img`
	height: 20px;
	padding-top: 2px;
`;

const PlayButton = styled(Button)`
	position: absolute;
	top: 2px;
	height: 24px;
	width: 20px;
	border-radius: 10px;
	//background-color: ${colors.green};
	@media ${desktop} {
		left: 310px;
	};
	@media ${mobile} {
		left: 310px;
	};
	:hover{
		background-color: #009994;
	}
`;

const PlayButtonIcon = styled.img`
	height: 20px;
	padding-top: 3px;
`;
