import styled from "@emotion/styled";
import { Surgery, SurgeryCompletionState } from "domain/surgery";
import React from "react";
import { row } from "style";
import { BlueFlag, GreenFlag, RedFlag } from "style/flags";
import { useI18n } from "utils/i18n";

export const StatePreAdmissionField: React.FC<{ surgery: Surgery | undefined }> = ({ surgery }) => {
	const { format } = useI18n();

	return surgery && surgery.statePreAdmission && surgery.statePreAdmission !== SurgeryCompletionState.NotApplicable ? (
		surgery.statePreAdmission === SurgeryCompletionState.ActionRequired ? (
			<Row>
				<BlueFlag>{format("table.pre-admission.actionRequired")}</BlueFlag>
			</Row>
		) : surgery.statePreAdmission === SurgeryCompletionState.Completed ? (
			<Row>
				<GreenFlag withIcon>{format("table.pre-admission.completed")}</GreenFlag>
			</Row>
		) : surgery.statePreAdmission === SurgeryCompletionState.Critical ? (
			<Row>
				<RedFlag withIcon>{format("table.pre-admission.critical")}</RedFlag>
			</Row>
		) : (
			<>{format("table.pre-admission.other")}</>
		)
	) : (
		<>{format("table.pre-admission.other")}</>
	);
};

const Row = styled.div`
	${row(0, "flex-start", "center")};
`;
