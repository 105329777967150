import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useDebouncedSearch(initialValue: string, debounceDelay = 500) {
	const [search, setSearch] = useState(initialValue);
	const [effectiveSearch, setEffectiveSearch] = useState(initialValue);
	const [loading, setLoading] = useState(false);

	const setSearchDebounced = useMemo(
		() =>
			debounce((search: string) => {
				setTimeout(() => setLoading(false), 0);
				setEffectiveSearch(search);
			}, debounceDelay),
		[debounceDelay]
	);

	return {
		search,
		effectiveSearch,
		loading,
		setSearch: useCallback(
			(search: string) => {
				setSearch(search);
				setLoading(true);
				setSearchDebounced(search);
			},
			[setSearchDebounced]
		),
	};
}

export const useToggle = (initialValue: boolean) => {
	const [toggle, setToggle] = useState(initialValue);
	return [toggle, useCallback(() => setToggle(value => !value), [])] as const;
};

export function useStateWithEffect<T>(initialState: T, effect: (value: T) => void) {
	const [state, setState] = useState<T>(initialState);
	return [
		state,
		useCallback(
			(value: T) => {
				setState(value);
				effect(value);
			},
			[effect]
		),
	] as const;
}

export function useClickOutside(callback: (e: MouseEvent) => void): React.Ref<HTMLDivElement> {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node | null)) {
				callback(event);
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => document.removeEventListener("click", handleClickOutside, true);
	}, [ref, callback]);

	return ref;
}
