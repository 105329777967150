import styled from "@emotion/styled";
import React, { InputHTMLAttributes } from "react";
import { colors, row } from "style";

export interface RadioButtonsInputProps<T> extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
	innerId: string;
	value: T;
	options: readonly T[];
	onChange: (v: T) => void;
	disabled?: boolean;
	className?: string;
	orientation?: "left" | "right";
	itemRenderer?: (item: T) => string;
	buttonColorRenderer?: (item: T) => string;
}

export type RadioButtonsType<K> = React.FC<RadioButtonsInputProps<K>>;

export function RadioButtonsInput<T>({
	innerId: id,
	onChange,
	options,
	value,
	className,
	orientation = "left",
	itemRenderer = v => `${v}`,
	buttonColorRenderer,
	disabled,
	...rest
}: RadioButtonsInputProps<T>): JSX.Element {
	const valueStr = itemRenderer(value);
	const selectedIndex = options.findIndex(it => itemRenderer(it) === valueStr);

	return (
		<RadioContainer className={className}>
			{options.map((v, index) => (
				<ButtonWrapper key={index} orientation={orientation} disabled={disabled}>
					<CheckedInput
						type="radio"
						id={`${id}-${index}`}
						name={id}
						defaultChecked={selectedIndex === -1 ? undefined : selectedIndex === index}
						color={buttonColorRenderer?.(v)}
						disabled={disabled}
						value={itemRenderer(v)}
						onChange={() => onChange(v)}
						{...rest}
					/>
					<StyledLabel htmlFor={`${id}-${index}`}>{itemRenderer(v)}</StyledLabel>
				</ButtonWrapper>
			))}
		</RadioContainer>
	);
}

const RadioContainer = styled.div`
	${row("M")};
`;

const StyledLabel = styled.label`
	color: ${colors.black};
	font-size: 15px;
	font-weight: bold;
`;

const ButtonWrapper = styled.div<{ orientation: "left" | "right"; disabled?: boolean }>`
	position: relative;
	${props => row("S", "center", "center", props.orientation === "left" ? undefined : "reverse")};

	> * {
		cursor: ${props => (props.disabled ? null : "pointer")};
	}
`;

const CheckedInput = styled.input<{ color?: string }>`
	width: 25px;
	height: 25px;
	background-color: ${colors.white};
	border-radius: 15px;
	display: inline-block;
	border: solid 1px ${props => props.color ?? colors.green};
	position: relative;
	-webkit-appearance: none;

	:checked:after {
		background-color: ${props => props.color ?? colors.green};
		border-radius: 25px;
		content: "";
		display: block;
		height: 11px;
		width: 11px;
		left: 6px;
		top: 6px;
		position: relative;
	}
`;
